<a *ngIf="isLink(item) && !isFunction(item.route) && item.label != 'Agency'  && item.label != 'Comparator'  && item.label != 'Market' && item.label != 'Users' && item.label != 'Permission Admin' && item.label != 'Permission User' && item.label != 'Group Admin' && item.label != 'Admin' && item.label != 'CRM' && item.label != 'Dashboard'&& item.label != 'Groups Agencies'&& item.label != 'Solde Historique' && item.label != 'Permission' && item.label != 'Permission Agencies'&& item.label != 'Search' && item.label != 'Contracts' && item.label != 'SpecialOffers'&& item.label != 'Log'&& item.label != 'Stop Sale' && item.label != 'List Bookings' && item.label != 'List Stop Sale' 
" [fragment]="item.fragment" [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
  [routerLink]="item.route" class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'Agency' && Agence" [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }" [routerLink]="item.route"
  class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'Market' && market" [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }" [routerLink]="item.route"
  class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'Solde Historique' && Agence" [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }" [routerLink]="item.route"
  class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'Permission Agencies' && Agence" [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }" [routerLink]="item.route"
  class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'Permission User' && user" [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }" [routerLink]="item.route"
  class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<!-- <a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'Permission coopérateurs' " [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }" [routerLink]="item.route"
  class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a> -->
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'Users' && user" [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }" [routerLink]="item.route"
  class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'List Bookings' && Booking" [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }" [routerLink]="item.route"
  class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'Comparator' && Comparator"
  [fragment]="item.fragment" [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
  [routerLink]="item.route" class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'Stop Sale' && stop_sale" [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }" [routerLink]="item.route"
  class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'List Stop Sale' && stop_sale"
  [fragment]="item.fragment" [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
  [routerLink]="item.route" class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'CRM' && crm" [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }" [routerLink]="item.route"
  class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'Dashboard' && dashboard" [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }" [routerLink]="item.route"
  class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'Groups Agencies' && Agence" [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }" [routerLink]="item.route"
  class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'Permission' && permission"
  [fragment]="item.fragment" [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
  [routerLink]="item.route" class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'Search' && search" [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }" [routerLink]="item.route"
  class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'Contracts' && contrat" [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }" [routerLink]="item.route"
  class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'SpecialOffers' && contrat"
  [fragment]="item.fragment" [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
  [routerLink]="item.route" class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'Admin' && SuperAdmin"
  [fragment]="item.fragment" [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
  [routerLink]="item.route" class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'Group Admin' && SuperAdmin"
  [fragment]="item.fragment" [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
  [routerLink]="item.route" class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'Permission Admin' && SuperAdmin"
  [fragment]="item.fragment" [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
  [routerLink]="item.route" class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<!-- <a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'List Bookings' && contrat" [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }" [routerLink]="item.route"
  class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a> -->

<!-- <a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'SpecialOffers' && specialoffers" [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }" [routerLink]="item.route"
  class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a> -->
<a *ngIf="isLink(item) && !isFunction(item.route) && item.label == 'Log' && log" [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }" [routerLink]="item.route"
  class="vex-sidenav-item" matRipple matRippleColor="var(--sidenav-item-ripple-color)"
  routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>

  <span class="vex-sidenav-item__label">{{ item.label }}</span>
</a>
<div (click)="item.route()" *ngIf="isLink(item) && isFunction(item.route)" class="vex-sidenav-item" matRipple
  matRippleColor="var(--sidenav-item-ripple-color)" routerLinkActive="vex-sidenav-item--active">
  <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>
  <span class="vex-sidenav-item__label">{{ item.label }}</span>
  <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="vex-sidenav-item__badge">{{
    item.badge.value }}</span>
</div>
<ng-container
  *ngIf="isDropdown(item) && item.label != 'ERP' && item.label != 'Hotel Management' && item.label != 'Experience'&& item.label != 'Profil' && item.label != 'API' &&  item.label != 'Tours Management' && item.label != 'Transfers Management' && item.label != 'XML-IN' && item.label != 'XML-OUT' && item.label != 'Configuration' && item.label != 'Stop Sale Contract' && item.label != 'Packages Management'&& item.label != 'BackOffice'">
  <div (click)="toggleOpen()" [class.vex-sidenav-item--active]="isOpen || isActive"
    [class.vex-sidenav-item--open]="isOpen" class="vex-sidenav-item" matRipple  
    matRippleColor="var(--sidenav-item-ripple-color)">
    <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>
    <span class="vex-sidenav-item__label">{{ item.label }}</span>
    <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="vex-sidenav-item__badge">{{
      item.badge.value }}</span>
    <mat-icon class="vex-sidenav-item__dropdown-icon" svgIcon="mat:keyboard_arrow_right"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="vex-sidenav-item__dropdown">
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>
<ng-container
  *ngIf="isDropdown(item) && item.label == 'BackOffice' && ( hotel || contrat || stop_sale || Comparator || tour || transfer || Package  || profil || configuration || log)">
  <div (click)="toggleOpen()" [class.vex-sidenav-item--active]="isOpen || isActive"
    [class.vex-sidenav-item--open]="isOpen" class="vex-sidenav-item" matRipple
    matRippleColor="var(--sidenav-item-ripple-color)">
    <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>
    <span class="vex-sidenav-item__label">{{ item.label }}</span>
    <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="vex-sidenav-item__badge">{{
      item.badge.value }}</span>
    <mat-icon class="vex-sidenav-item__dropdown-icon" svgIcon="mat:keyboard_arrow_right"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="vex-sidenav-item__dropdown">
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>

<ng-container *ngIf="isDropdown(item) && item.label == 'ERP' && erp">
  <div (click)="toggleOpen()" [class.vex-sidenav-item--active]="isOpen || isActive"
    [class.vex-sidenav-item--open]="isOpen" class="vex-sidenav-item" matRipple
    matRippleColor="var(--sidenav-item-ripple-color)">
    <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>
    <span class="vex-sidenav-item__label">{{ item.label }}</span>
    <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="vex-sidenav-item__badge">{{
      item.badge.value }}</span>
    <mat-icon class="vex-sidenav-item__dropdown-icon" svgIcon="mat:keyboard_arrow_right"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="vex-sidenav-item__dropdown">
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>
<ng-container *ngIf="isDropdown(item) && item.label == 'Hotel Management' && hotel">
  <div (click)="toggleOpen()" [class.vex-sidenav-item--active]="isOpen || isActive"
    [class.vex-sidenav-item--open]="isOpen" class="vex-sidenav-item" matRipple
    matRippleColor="var(--sidenav-item-ripple-color)">
    <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>
    <span class="vex-sidenav-item__label">{{ item.label }}</span>
    <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="vex-sidenav-item__badge">{{
      item.badge.value }}</span>
    <mat-icon class="vex-sidenav-item__dropdown-icon" svgIcon="mat:keyboard_arrow_right"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="vex-sidenav-item__dropdown">
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>



<ng-container *ngIf="isDropdown(item) && item.label == 'Experience' && event">
  <div (click)="toggleOpen()" [class.vex-sidenav-item--active]="isOpen || isActive"
    [class.vex-sidenav-item--open]="isOpen" class="vex-sidenav-item" matRipple
    matRippleColor="var(--sidenav-item-ripple-color)">
    <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>
    <span class="vex-sidenav-item__label">{{ item.label }}</span>
    <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="vex-sidenav-item__badge">{{
      item.badge.value }}</span>
    <mat-icon class="vex-sidenav-item__dropdown-icon" svgIcon="mat:keyboard_arrow_right"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="vex-sidenav-item__dropdown">
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>
<ng-container *ngIf="isDropdown(item) && item.label == 'Packages Management' && Package">
  <div (click)="toggleOpen()" [class.vex-sidenav-item--active]="isOpen || isActive"
    [class.vex-sidenav-item--open]="isOpen" class="vex-sidenav-item" matRipple
    matRippleColor="var(--sidenav-item-ripple-color)">
    <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>
    <span class="vex-sidenav-item__label">{{ item.label }}</span>
    <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="vex-sidenav-item__badge">{{
      item.badge.value }}</span>
    <mat-icon class="vex-sidenav-item__dropdown-icon" svgIcon="mat:keyboard_arrow_right"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="vex-sidenav-item__dropdown">
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>
<ng-container *ngIf="isDropdown(item) && item.label == 'Profil' && profil">
  <div (click)="toggleOpen()" [class.vex-sidenav-item--active]="isOpen || isActive"
    [class.vex-sidenav-item--open]="isOpen" class="vex-sidenav-item" matRipple
    matRippleColor="var(--sidenav-item-ripple-color)">
    <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>
    <span class="vex-sidenav-item__label">{{ item.label }}</span>
    <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="vex-sidenav-item__badge">{{
      item.badge.value }}</span>
    <mat-icon class="vex-sidenav-item__dropdown-icon" svgIcon="mat:keyboard_arrow_right"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="vex-sidenav-item__dropdown">
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>
<ng-container *ngIf="isDropdown(item) && item.label == 'API' && ( xml_in  || xml_out )">
  <div (click)="toggleOpen()" [class.vex-sidenav-item--active]="isOpen || isActive"
    [class.vex-sidenav-item--open]="isOpen" class="vex-sidenav-item" matRipple
    matRippleColor="var(--sidenav-item-ripple-color)">
    <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>
    <span class="vex-sidenav-item__label">{{ item.label }}</span>
    <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="vex-sidenav-item__badge">{{
      item.badge.value }}</span>
    <mat-icon class="vex-sidenav-item__dropdown-icon" svgIcon="mat:keyboard_arrow_right"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="vex-sidenav-item__dropdown">
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>

<ng-container *ngIf="isDropdown(item) && item.label == 'Tours Management' && tour">
  <div (click)="toggleOpen()" [class.vex-sidenav-item--active]="isOpen || isActive"
    [class.vex-sidenav-item--open]="isOpen" class="vex-sidenav-item" matRipple
    matRippleColor="var(--sidenav-item-ripple-color)">
    <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>
    <span class="vex-sidenav-item__label">{{ item.label }}</span>
    <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="vex-sidenav-item__badge">{{
      item.badge.value }}</span>
    <mat-icon class="vex-sidenav-item__dropdown-icon" svgIcon="mat:keyboard_arrow_right"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="vex-sidenav-item__dropdown">
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>
<ng-container *ngIf="isDropdown(item) && item.label == 'Transfers Management' && transfer">
  <div (click)="toggleOpen()" [class.vex-sidenav-item--active]="isOpen || isActive"
    [class.vex-sidenav-item--open]="isOpen" class="vex-sidenav-item" matRipple
    matRippleColor="var(--sidenav-item-ripple-color)">
    <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>
    <span class="vex-sidenav-item__label">{{ item.label }}</span>
    <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="vex-sidenav-item__badge">{{
      item.badge.value }}</span>
    <mat-icon class="vex-sidenav-item__dropdown-icon" svgIcon="mat:keyboard_arrow_right"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="vex-sidenav-item__dropdown">
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>
<ng-container *ngIf="isDropdown(item) && item.label == 'XML-IN' && xml_in">
  <div (click)="toggleOpen()" [class.vex-sidenav-item--active]="isOpen || isActive"
    [class.vex-sidenav-item--open]="isOpen" class="vex-sidenav-item" matRipple
    matRippleColor="var(--sidenav-item-ripple-color)">
    <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>
    <span class="vex-sidenav-item__label">{{ item.label }}</span>
    <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="vex-sidenav-item__badge">{{
      item.badge.value }}</span>
    <mat-icon class="vex-sidenav-item__dropdown-icon" svgIcon="mat:keyboard_arrow_right"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="vex-sidenav-item__dropdown">
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>
<ng-container *ngIf="isDropdown(item) && item.label == 'XML-OUT' && xml_out">
  <div (click)="toggleOpen()" [class.vex-sidenav-item--active]="isOpen || isActive"
    [class.vex-sidenav-item--open]="isOpen" class="vex-sidenav-item" matRipple
    matRippleColor="var(--sidenav-item-ripple-color)">
    <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>
    <span class="vex-sidenav-item__label">{{ item.label }}</span>
    <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="vex-sidenav-item__badge">{{
      item.badge.value }}</span>
    <mat-icon class="vex-sidenav-item__dropdown-icon" svgIcon="mat:keyboard_arrow_right"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="vex-sidenav-item__dropdown">
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>
<ng-container *ngIf="isDropdown(item) && item.label == 'Configuration' && configuration">
  <div (click)="toggleOpen()" [class.vex-sidenav-item--active]="isOpen || isActive"
    [class.vex-sidenav-item--open]="isOpen" class="vex-sidenav-item" matRipple
    matRippleColor="var(--sidenav-item-ripple-color)">
    <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>
    <span class="vex-sidenav-item__label">{{ item.label }}</span>
    <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="vex-sidenav-item__badge">{{
      item.badge.value }}</span>
    <mat-icon class="vex-sidenav-item__dropdown-icon" svgIcon="mat:keyboard_arrow_right"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="vex-sidenav-item__dropdown">
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>
<ng-container *ngIf="isDropdown(item) && item.label == 'Stop Sale Contract' && stop_sale">
  <div (click)="toggleOpen()" [class.vex-sidenav-item--active]="isOpen || isActive"
    [class.vex-sidenav-item--open]="isOpen" class="vex-sidenav-item" matRipple
    matRippleColor="var(--sidenav-item-ripple-color)">
    <mat-icon *ngIf="level === 0" [svgIcon]="item.icon" class="vex-sidenav-item__icon"></mat-icon>
    <span class="vex-sidenav-item__label">{{ item.label }}</span>
    <span *ngIf="item.badge" [ngClass]="[item.badge.bgClass, item.badge.textClass]" class="vex-sidenav-item__badge">{{
      item.badge.value }}</span>
    <mat-icon class="vex-sidenav-item__dropdown-icon" svgIcon="mat:keyboard_arrow_right"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="vex-sidenav-item__dropdown">
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>
<ng-container *ngIf="isSubheading(item)">
  <div class="vex-sidenav-subheading">{{ item.label }}</div>
  <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="0"></vex-sidenav-item>
</ng-container>