import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuardService as AuthGuard } from './pages/pages/auth/auth-guard.service';

const routes: VexRoutes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/pages/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },
  {
    path: 'ConfirmRegistration',
    loadChildren: () => import('./pages/pages/auth/confirm-registration/confirm-registration.module').then(m => m.ConfirmRegistrationModule),
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },

  {
    path: 'apps',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],

    children: [
      {
        path: 'analytics',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
      },


      {
        path: 'Dashboard',
        loadChildren: () => import('./pages/apps/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'chat',
        loadChildren: () => import('./pages/apps/chat/chat.module').then(m => m.ChatModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'mail',
        loadChildren: () => import('./pages/apps/mail/mail.module').then(m => m.MailModule),
        data: {
          toolbarShadowEnabled: true,
          scrollDisabled: true
        }
      },
      {
        path: 'social',
        loadChildren: () => import('./pages/apps/social/social.module').then(m => m.SocialModule)
      },
      {
        path: 'contacts',
        loadChildren: () => import('./pages/apps/contacts/contacts.module').then(m => m.ContactsModule)
      },
      {
        path: 'calendar',
        loadChildren: () => import('./pages/apps/calendar/calendar.module').then(m => m.CalendarModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'aio-table',
        loadChildren: () => import('./pages/apps/aio-table/aio-table.module').then(m => m.AioTableModule),
      },
      {
        path: 'hotel',
        loadChildren: () => import('./pages/apps/hotel/hotel.module').then(m => m.HotelModule),
      },
      {
        path: 'concellation-transfer',
        loadChildren: () => import('./pages/apps/concellation-transfer/concellation-transfer.module').then(m => m.ConcellationTransferModule),
      },
      {
        path: 'permission-agencies',
        loadChildren: () => import('./pages/apps/permission-user/permission-user.module').then(m => m.PermissionUserModule),
      },
      {
        path: 'groupe-coopérateurs',
        loadChildren: () => import('./pages/apps/coopérateurs/access-user/access-user.module').then(m => m.AccessUserModule),
      },
      {
        path: 'coopérateurs',
        loadChildren: () => import('./pages/apps/coopérateurs/list-users/list-users.module').then(m => m.ListUsersModule),
      },
      {
        path: 'permission-coopérateurs',
        loadChildren: () => import('./pages/apps/coopérateurs/access-user/access-user.module').then(m => m.AccessUserModule),
      },
      {
        path: 'room',
        loadChildren: () => import('./pages/apps/room/room.module').then(m => m.RoomModule),
      },
      {
        path: 'tour-booking',
        loadChildren: () => import('./pages/apps/tour-booking/tour-booking.module').then(m => m.TourBookingModule),
      },
      {

        path: 'price-tour',
        loadChildren: () => import('./pages/apps/price-tour/price-tour.module').then(m => m.PriceTourModule),
      },
      {

        path: 'concellation-condition-tour',
        loadChildren: () => import('./pages/apps/concellation-condition-tour/concellation-condition-tour.module').then(m => m.ConcellationConditionTourModule),
      },
      {
        path: 'period-transfer',
        loadChildren: () => import('./pages/apps/period-transfer/period-transfer.module').then(m => m.PeriodTransferModule),
      },
      {
        path: 'area-transfer',
        loadChildren: () => import('./pages/apps/area-transfer/area-transfer.module').then(m => m.AreaTransferModule),
      },
      {
        path: 'category-tour',
        loadChildren: () => import('./pages/apps/category-tour/category-tour.module').then(m => m.CategoryTourModule),
      },
      {
        path: 'period-tour',
        loadChildren: () => import('./pages/apps/period-tour/period-tour.module').then(m => m.PeriodTourModule),
      },
      {
        path: 'category-transfer',
        loadChildren: () => import('./pages/apps/category-transfer/category-transfer.module').then(m => m.CategoryTransferModule),
      },

      {
        path: 'transfer',
        loadChildren: () => import('./pages/apps/transfer/transfer.module').then(m => m.TransferModule),
      },
      {
        path: 'booking-transfert',
        loadChildren: () => import('./pages/apps/booking-transfert/booking-transfert.module').then(m => m.BookingTransfertModule),
      },
      {
        path: 'provider-transfer',
        loadChildren: () => import('./pages/apps/provider-transfer/provider-transfer.module').then(m => m.ProviderTransferModule),
      },
      {
        path: 'transporter-transfer',
        loadChildren: () => import('./pages/apps/transporter-transfer/transporter-transfer.module').then(m => m.TransporterTransferModule),
      },
      {
        path: 'drivers-transfert',
        loadChildren: () => import('./pages/apps/drivers-transfert/drivers-transfert.module').then(m => m.DriversTransfertModule),
      },
      {
        path: 'vehicle-transfert',
        loadChildren: () => import('./pages/apps/vehicle-transfert/vehicle-transfert.module').then(m => m.VehicleTransfertModule),
      },
      {
        path: 'price-transfer',
        loadChildren: () => import('./pages/apps/price-transfer/price-transfer.module').then(m => m.PriceTransferModule),
      },
      {
        path: 'location-transfert',
        loadChildren: () => import('./pages/apps/location-transfert/location-transfert.module').then(m => m.LocationTransfertModule),
      },
      {
        path: 'report',
        loadChildren: () => import('./pages/apps/report/report.module').then(m => m.ReportModule),
      },
      {
        path: 'owner',
        loadChildren: () => import('./pages/apps/owner/owner.module').then(m => m.OwnerModule),
      },
      {
        path: 'groupadmin',
        loadChildren: () => import('./pages/apps/groupadmin/groupadmin.module').then(m => m.GroupadminModule),
      },
      {
        path: 'market',
        loadChildren: () => import('./pages/apps/market/market.module').then(m => m.MarketModule),
      },
      {
        path: 'group-user',
        loadChildren: () => import('./pages/apps/group-user/group-user.module').then(m => m.GroupUserModule),
      },


      {
        path: 'permission-user',
        loadChildren: () => import('./pages/apps/permission-user/permission-user.module').then(m => m.PermissionUserModule),
      },



      {
        path: 'card',
        loadChildren: () => import('./pages/apps/card/card.module').then(m => m.CardModule),
      },
    

      {
        path: 'room-type',
        loadChildren: () => import('./pages/apps/room-type/room-type.module').then(m => m.RoomTypeModule),
      },
      {
        path: 'encaissement',
        loadChildren: () => import('./pages/apps/encaissement/encaissement.module').then(m => m.EncaissementModule),
      },
      {
        path: 'board',
        loadChildren: () => import('./pages/apps/board/board.module').then(m => m.BoardModule),
      },
      {
        path: 'country',
        loadChildren: () => import('./pages/apps/country/country.module').then(m => m.CountryModule),
      },
      {
        path: 'villes',
        loadChildren: () => import('./pages/apps/villes/villes.module').then(m => m.VillesModule),
      },
      {


        path: 'contrat',
        loadChildren: () => import('./pages/apps/contrat/contrat.module').then(m => m.ContratModule),
      },
      {
        path: 'solde',
        loadChildren: () => import('./pages/apps/solde/solde.module').then(m => m.SoldeModule),
      },
      {
        path: 'crm',
        loadChildren: () => import('./pages/apps/crm/crm.module').then(m => m.CrmModule),
      },
      {
        path: 'chain',
        loadChildren: () => import('./pages/apps/chain/chain.module').then(m => m.ChainModule),
      },
      {
        path: 'comparator',
        loadChildren: () => import('./pages/apps/comparator/comparator.module').then(m => m.ComparatorModule),
      },
      {
        path: 'paiement',
        loadChildren: () => import('./pages/apps/paiement/paiement.module').then(m => m.PaiementModule),
      },
      {
        path: 'tresorerie',
        loadChildren: () => import('./pages/apps/tresorerie/tresorerie.module').then(m => m.TresorerieModule),
      },
      {
        path: 'providers',
        loadChildren: () => import('./pages/apps/providers/providers.module').then(m => m.ProvidersModule),
      },
      {
        path: 'register',
        loadChildren: () => import('./pages/apps/register/register.module').then(m => m.RegisterModule),
      },
      {
        path: 'pays',
        loadChildren: () => import('./pages/apps/pays/pays.module').then(m => m.PaysModule),
      },

      {
        path: 'alert',
        loadChildren: () => import('./pages/apps/alert/alert.module').then(m => m.AlertModule),
      },
      {
        path: 'alert2',
        loadChildren: () => import('./pages/apps/alert2/alert2.module').then(m => m.Alert2Module),
      },


      {
        path: 'child-price',
        loadChildren: () => import('./pages/apps/child-price/child-price.module').then(m => m.ChildPriceModule),
      },
      {
        path: 'list-sale',
        loadChildren: () => import('./pages/apps/list-sale/list-sale.module').then(m => m.ListSaleModule),
      },
      {
        path: 'avance',
        loadChildren: () => import('./pages/apps/avance/avance.module').then(m => m.AvanceModule),
      },

      {
        path: 'caution',
        loadChildren: () => import('./pages/apps/caution/caution.module').then(m => m.CautionModule),
      },
      {
        path: 'avance-fournisseur',
        loadChildren: () => import('./pages/apps/avance-fournisseur/avance-fournisseur.module').then(m => m.AvanceFournisseurModule),
      },
      {
        path: 'caution-fournisseur',
        loadChildren: () => import('./pages/apps/caution-fournisseur/caution-fournisseur.module').then(m => m.CautionFournisseurModule),
      },
      {
        path: 'decaissement',
        loadChildren: () => import('./pages/apps/decaissement/decaissement.module').then(m => m.DecaissementModule),
      },
      {
        path: 'en-tete',
        loadChildren: () => import('./pages/apps/en-tete/en-tete.module').then(m => m.EnTeteModule),
      },
      {
        path: 'sale',
        loadChildren: () => import('./pages/apps/sale/sale.module').then(m => m.SaleModule),
      },
      {
        path: 'log',
        loadChildren: () => import('./pages/apps/log/log.module').then(m => m.LogModule),
      },
      {
        path: 'reservations',
        loadChildren: () => import('./pages/apps/reservations/reservations.module').then(m => m.ReservationsModule),
      },
      {
        path: 'tours',
        loadChildren: () => import('./pages/apps/tours/tours.module').then(m => m.ToursModule),
      },
      {
        path: 'location-tours',
        loadChildren: () => import('./pages/apps/location-tours/location-tours.module').then(m => m.LocationToursModule),
      },
      {
        path: 'provider',
        loadChildren: () => import('./pages/apps/provider/provider.module').then(m => m.ProviderModule),
      },
      {
        path: 'transfer',
        loadChildren: () => import('./pages/apps/transfer/transfer.module').then(m => m.TransferModule),
      },
      {
        path: 'provider-transfer',
        loadChildren: () => import('./pages/apps/provider-transfer/provider-transfer.module').then(m => m.ProviderTransferModule),
      },
      {
        path: 'transporter-transfer',
        loadChildren: () => import('./pages/apps/transporter-transfer/transporter-transfer.module').then(m => m.TransporterTransferModule),
      },
      {
        path: 'drivers-transfert',
        loadChildren: () => import('./pages/apps/drivers-transfert/drivers-transfert.module').then(m => m.DriversTransfertModule),
      },
      {
        path: 'vehicle-transfert',
        loadChildren: () => import('./pages/apps/vehicle-transfert/vehicle-transfert.module').then(m => m.VehicleTransfertModule),
      },
      {
        path: 'location-transfert',
        loadChildren: () => import('./pages/apps/location-transfert/location-transfert.module').then(m => m.LocationTransfertModule),
      },
      {
        path: 'location-event',
        loadChildren: () => import('./pages/apps/location-event/location-event.module').then(m => m.LocationEventModule),
      },
      {
        path: 'packages',
        loadChildren: () => import('./pages/apps/packages/packages.module').then(m => m.PackagesModule),
      },
      {
        path: 'events',
        loadChildren: () => import('./pages/apps/events/events.module').then(m => m.EventsModule),
      },

      /* {
        path: 'search-package',
        loadChildren: () => import('./pages/apps/search-package/search-package.module').then(m => m.SearchPackageModule),
      }, */
      {
        path: 'event-providers',
        loadChildren: () => import('./pages/apps/event-providers/event-providers.module').then(m => m.EventProvidersModule),
      },
      {
        path: 'Mini ERP',
        loadChildren: () => import('./pages/apps/mini-erp/mini-erp.module').then(m => m.MiniErpModule),
      },
      {
        path: 'facturelist',
        loadChildren: () => import('./pages/apps/facturelist/facturelist.module').then(m => m.FacturelistModule),
      },
      {
        path: 'reculist',
        loadChildren: () => import('./pages/apps/reculist/reculist.module').then(m => m.ReculistModule),
      },
      {
        path: 'price-transfer',
        loadChildren: () => import('./pages/apps/price-transfer/price-transfer.module').then(m => m.PriceTransferModule),
      },
      {
        path: 'xml-in',
        loadChildren: () => import('./pages/apps/xml-in/xml-in.module').then(m => m.XmlInModule),
      },
      {
        path: 'permission',
        loadChildren: () => import('./pages/apps/permission/permission.module').then(m => m.PermissionModule),
      },

      {
        path: 'recu',
        loadChildren: () => import('./pages/apps/recu/recu.module').then(m => m.RecuModule),
      },
      {
        path: 'search',
        loadChildren: () => import('./pages/apps/search/search.module').then(m => m.SearchModule),
      },
      {
        path: 'searching',
        loadChildren: () => import('./pages/apps/searching/searching.module').then(m => m.SearchingModule),
      },
      {
        path: 'xml-in-integrateur',
        loadChildren: () => import('./pages/apps/xml-in-integrateurs/xml-in-integrateurs.module').then(m => m.XmlInIntegrateursModule),
      },

      {
        path: 'mappage-xmlin',
        loadChildren: () => import('./pages/apps/mappage-xmlin/mappage-xmlin.module').then(m => m.MappageXmlinModule),
      },
      {
        path: 'Mapping',
        loadChildren: () => import('./pages/apps/mapping/mapping.module').then(m => m.MappingModule),
      },
      {
        path: 'Agencies',
        loadChildren: () => import('./pages/apps/xml-out/xml-out.module').then(m => m.XmlOutModule),
      },
      {
        path: 'Agencies',
        loadChildren: () => import('./pages/apps/agencies/agencies.module').then(m => m.AgenciesModule),
      },
      {
        path: 'Groups',
        loadChildren: () => import('./pages/apps/groups/groups.module').then(m => m.GroupsModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/apps/users/users.module').then(m => m.UsersModule),
      },

      {
        path: 'Groupes',
        loadChildren: () => import('./pages/apps/groupes/groupes.module').then(m => m.GroupesModule),
      },
      {
        path: 'fund-expenses',
        loadChildren: () => import('./pages/apps/fund-expenses/fund-expenses.module').then(m => m.FundExpensesModule),
      },
      {
        path: 'help-center',
        loadChildren: () => import('./pages/apps/help-center/help-center.module').then(m => m.HelpCenterModule),
      },
      {
        path: 'scrumboard',
        loadChildren: () => import('./pages/apps/scrumboard/scrumboard.module').then(m => m.ScrumboardModule),
      },
      {
        path: 'editor',
        loadChildren: () => import('./pages/apps/editor/editor.module').then(m => m.EditorModule),
      },



    ]
  },
  {
    path: 'pages',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'pricing',
        loadChildren: () => import('./pages/pages/pricing/pricing.module').then(m => m.PricingModule)
      },
      {
        path: 'faq',
        loadChildren: () => import('./pages/pages/faq/faq.module').then(m => m.FaqModule)
      },
      {
        path: 'guides',
        loadChildren: () => import('./pages/pages/guides/guides.module').then(m => m.GuidesModule)
      },
      {
        path: 'invoice',
        loadChildren: () => import('./pages/pages/invoice/invoice.module').then(m => m.InvoiceModule)
      },
      {
        path: 'error-404',
        loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      },
      {
        path: 'error-500',
        loadChildren: () => import('./pages/pages/errors/error-500/error-500.module').then(m => m.Error500Module)
      }
    ]
  },
  {
    path: 'ui',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'components',
        loadChildren: () => import('./pages/ui/components/components.module').then(m => m.ComponentsModule),
      },
      {
        path: 'forms/form-elements',
        loadChildren: () => import('./pages/ui/forms/form-elements/form-elements.module').then(m => m.FormElementsModule),
        data: {
          containerEnabled: true
        }
      },
      {
        path: 'forms/form-wizard',
        loadChildren: () => import('./pages/ui/forms/form-wizard/form-wizard.module').then(m => m.FormWizardModule),
        data: {
          containerEnabled: true
        }
      },
      {
        path: 'icons',
        loadChildren: () => import('./pages/ui/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'page-layouts',
        loadChildren: () => import('./pages/ui/page-layouts/page-layouts.module').then(m => m.PageLayoutsModule),
      },
    ]
  },
  {
    path: 'documentation',
    loadChildren: () => import('./pages/documentation/documentation.module').then(m => m.DocumentationModule),
  },
  {
    path: '**',
    loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
  }
]
  ;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
