import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatTableDataSource } from '@angular/material/table';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { MiniErpService } from './mini-erp.service';
import { format, isThisISOWeek } from "date-fns";
import { MatDialog } from '@angular/material/dialog';
import { Customer } from '../aio-table/interfaces/customer.model';
import { ReservationsUpdateComponent } from '../reservations/reservations-update/reservations-update.component';
import { scaleFadeIn400ms } from 'src/@vex/animations/scale-fade-in.animation';
import { Observable, ReplaySubject, catchError, filter, map, of, startWith, switchMap } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Facture } from './interfaces/facture.model';
import { formatDate } from '@angular/common';
import { ToolbarserviceService } from 'src/@vex/layout/toolbar/toolbarservice.service';
import { LoginService } from '../../pages/auth/login/login.service';



@UntilDestroy({ checkProperties: true })
@UntilDestroy()
@UntilDestroy()

@Component({
  selector: 'vex-mini-erp',
  templateUrl: './mini-erp.component.html',
  styleUrls: ['./mini-erp.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms,scaleFadeIn400ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class MiniErpComponent implements OnInit,AfterViewInit {
  layoutCtrl = new UntypedFormControl('boxed');
  selection = new SelectionModel<any>(true, []);

  searchCtrl = new UntypedFormControl();
  dataSource: MatTableDataSource<any> | null;
  datasourcefacture: MatTableDataSource<any> | null;
  list:boolean=true
  facture:boolean=false
  factureData:any
  result:number=0
  nomsupp = "nom1"
  nomSupp = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]
  supps = [""]
  mt_ht_supp = "mt_ht_supp1"
  taux_tva_supp1;
  taux_tva_suppl = ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0"];
  taux_tva_supp3;
  taux_tva_supp4;
  taux_tva_supp5;
  taux_tva_supp = "taux_tva_supp1"
  montantHT_supp1 = 0;
  montantTTC_supp1 = 0;
  montantHT_supp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  montantTTC_supp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  montantHT_supp3 = 0;
  montantTTC_supp3 = 0;
  montantHT_supp4 = 0;
  montantTTC_supp4 = 0;
  montantHT_supp5 = 0;
  montantTTC_supp5 = 0;
  mt_tva = 0;
  mt_tva7 = 0;
  mt_tva19 = 0;
  montant_total = 0;
  montant_total_ht;
  supplementTotal = 0;
  montantTTC:string
  boolSupp1: boolean = true;
  boolSupp2: boolean = false;
  boolSupp3: boolean = false;
  boolSupp4: boolean = false;
  boolSupp5: boolean = false;
  submitted: boolean = false;
  countSupp = 0;
  typeclient;
  client;
  public Bookings: any[] = [];
  public Rooms:any[]=[]
  customers: any[];

  @Input()
  columns: TableColumn<Facture>[] = [
    { label: 'checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Action', property: 'confirm', type: 'button', visible: true },
    { label: 'Id', property: 'BookingId', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Status', property: 'BookingStatus', type: 'button', visible: true, cssClasses: ['font-medium'] },
    { label: 'Reference', property: 'BookingReference', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Hôtel', property: 'Hotel', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Date Réservation', property: 'BookingDate', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Check-In', property: 'CheckInDate', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Check-Out', property: 'CheckOutDate', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Action', property: 'actions', type: 'button', visible: false },

  ];  percent1=0.07
  percent2=0.19
  
  datafacture:any
@Input()
@Input()

  
  row: any;
  data: any;
  Row: any;
  BookingId: any;
  id: any;
  PercentageValue:any[] = [
    { value: '0.07', viewValue: '7%' },
    { value: '0.19', viewValue: '19%' },

  ];
  PercentageValues:any[] = [
    { value: '0.07', viewValue: '7%' },
    { value: '0.19', viewValue: '19%' },

  ];
  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  subject$: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  data$: Observable<any[]> = this.subject$.asObservable();
  Percentage:string
  Percentagee:string
  montantTTC_suppl: string;
  tableselect: string[] = []
  resultat: number;
  Percentage1:number
  Percentage2:number
  reservation=[]
  Customer: any;
  percent: string;
  mtttc: any;
  today= new Date();
  jstoday="";
  Room: any[];
  Price: any;
  totalPrice: any;
  NBchild: any;
  NumberOfChildren: any;
  NumberOfRooms: any;
  NumberOfNights: any;
  resultMt: number;
  resultMHT: any;
  percentt: string;
  address: any;
  page: any;
  pageIndex = 0;
  length:number = 20
  isLoading:boolean=false
  constructor(private MiniErpService: MiniErpService,
    private dialog: MatDialog,private cd:ChangeDetectorRef,private loginservice:LoginService ) { 

     this.jstoday = formatDate(this.today, 'dd-MM-yyyy ', 'en-US',);

  }
  
  
  

  labels = [
    {
      text: 'Confirmer',
      textClass: 'text-green',
      bgClass: 'bg-green-light',
      previewClass: 'bg-green'
    },
    {
      text: 'Annuler',
      textClass: 'text-red',
      bgClass: 'bg-red-light',
      previewClass: 'bg-red'
    },
    {
      text: 'En Attente',
      textClass: 'text-orange',
      bgClass: 'bg-orange-light',
      previewClass: 'bg-orange'
    }
  ];
  Valuebooking:string
  designationvalue:string
  
  getpagedata(page:any,pageSize:any){
      
    console.log("page*: ",page,"pageSize*: ",pageSize)
  
    this.MiniErpService.postAllReservPagination(page,pageSize).subscribe(res=>{
      console.log("resp pagination: ",res)
      this.reservation=res.items
      this.dataSource = new MatTableDataSource(this.reservation);
      console.log("this.reservation",this.reservation)
      this.page = page;
  
      console.log("this.page**",this.page)
       
      this.length=parseInt(res.totalItems)
  
    },
    err => { console.log("hotel/getall error", err); }
  
    );
    return of(this.reservation.map(market => new Facture (market)))
  
  }
  
  
  updatefacture(customer){
    this.MiniErpService.updateFacture(customer,this.id).subscribe((Response:any)=>{
      console.log("Facture updated",Response);
    })
  }
 
  deletefacture(){

  }

   deleteCustomers(customers: Customer[]) {
   
    customers.forEach(c => this.deletefacture());
  } 
  showValueTTCMT(value){
    
   
  }

  showValueTTC1(value){

    this.resultMt=0
    this.resultMHT = (value.target.value);
    console.log("resultMHT:::",this.resultMHT)
    
  }
//////////////////////////////////////////////////////
  getdata(){
    this.loginservice.addressData.subscribe((address) => {

      this.address = address;
      console.log("address***/",address)

    });
}
getAllReservations() {
  let ObjGetBooking = Object.assign(
    {
      Credentials: {
          Login: "MyLogin",
          Password: "Password"
      },
      BookingList: {
          
      }
  }
  )
  console.log("ObjGetBooking: ",ObjGetBooking)
  this.MiniErpService.postAllReserv(ObjGetBooking).subscribe(response => {
    console.log("Liste reservation: ", response)
      this.reservation=response.BookingListResponse.Bookings
    console.log("reservations: ", this.reservation)
   
    this.cd.detectChanges();
    this.subject$.next(response.BookingListResponse.Bookings);

  },
  err => { console.log("reservation/getall error", err); }

  );
  return of(this.reservation.map(reservation => new Facture (reservation)))

}

  /********************************ines@dotcom.tn******************************** */
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;

    this.paginator.page
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          return this.getpagedata(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize
            ).pipe(catchError(() => new Observable(null)));
          }
          ),map((reservation) => {
            if (reservation == null) return [];
            this.reservation = this.reservation;
            this.isLoading = false;
            return reservation;
          })
        )
          .subscribe((reservation) => {
            this.reservation = this.reservation;
            this.dataSource = new MatTableDataSource(this.reservation);
          });

    }
  ngOnInit(): void {
    this.getdata()
    this.getAllReservations().subscribe(reservation=>{
      this.subject$.next(reservation)
    });



    this.dataSource = new MatTableDataSource();
    this.data$.pipe(
      filter<Facture[]>(Boolean)
    ).subscribe(reservation => {
      this.reservation = reservation;
      this.dataSource.data = reservation;
    });

    console.log("reservation/reservation length ", this.reservation.length)

      this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));  

     
  }
  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }
  teReservation(Reservation) {
    /**
     * Here we are updating our local array.
     * You would probably make an HTTP request here.
     */
  }
  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }
  showoperationSelectedd(){
    this.percent=this.Percentage
    this.percentt=this.Percentagee
    this.result=0
    this.mtttc=0

    if(this.Percentage=='0.07'){
      this.result=parseFloat(this.Valuebooking)*parseFloat(this.Percentage)
      console.log("result",this.result)
      this.mtttc=(this.result*(1+parseFloat(this.Percentage))+0.600)
      console.log("mtttc",this.mtttc)
      
    }
    if(this.Percentage=='0.19'){
      this.result=parseFloat(this.Valuebooking)*parseFloat(this.Percentage)
      console.log("result",this.result)
      this.mtttc=(this.result*(1+parseFloat(this.Percentage))+0.600)
      console.log("mtttc",this.mtttc)
      
    }
  }
  createReservation(Row) {
    
     
    this.Row={
      "BookingId":this.selection.selected,
      "Désignation": this.designationvalue,
      "Montant Ht":this.Valuebooking,
      "Taux TVA":this.PercentageValue,
      "Montant TTC":this.result
    }
    console.log("row: ",Row)
    
   
  }

  PostFacture(){
    let request={
      "Facture": {
        "Bookings": 
        // return table with id / attribut
          this.Bookings.map(h=>h.BookingId)

                
      }
    }
    console.log("Request",request)
    this.MiniErpService.PostFacture(request).subscribe(Response=>{
       console.log("response added facturation ", Response)
    })  
  }
  ConfirmReservation(row){
    
  }
  showValue(value){
    this.Valuebooking = (value.target.value);
    console.log("Valuebooking:",this.Valuebooking)
    this.row=this.Valuebooking

  }
  showValueDesignation(value){
    this.designationvalue = (value.target.value);
    console.log("designationvalue:",this.designationvalue)
    this.row=this.designationvalue
  }
  showValueTTC(value){
    this.montantTTC_supp = (value.target.value);
    console.log("montantTTC_supp:",this.montantTTC_supp)
  }
  showoperationSelected(){
    console.log("this selected :", this.Percentage);
    console.log("this selectedd :", this.Percentagee);

     this.percent=this.Percentage
     this.percentt=this.Percentagee

     console.log("this.percent",this.percent)
     console.log("this.percentt",this.percentt)
     this.resultMHT=0;

     if(this.Percentagee=="0.07"){
      this.resultMHT=(((this.totalPrice*(parseFloat(this.Percentagee))))).toFixed(2)
     }
     if(this.Percentagee=="0.19"){
      this.resultMHT=(((this.totalPrice*(parseFloat(this.Percentagee))))).toFixed(2)
     }
    this.percent1=0.07
    this.percent2=0.19
    this.Percentage1=this.percent1
    this.Percentage2=this.percent2
    this.tableselect.push("")

  }
 
  addSupplement(Row) {
    let number = this.supps.length + 1
    this.nomsupp = "nom" + number
    this.mt_ht_supp = "mt_ht_supp" + number
    this.taux_tva_supp = "taux_tva_supp" + number
    console.log("this.nomsupp: ", this.nomsupp)
    console.log("this.montantHT_supp[this.supps.length-1]==0: ", this.montantHT_supp[this.supps.length - 1] == 0)
    console.log("this.taux_tva_suppl[this.supps.length-1]==-1: ", this.taux_tva_suppl[this.supps.length - 1] == '-1')
    console.log("Number.isNaN(this.montantHT_supp[this.supps.length-1])): ", Number.isNaN(this.montantHT_supp[this.supps.length - 1]))
    console.log("this.montantHT_supp[this.supps.length-1]==0||!Number.isNaN(this.montantHT_supp[this.supps.length-1]): ", this.montantHT_supp[this.supps.length - 1] == 0 || !Number.isNaN(this.montantHT_supp[this.supps.length - 1]))
    console.log("this.taux_tva_suppl[this.supps.length-1]==-1 || (this.montantHT_supp[this.supps.length-1]==0||!Number.isNaN(this.montantHT_supp[this.supps.length-1]))", this.taux_tva_suppl[this.supps.length - 1] == "-1" || this.montantHT_supp[this.supps.length - 1] == 0 || Number.isNaN(this.montantHT_supp[this.supps.length - 1]))
     if (this.taux_tva_suppl[this.supps.length - 1] == "-1" || this.montantHT_supp[this.supps.length - 1] == 0 || Number.isNaN(this.montantHT_supp[this.supps.length - 1])) {
      alert("Veuillez remplir ces champs en premier.")
      this.supps.splice(this.supps.length - 1, 1)
    }

    else {
      this.supps.push("")
      this.taux_tva_suppl[this.supps.length - 1] = "-1"
      this.montantHT_supp[this.supps.length - 1] = 0
    }
     
    
    //console.log("formValue[nom1]: ",document.getElementById("nom1"))
    // this.supplements.push({id: this.j++ });
    /*  if (this.j < 0) {
       this.j = 0;
     }
     if (this.j === 0) {
       this.boolSupp1 = true;
       this.boolSupp2 = true;
       this.boolSupp3 = false;
       this.boolSupp4 = false;
       this.boolSupp5 = false;
     } else if (this.j === 1) {
       this.boolSupp1 = true;
       this.boolSupp2 = true;
       this.boolSupp3 = true;
       this.boolSupp4 = false;
       this.boolSupp5 = false;
     } else if (this.j === 2) {
       this.boolSupp1 = true;
       this.boolSupp2 = true;
       this.boolSupp3 = true;
       this.boolSupp4 = true;
       this.boolSupp5 = false;
     } else if (this.j === 3) {
       this.boolSupp1 = true;
       this.boolSupp2 = true;
       this.boolSupp3 = true;
       this.boolSupp4 = true;
       this.boolSupp5 = true;
     }
     this.j++; */
    // //console.log(this.j)
   


  }
  onUpdateTvaSupp(event, i) {
    console.log("i",i)
    console.log("event",event)

    const input = document.getElementsByClassName('inp').item(document.getElementsByClassName('inp').length - 1) as HTMLInputElement | null;
    console.log("input: ", input)
    // 👉️ input has type HTMLInputElement or null here

    if (input != null) {
      // 👉️ input has type HTMLInputElement here
      const value = input.value;
      console.log(value); // 👉️ "Initial value"
      console.log("parseInt(value): ", parseInt(value))
      this.montantHT_supp[i] = parseInt(value)
    }
    if (this.montantHT_supp[i] == 0 || Number.isNaN(this.montantHT_supp[i])) {
      alert("Veuillez saisie le montant en premier.")
      this.supps.splice(this.supps.length - 1, 1)
    }
    else {
      console.log("event: ", event)
      console.log("i: ", i)
      let tvaTaux
      console.log(" this.taux_tva_suppl[i]: ", this.taux_tva_suppl[i])

      if (this.taux_tva_suppl[i] != "0") {
        console.log("this.taux_tva_suppl[i]!=undefined: ",this.taux_tva_suppl[i])
        tvaTaux = +this.taux_tva_suppl[i]
        if (this.taux_tva_suppl[i] === "0.07") {
          this.montantTTC_supp[i] = this.montantHT_supp[i] * (1 + tvaTaux);
          this.mt_tva7 -= this.montantHT_supp[i] * tvaTaux;
          this.mt_tva -= this.montantHT_supp[i] * tvaTaux;
          if (this.montantTTC_supp[i] < 0)
            this.montant_total -= -this.montantTTC_supp[i];
          else
            this.montant_total -= this.montantTTC_supp[i];
          this.montant_total_ht -= this.montantHT_supp[i];
        } else {
          this.montantTTC_supp[i] = -this.montantHT_supp[i] * (1 + tvaTaux);

          this.mt_tva19 -= this.montantHT_supp[i] * tvaTaux;
          this.mt_tva -= this.montantHT_supp[i] * tvaTaux;
          if (this.montantTTC_supp[i] < 0)
            this.montant_total -= -this.montantTTC_supp[i];
          else
            this.montant_total -= this.montantTTC_supp[i];
          this.montant_total_ht -= this.montantHT_supp[i];
        }
        console.log("this.montantTTC_supp[i]: ",this.montantTTC_supp[i],"this.mt_tva19: ",this.mt_tva19,"this.mt_tva: ",this.mt_tva,"this.montant_total: ",this.montant_total,"this.montant_total_ht: ",this.montant_total_ht,"this.montantHT_supp[i]: ",this.montantHT_supp[i],"this.montantTTC_supp[i]: ",this.montantTTC_supp[i])
        this.taux_tva_suppl[i] = event;
        tvaTaux = +event;

        this.supplementTotal += +this.montantHT_supp[i];
        if (event === "0.07") {
          this.montantTTC_supp[i] = +this.montantHT_supp[i] * (1 + tvaTaux);
          this.mt_tva7 += +this.montantHT_supp[i] * tvaTaux;
          this.mt_tva += +this.montantHT_supp[i] * tvaTaux;
          this.montant_total += this.montantTTC_supp[i];
          this.montant_total_ht += +this.montantHT_supp[i];
        } else {
          this.montantTTC_supp[i] = +this.montantHT_supp[i] * (1 + tvaTaux);

          this.mt_tva19 += +this.montantHT_supp[i] * tvaTaux;
          this.mt_tva += +this.montantHT_supp[i] * tvaTaux;
          this.montant_total += this.montantTTC_supp[i];
          this.montant_total_ht += +this.montantHT_supp[i];
        }
        
      }
      else {
        let number = i + 1
        console.log("number: ", number)
        console.log("document.getElementsByClassName('inp'): ", document.getElementsByClassName('inp').length)
        console.log("document.getElementsByClassName('inp').item(1): ", document.getElementsByClassName('inp').item(document.getElementsByClassName('inp').length - 1))
        console.log("document.getElementsByClassName('inp').item(2): ", document.getElementsByClassName('inp').item(0))

        const input = document.getElementsByClassName('inp').item(document.getElementsByClassName('inp').length - 1) as HTMLInputElement | null;
        console.log("input: ", input)
        // 👉️ input has type HTMLInputElement or null here

        if (input != null) {
          // 👉️ input has type HTMLInputElement here
          const value = input.value;
          console.log(value); // 👉️ "Initial value"
          this.montantHT_supp[i] = parseInt(value)
        }
        const nom = document.getElementsByClassName('nom').item(document.getElementsByClassName('inp').length - 1) as HTMLInputElement | null;
        console.log("input: ", input)
        // 👉️ input has type HTMLInputElement or null here

        if (nom != null) {
          // 👉️ input has type HTMLInputElement here
          const value = nom.value;
          console.log(value); // 👉️ "Initial value"
          this.nomSupp[i] = value
        }


        this.taux_tva_suppl[i] = event;
        tvaTaux = +event;

        this.supplementTotal += +this.montantHT_supp[i];
        console.log("this.supplementTotal: ", this.supplementTotal)
        if (event === "0.07") {
          this.montantTTC_supp[i] = +this.montantHT_supp[i] * (1 + tvaTaux);
          this.mt_tva7 += +this.montantHT_supp[i] * tvaTaux;
          this.mt_tva += +this.montantHT_supp[i] * tvaTaux;
          this.montant_total += this.montantTTC_supp[i];
          this.montant_total_ht += +this.montantHT_supp[i];
        } else {
          this.montantTTC_supp[i] = +this.montantHT_supp[i] * (1 + tvaTaux);
          this.mt_tva19 += +this.montantHT_supp[i] * tvaTaux;
          this.mt_tva += +this.montantHT_supp[i] * tvaTaux;
          this.montant_total += this.montantTTC_supp[i];
          this.montant_total_ht += +this.montantHT_supp[i];
        }
      }
      console.log("this.montantTTC_supp: ", this.montantTTC_supp)
    }
        
    
  }

 
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected;
    console.log("numSelected",this.selection.selected)
    this.Bookings=this.selection.selected
    console.log("Bookings:",this.selection.selected)
    
    const numRows = this.dataSource.data;
    return numSelected === numRows;

  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
      
  }
  deleteReservation(x) { 
   
      this.row.splice(x, 1 );
    
  }
  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }
  
  facturer(row){
    console.log("row-------",row)
    this.list=false
    this.facture=true
    this.datafacture=this.Bookings
    console.log("datafacture!!: ",this.datafacture)
    for (let i = 0; i < this.Bookings.length; i++) {
      console.log("Bookings.totalPrice[]",this.Bookings[i].TotalPrice)
      this.totalPrice=this.Bookings[i].TotalPrice
      console.log("totalPrice,",this.totalPrice)
      this.NBchild=this.Bookings[i].NumberOfAdults
      this.NumberOfChildren=this.Bookings[i].NumberOfChildren
      this.NumberOfNights=this.Bookings[i].NumberOfNights
      this.NumberOfRooms=this.Bookings[i].NumberOfRooms
      console.log("NBchild",this.NBchild)
      console.log("NumberOfChildren",this.NumberOfChildren)
      console.log("NumberOfNights",this.NumberOfNights)
      console.log("NumberOfRooms",this.NumberOfRooms)

  } 
 


      for (let j = 0; j < this.Bookings.length; j++) {
        console.log("Rooms[]",this.Bookings[j].Rooms[j])
    }
   

  }
  onSubmitAdd(form) {
    console.log(this.montantTTC_supp, this.nomSupp, this.montantHT_supp, this.taux_tva_suppl)
    let montantTTC_sup = []
    let nomSup = []
    let montantHT_sup = []
    let taux_tva_sup = []
    console.log("this.supps.length: ", this.supps.length)
    for (let i = 0; i < this.supps.length; i++) {
      console.log("i: ", i)

      montantTTC_sup.push(this.montantTTC_supp[i])
      console.log("montantTTC_sup: ", montantTTC_sup)
      nomSup.push(this.nomSupp[i])
      console.log("nomSup: ", nomSup)
      montantHT_sup.push(this.montantHT_supp[i])
      console.log("montantHT_sup: ", montantHT_sup)
      taux_tva_sup.push(this.taux_tva_suppl[i])
      console.log("taux_tva_sup: ", taux_tva_sup)
    }
    console.log("montantTTC_sup: ", montantTTC_sup, "nomSup: ", nomSup, "montantHT_sup: ", montantHT_sup, "taux_tva_sup: ", taux_tva_sup)
    console.log("this.taux_tva_supp: ", this.taux_tva_suppl)
    console.log("this.montantTTC_supp: ", this.montantTTC_supp)
    this.submitted = true;
    let formValue = form;

   
    formValue["date_facture"] = format(
      new Date(formValue["date_facture"]),
      "yyyy-MM-dd"
    );
 
    let DateFacture = formValue["date_facture"];
    let montantTotal = this.montant_total.toFixed(3);
    
    let designationRev = this.factureData.hotel;
    let dateArriveRev = this.factureData.dateArrive;
    let dateSortieRev = this.factureData.dateSortie;
    let nbrNuitsRv = this.factureData.nbrNuits;
    let nbrChambres = this.factureData.nb_chambres;
    let nbSuitsRev = this.factureData.nb_suits;
    let nbAdultsRev = this.factureData.nb_adult;
    let nbEnfantsRev = this.factureData.nb_enfant;
    let montantHTRev;
    let tauxTvaRev = 0.07;
    let montantRevTTC;

    

    // montant ttc reservation
    let revArr = this.dataSource;
  
    montantHTRev = +montantRevTTC / 1.07;
    this.mt_tva7 += +montantHTRev * 0.07;
  
    let montantHTSupp1;
    let tvaSupp1;
    let montantTTCSupp1;

    let montantHTSupp2;
    let tvaSupp2;
    let montantTTCSupp2;

    let montantHTSupp3;
    let tvaSupp3;
    let montantTTCSupp3;

    let montantHTSupp4;
    let tvaSupp4;
    let montantTTCSupp4;

    let montantHTSupp5;
    let tvaSupp5;
    let montantTTCSupp5;
    //supplément 1
    let nomSupp1 = formValue["nom1"];
    if (nomSupp1) {
      this.countSupp++;

      montantHTSupp1 = formValue["mt_ht_supp1"];
      tvaSupp1 = this.taux_tva_supp1;
      montantTTCSupp1 = this.montantTTC_supp1.toFixed(3);
    }

    let nomSupp2 = formValue["nom2"];
    if (nomSupp2) {
      this.countSupp++;

      montantHTSupp2 = formValue["mt_ht_supp2"];

      // tvaSupp2 = this.taux_tva_supp2;
      //montantTTCSupp2 = this.montantTTC_supp2.toFixed(3);
    }

    //supplément 3
    let nomSupp3 = formValue["nom3"];
    if (nomSupp3) {
      this.countSupp++;

      montantHTSupp3 = formValue["mt_ht_supp3"];
      tvaSupp3 = this.taux_tva_supp3;
      montantTTCSupp3 = this.montantTTC_supp3.toFixed(3);
    }

    //supplément 4
    let nomSupp4 = formValue["nom4"];
    if (nomSupp4) {
      this.countSupp++;

      montantHTSupp4 = formValue["mt_ht_supp4"];
      tvaSupp4 = this.taux_tva_supp4;
      montantTTCSupp4 = this.montantTTC_supp4.toFixed(3);
    }

    //supplément 5
    let nomSupp5 = formValue["nom5"];
    if (nomSupp5) {
      this.countSupp++;

      montantHTSupp5 = formValue["mt_ht_supp5"];
      tvaSupp5 = this.taux_tva_supp5;
      montantTTCSupp5 = this.montantTTC_supp5.toFixed(3);
    }

    let supplementTotalHt = this.supplementTotal.toFixed(3);
    let totalMontantTVA = this.mt_tva.toFixed(3);
    let countSupp = this.countSupp;
   

   
  }
 
}

