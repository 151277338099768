import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';

import { map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'vex-cancellation',
  templateUrl: './cancellation.component.html',
  styleUrls: ['./cancellation.component.scss']
})
export class CancellationComponent implements OnInit {
  public formCancellation: FormGroup

  selected = new FormControl(0);

  TabPeriodes: any[]
  options = [
    { value: true, display: "%" },
    { value: false, display: "#" }
  ];
  filterBoardingforboardingg$: Observable<any[]>
  destinationBoardForCancellation: UntypedFormControl;
  BoardConcellation: any;
  disableButton:boolean=false

  Category: string;
  Type: string;
  constructor(@Inject(MAT_DIALOG_DATA)  public defaults: any, private dialog:MatDialog,
  
  private router: Router,
  private fb: UntypedFormBuilder, private ContratService: ApiService, 
  public datepipe: DatePipe,
   private cd: ChangeDetectorRef, private formBuilder: FormBuilder, private cdr: ChangeDetectorRef, 
   private dialogRef: MatDialogRef<CancellationComponent>) {}

  ngOnInit(): void {
    console.log("data from edit",this.defaults)
    this.TabPeriodes=[this.defaults.period]

    this.formCancellation = this.fb.group({
      PeriodGroupes: this.fb.array([])  // FormArray for PeriodGroupes
    }); 
    this.Category=this.defaults.category
    this.Type=this.defaults.type

    if( this.Type==='insert'){
      this.loadCancellationConditionsInsert()

    }
    else if(this.Type==='update'){
      this.loadCancellationConditionsUpdate()

    }



  }

  showSuccessAlert(message): void {
    Swal.fire({
      title: 'Success',
      text: message,
      icon: 'success',
      confirmButtonText: 'Okay',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.dialogRef.close()
        console.log('Okay button pressed');
      } else if (result.isDismissed) {
        this.dialogRef.close()
        console.log('Dialog was dismissed');
      }
    });
   

  }
  showWarningAlert(message): void {
    const dialogswal=Swal.fire({
      title: 'Warning',
      text: message,
      icon: 'warning',
      confirmButtonText: 'Okay',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      allowOutsideClick: false
    })
  
   
  
  }
  showErrorAlert(): void {
  
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    });
   

  }
  
 ///////////////////////////////
/*START CANCELLATION METHODES*/
loadCancellationConditionsUpdate(): void {
  var board_id=0
  var room_id=0
  var room_type_id=0
  var type_id=null
  var type=''
  console.log(this.TabPeriodes[0].id,type_id,type)


  switch (this.Category) {
    case 'room':
      room_id=this.defaults.data.room_id
      type_id=room_id
      type='room_id'

      break;
      case 'board':
        board_id=this.defaults.data.arrangement_id
        type_id=board_id
        type='boardc_id'


        break;
    case 'roomType':
      room_type_id=this.defaults.data.type_id
      type_id=room_type_id
      type='room_type_id_cancel'


  
        break;
  
      default:
          break;
  }

  this.ContratService.getCancellationsByPeriodType(this.TabPeriodes[0].id,type_id,type).subscribe(respCancellationConditions => {
    console.log("respCancellationConditions: ", respCancellationConditions);
    const cancellations=respCancellationConditions.cancellations
   
    const periodGroupesArray = this.formCancellation.get('PeriodGroupes') as FormArray;
    periodGroupesArray.clear();
      this.TabPeriodes=[this.defaults.period]
      const periodGroup = this.fb.group({
        Period_id: [this.TabPeriodes[0].id],
        Period_name: [this.TabPeriodes[0].name],
        CancellationConditions: this.fb.array([])
      });
      const cancellationConditionsArray = periodGroup.get('CancellationConditions') as FormArray;
    



    if (cancellations.length!=0){

    


      cancellations.forEach(condition => {

    const conditionGroup = this.fb.group({
      id:[condition.id],
      board_id: [condition.board_id],
      room_id: [condition.room_id],
      room_name: [condition.room_name],
      refundable: [condition.refundable],

      board_name: [condition.board_name],
      room_type_id_concel: [condition.room_type_id_concel],
      min_nights: [condition.min_nights],
      max_nights: [condition.max_nights],
      min_days_before_arrival: [condition.min_days_before_arrival],
      nights_to_bill: [condition.nights_to_bill],
      night_value: [this.defaults.data.price],
      operation: [condition.operation],


      no_show_nights_to_bill: [condition.no_show_nights_to_bill],
      no_show_night_value: [condition.no_show_night_value],
      bill_stay_value: [condition.bill_stay_value],
      free_cancel_before: [condition.free_cancel_before],
      no_show: [condition.no_show],
      bill_stay: [condition.bill_stay],
      free_cancel: [condition.free_cancel],
      room_type_name: [condition.room_type_name]
    });
    cancellationConditionsArray.push(conditionGroup);
    console.log("cancellationConditionsArray: ", cancellationConditionsArray);


 
});
}
else {
  cancellationConditionsArray.push(
    this.fb.group({
      id:new FormControl(null, ),
      board_id: new FormControl(board_id, ),
      board_name: new FormControl(this.defaults.data.boarding_name ?? '', ),
      room_name: new FormControl(this.defaults.data.room_name ?? '', ),
      refundable : new FormControl(true, ),

      room_id:new FormControl(room_id, ),
      min_nights: new FormControl(0, ),
      max_nights: new FormControl(0, ),
      min_days_before_arrival: new FormControl(0, ),
      nights_to_bill: new FormControl(0, ),
      night_value: new FormControl(this.defaults.data.price, ),
      operation: new FormControl(false, ),

      bill_stay: new FormControl(false, ),
      bill_stay_value: new FormControl(0, ),
      no_show: new FormControl(false, ),
      no_show_nights_to_bill: new FormControl(0, ),
      no_show_night_value: new FormControl(0, ),
      free_cancel: new FormControl(false, ),
      free_cancel_before: new FormControl(0, ),
      room_type_id_concel: new FormControl(room_type_id, ),
      room_type_name: new FormControl(this.defaults.data.room_type ?? '', )
    
    
    }));
}

periodGroupesArray.push(periodGroup);



   
console.log("periodGroupesArray: ", periodGroupesArray);




  });
}
loadCancellationConditionsInsert(): void {

   
  const periodGroupesArray = this.formCancellation.get('PeriodGroupes') as FormArray;
  periodGroupesArray.clear();
console
console.log("this.TabPeriodes",this.TabPeriodes)
  
const periodGroup = this.fb.group({
  Period_id: [this.TabPeriodes[0].id],
  Period_name: [this.TabPeriodes[0].name],
  CancellationConditions: this.fb.array([])
});
    const cancellationConditionsArray = periodGroup.get('CancellationConditions') as FormArray;
    periodGroupesArray.push(periodGroup);
var board_id=0
var room_id=0
var room_type_id=0
switch (this.Category) {
  case 'room':
    room_id=this.defaults.data.room_id
    break;
    case 'board':
      board_id=this.defaults.data.arrangement_id

      break;
  case 'roomType':
    room_type_id=this.defaults.data.type_id

      break;

    default:
        break;
}



    cancellationConditionsArray.push(
      this.fb.group({
        id:new FormControl(null, ),
        board_id: new FormControl(board_id, ),
        board_name: new FormControl(this.defaults.data.boarding_name, ),
        room_name: new FormControl(this.defaults.data.room_name, ),
        refundable : new FormControl(true, ),

        room_id:new FormControl(room_id, ),
        min_nights: new FormControl(0, ),
        max_nights: new FormControl(0, ),
        min_days_before_arrival: new FormControl(0, ),
        nights_to_bill: new FormControl(0, ),
        night_value: new FormControl(this.defaults.data.price, ),
        bill_stay: new FormControl(false, ),
        operation: new FormControl(false, ),

        bill_stay_value: new FormControl(0, ),
        no_show: new FormControl(false, ),
        no_show_nights_to_bill: new FormControl(0, ),
        no_show_night_value: new FormControl(0, ),
        free_cancel: new FormControl(false, ),
        free_cancel_before: new FormControl(0, ),
        room_type_id_concel: new FormControl(room_type_id, ),
        room_type_name: new FormControl(this.defaults.data.room_type, )
      
      
      }));
  







console.log("periodGroupesArray: ", periodGroupesArray);





}
getBillStay(i,groupIndex){
const periodGroup = this.formCancellation.get('PeriodGroupes') as FormArray;
const conditionGroup = periodGroup.at(groupIndex).get('CancellationConditions') as FormArray;
const itemConcell = conditionGroup.at(i) as FormGroup;

return  (itemConcell.get('bill_stay') as FormArray).value;

}
getFreeCancel(i,groupIndex){
const periodGroup = this.formCancellation.get('PeriodGroupes') as FormArray;
const conditionGroup = periodGroup.at(groupIndex).get('CancellationConditions') as FormArray;
const itemConcell = conditionGroup.at(i) as FormGroup;

return  (itemConcell.get('free_cancel') as FormArray).value;

}
getNoShow(i,groupIndex){
const periodGroup = this.formCancellation.get('PeriodGroupes') as FormArray;
const conditionGroup = periodGroup.at(groupIndex).get('CancellationConditions') as FormArray;
const itemConcell = conditionGroup.at(i) as FormGroup;

return  (itemConcell.get('no_show') as FormArray).value;

}
NowShow(i,groupIndex) {
const periodGroup = this.formCancellation.get('PeriodGroupes') as FormArray;
const conditionGroup = periodGroup.at(groupIndex).get('CancellationConditions') as FormArray;
const itemConcell = conditionGroup.at(i) as FormGroup;

const noShow = (itemConcell.get('no_show') as FormArray).value;
itemConcell.patchValue({
  no_show : !noShow
});
  this.cd.detectChanges()


// this.ActivateNowShow = !this.ActivateNowShow
// this.cd.detectChanges()
// console.log("this.ActivateNowShow: ", this.ActivateNowShow)
}
BillPerStay(i,groupIndex) {
const periodGroup = this.formCancellation.get('PeriodGroupes') as FormArray;
const conditionGroup = periodGroup.at(groupIndex).get('CancellationConditions') as FormArray;
const itemConcell = conditionGroup.at(i) as FormGroup;

const bill_stay = (itemConcell.get('bill_stay') as FormArray).value;
itemConcell.patchValue({
  bill_stay : !bill_stay
});
  this.cd.detectChanges()
}
FreeCancellation(i,groupIndex) {
const periodGroup = this.formCancellation.get('PeriodGroupes') as FormArray;
const conditionGroup = periodGroup.at(groupIndex).get('CancellationConditions') as FormArray;
const itemConcell = conditionGroup.at(i) as FormGroup;

const free_cancel = (itemConcell.get('free_cancel') as FormArray).value;
itemConcell.patchValue({
  free_cancel : !free_cancel
});
  this.cd.detectChanges()

}
getRefundable(i,groupIndex){
const periodGroup = this.formCancellation.get('PeriodGroupes') as FormArray;
const conditionGroup = periodGroup.at(groupIndex).get('CancellationConditions') as FormArray;
const itemConcell = conditionGroup.at(i) as FormGroup;

return  (itemConcell.get('refundable') as FormArray).value;

}
Refundable(i,groupIndex) {
const periodGroup = this.formCancellation.get('PeriodGroupes') as FormArray;
const conditionGroup = periodGroup.at(groupIndex).get('CancellationConditions') as FormArray;
const itemConcell = conditionGroup.at(i) as FormGroup;

const refundable = (itemConcell.get('refundable') as FormArray).value;
itemConcell.patchValue({
  refundable : !refundable
});
  this.cd.detectChanges()


// this.ActivateNowShow = !this.ActivateNowShow
// this.cd.detectChanges()
// console.log("this.ActivateNowShow: ", this.ActivateNowShow)
}
get periodGroupes(): FormArray {
return this.formCancellation.get('PeriodGroupes') as FormArray;
}
getCancellationConditions(periodGroup: AbstractControl): FormArray {

return periodGroup.get('CancellationConditions') as FormArray;
}


createItemCondition(): FormGroup {

  return this.formBuilder.group({
    id:new FormControl(null, ),
    board_id: new FormControl(0, ),
    board_name: new FormControl('', ),
    room_id: new FormControl(this.defaults.data.room_id, ),
    room_name: new FormControl(this.defaults.data.room_name, ),
    refundable : new FormControl(true, ),
    operation: new FormControl(false, ),
    min_nights: new FormControl(0, ),
    max_nights: new FormControl(0, ),
    min_days_before_arrival: new FormControl(0, ),
    nights_to_bill: new FormControl(0, ),
    night_value: new FormControl(this.defaults.data.price, ),
    bill_stay: new FormControl(false, ),
    bill_stay_value: new FormControl(0, ),
    no_show: new FormControl(false, ),
    no_show_nights_to_bill: new FormControl(0, ),
    no_show_night_value: new FormControl(0, ),
    free_cancel: new FormControl(false, ),
    free_cancel_before: new FormControl(0, ),
    room_type_id_concel: new FormControl(0, ),
    room_type_name: new FormControl('', )


  })

}
AddnewrowConcell(periodGroup: AbstractControl) {
  var itemConcell = periodGroup.get('CancellationConditions') as FormArray;
  itemConcell.push(this.createItemCondition());
  this.cd.markForCheck(); 

  this.cd.detectChanges()

}
RemoveitemCancell(periodGroup: AbstractControl,index: any) {
const itemConcell = periodGroup.get('CancellationConditions') as FormArray;


  itemConcell.removeAt(index)
  this.cd.detectChanges()



 
    

  }

groupByPeriodId(conditions: any[]): { [key: string]: any[] } {
  return conditions.reduce((groups, condition) => {
    const periodId = condition.periodid;
    if (!groups[periodId]) {
      groups[periodId] = [];
    }
    groups[periodId].push(condition);
    return groups;
  }, {});
}
SaveCancellationConditions(periodGroup: AbstractControl): void {
console.log("SaveCancellationConditions"+this.formCancellation.value)

  if (this.formCancellation.status=='VALID'){
    const CancellationConditions = (periodGroup.get('CancellationConditions') as FormArray).value;
    const periodId = (periodGroup.get('Period_id') as FormArray).value;
    console.log('Current periodId:', periodId);
  
    
    console.log('Current formValue:', CancellationConditions);
    
  
   
        
     
  
    const objcancella = {
        periodid: periodId,
        cancellationConditions: CancellationConditions,
    };
    
    console.log('objcancella:', objcancella);
  
    if(this.Type==='insert'){
      this.disableButton=true
  
      this.ContratService.PostcancellationCondition(objcancella).subscribe(
        (resp) => {
            console.log('Response from Post cancellation condition:', resp);
            this.showSuccessAlert(resp.message)
        },
        (error) => {
          this.disableButton=false
  
            console.error('Error updating cancellation conditions:', error);
            this.showErrorAlert()
  
        }
    ); 
    }
    else if(this.Type==='update'){
      this.disableButton=true
  
      this.ContratService.PutcancellationCondition(objcancella).subscribe(
        (resp) => {
            console.log('Response from Put cancellation condition:', resp);
            this.showSuccessAlert(resp.message)
        },
        (error) => {
          this.disableButton=false
  
            console.error('Error updating cancellation conditions:', error);
            this.showErrorAlert()
  
        }
    ); 
    
    }
   

  }
  else {
    this.showWarningAlert('All fields are required')

  }
  
}


/*END CANCELLATION METHODES*/
///////////////////////////////
}
