import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatTableDataSource } from '@angular/material/table';
import { map, Observable, of, ReplaySubject, startWith } from 'rxjs';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { aioTableLabels } from 'src/static-data/aio-table-data';
import { ApiService } from '../api.service';
import { Contrat } from '../interfaces/contrat.model';
import { CancellationComponent } from '../cancellation/cancellation.component';
import Swal from 'sweetalert2';
import { error } from 'console';

@Component({
  selector: 'vex-create-contrat',
  templateUrl: './create-contrat.component.html',
  styleUrls: ['./create-contrat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]

})
export class CreateContratComponent implements OnInit {
  customers: any[];
  number: number = 0;
  static id: 100;
  rooms: string = '';
  CodePeriod: string;
  room_id: number
  room: any[] = []
  event: string;
  list_room: [];
  selectedTabIndex = 0;
  TabCanellationCondtions: any[] = []
  public form: FormGroup;
  public formContrat: FormGroup;
  public formPeriode: FormGroup;
  public formRooms: FormGroup;
  public formBaseRoom: FormGroup;
  public formBoarding: FormGroup
  Alldatasource: FormGroup;
  public formAllotement: FormGroup
  public formCancellation: FormGroup

  
  public formChild: FormGroup;
  start_at = new FormControl();
  destinationroomtypeForCancellationConditions: UntypedFormControl;
  end_at = new FormControl();
  Periodlist: any
  filterBoardingforboardingextrat$: Observable<any[]>;
  destinationBoardExtra: UntypedFormControl;
  BoardExtra: any[] = []
  ArrayRoomTypeCancellation:any[]=[]
  boardnameextra
  selectedboardextra
  mode: 'create' | 'update' | 'duplicate' = 'create';
  ROOM: string;
  selectedRoomPrices
  CheckIn: string;
  IdRooms: any[] = [];
  IdBoard: any[] = [];
  IdTypeRoom: any[] = [];
  Room: any;
  Type: any
  idboard: any;
  idrooms: any;
  periods: any[] = [];
  HotelName: any;
  Name: any;
  extbPrice: any;
  value: any;
  valuee: any;
  selectvaluee: string;
  selectedroomname: any;
  allotement: any
  IdRoom: any;
  roomnamePrices: number;
  rommPricename: any[];
  version: number;
  hotelname: any;
  selectvalueaceess: any;
  basepriceid: any;
  idPrice: any;
  priceid: any;
  period: { period: any; } & { rooms: any[]; };
  periodId: any;
  selectvalueDevise: string;
  market: any;
  def: any;
  idmarket: any;
  marketname: any;
  boardId: any[] = []
  roomname: any;
  prices: number;
  togglevalue: any
  AgeTo: number;
  selectedroomForChildPrices: any;
  Pricesroomname: any;
  SelectedValue: any;
  NB: number;
  Age: number;
  childs: any;
  PricesRoomtype: any;
  selectedboardForChildPrices: any;
  Pricesboard: any;
  showNumP = true
  codeRoomtype: void;
  minadult: any = {};
  selectedboardPeriod: any;
  IdContratPeriod: void[];
  namePeriode: any;
  IdContratBoardExtrat: any;
  IdcontratbyBoardExtrat: any;
  boardextraId: any;
  Boardarrayy: any;
  extraboard: any;
  boardextratid: any;
  extra_board_id: any;
  Code: any;
  selectedConcel: any;
  BoardConcellName: any;
  selectedRoomTypeConcellation: any;
  TypeConcellation_name: any;
  TypeConcellation_id: any;
  BoardConcellid: any;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any, private formBuilder: FormBuilder, private dialog:MatDialog,
    private dialogRef: MatDialogRef<CreateContratComponent>,
    private fb: UntypedFormBuilder, private ContratService: ApiService, public datepipe: DatePipe
    , private cd: ChangeDetectorRef, private cdr: ChangeDetectorRef) {

  }
// DRAFT CONTRAT VARIAVLES
contratUpdatedSucess:Boolean=false
periodUpdatedSucess:Boolean=false
roomUpdatedSucess:Boolean=false
allotementUpdatedSucess:Boolean=false
pricesUpdatedSucess:Boolean=false








  RoomsForAllotement: any[] = []
  filterRoomTypeContrat$: Observable<any[]>;
  filterRoomTypeContratForPrices$: Observable<any[]>
  filterBoardingChild$: Observable<any[]>
  filterRoomTypeContratForChild$: Observable<any[]>
  DisabledBoardArray: boolean[][] = [[true],[true]];
  DisabledRoomArray: boolean[][] = [[true],[true]];

  DisabledRoomTypeArray: boolean[][] = [[true],[true]];
  
  filterRoomContratForPrices$: Observable<any[]>

  filterBoardingContratForPrices$: Observable<any[]>
  filterBoarding$: Observable<any[]>
  filterBoardingPeriod$: Observable<any[]>
  Definition: string
  fltDestinationsRoom$: Observable<any[]>;
  filterRoomChild$: Observable<any[]>;

  destinationRoomPrices: UntypedFormControl;
  ContratRoom: UntypedFormControl;
  selectedRoomType: any = {}
  ContratRoomType;
  roomName: string
  item!: FormArray;
  itemConcell!: FormArray;
  itemPeriode!: FormArray;
  items!: FormArray;
  destinationBoard: UntypedFormControl;
  destinationBoardPeriod: UntypedFormControl;
  destinationBoardForCancellationConditions: UntypedFormControl;
  destinationBoardForCancellationCondition: UntypedFormControl;

  destinationBoardPrices: UntypedFormControl;
  beginP: Date;
  ROOM0: string;
  CalculL: string;
  CalculForRoom: boolean;
  CalculForTypeRoom: boolean
  CalculForBoard: boolean;
  isSaving = false; // Variable pour gérer l'état du bouton
  Calcul: string;
  tablo: string[] = []
  tableselect: string[] = []
  BeginContrat: any;
  EndContrat: any
  CheckInPeriod: any
  changeCheckInPeriod: any;
  changeCheckInn_period: any;
  changeCheckIn_Board: any;
  changeCheckInEndBoard: any;
  destination: UntypedFormControl;
  destinationRoomChildPrice: UntypedFormControl;
  filterRoomTypeConcellation$: Observable<any[]>;

  public fltDestinations$: Observable<any[]>;
  DestinationCtrl: UntypedFormControl;
  filterContrat$: Observable<any[]>;
  destinationCtrl: UntypedFormControl;
  RoomTypeForPrices: UntypedFormControl;
  BoardingForPrices: UntypedFormControl;
  public formExtraBoarding: FormGroup;

  selectedRoomTypePrices
  filteredHotels$: Observable<any[]>;
  selectedmap
  type_id
  public useDefault = false;
  public useDefaultoperation = false;

  name
  IdContrat: number
  IdPeriod: any[] = []
  ContractName: string
  ContractHotelid: number
  selectedcontrat
  Filters
  BoardPrice: any[]
  /***************RoomTypePrices************* */
  tabidroomsForPrices: any[] = []
  tabiPeriodesForPrices: any[] = []
  ArrayBoardingCancellation:any[] = []
  tabidtyperoomsForPrices: any[] = []
  tabidboardsForPrices: any[] = []
  tableauBorad: any[] = []
  tableauRoomType: any[] = []
  tableauPriceType: any[] = []
  tableauEXTBPriceType: any[] = []
  tableauTypeRoom: any[] = []
  /***********BoardPRICES************** */
  Boarding: any
  PriceBoard: number
  isDisabledMap: Map<string, boolean> = new Map();

  EXTBPriceBoard: number
  BoardT: any[]
  tableauRoomTypecancell: any[] = []
  tableauBoard: any[] = []
  CodeTab: any[] = []
  tableauPriceBoard: any[] = []
  tableauEXTBPriceBoard: any[] = []
  tableauTypeBoard: any[] = []
  TypeCalculatBoard: boolean

  ActivateNowShow: boolean = false
  ActivateBillPerStay: boolean = false
  ActivateFreeCancellation: boolean = false
  PeriodeArrayy: FormArray
  /***********RoomPrices************ */
  tableauRoom: any[] = []
  tableauperiode: any[] = []
  FilterTableRoomPrices: any[] = []
  request: any = {}
  tableauEXTBPrice: any[] = []
  tableauPrice: any[] = []
  tableauType: any[] = []
  tableauBase: any[] = []
  selectedroom: any = {}; // Initialisation avec un objet vide
  inputFieldValue
  Board: any[];
  RoomName: any[];
  RoomType: any[];
  Contrat: any[];
  RoomTypeConcel: any[];
  periodforallotement: any[] = []
  Hotel: any[];
  selectvalue: string
  ArrayTypeRoom: any[] = [];
  ArrayBoarding: any[] = [];
  ArrayIdBoardExtra: any
  Arraydef: any[] = []
  ArrayRoomName: any[] = [];
  ArrayRoomNamePrices: any[] = [];
  ArrayRoomTypePrices: any[] = [];
  ArrayBoardingPrices: any[] = [];
  roomNameforAllo: any;
  selectedBoardPrices
  periodNum: number
  EXTBPrice: number
  PeriodL: any
  index: number = 1
  selectedIndex: number = 0;
  isLinear = true;

  Price: number
  PriceType: number
  EXTBPriceType: number
  TypeCalculatType: boolean
  RoomTypePrice: any[]
  type = "true"
  boardtype = "true"
  roomtype = "true"
  itemm: boolean
  allotements: any[] = []
  accessL: string
  boardIdTab: any[] = []
  TypeCalculat: boolean
  activate: boolean[] = []
  child: number;
  filterBoardingforboardingg$: Observable<any[]>
  currentStepIndex = 0; // Initialisez à l'index de la première étape

  tableColumns: TableColumn<any>[] = [
    {
      label: 'Rooms',
      property: 'room',
      type: 'text',
      cssClasses: ['font-medium']
    },
  ]
  Roomsforallotement: any[] = []
  labels = aioTableLabels;
  tableauBoradCancell:any[]=[]
  RoomNameP: any[]
  layoutCtrl = new UntypedFormControl('boxed');
  subject$: ReplaySubject<Contrat[]> = new ReplaySubject<any[]>(1);
  data$: Observable<any[]> = this.subject$.asObservable();
  selectedboard
  Arrangement: any[];
  tableauBoardExtrat: any[] = []
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new UntypedFormControl();
  data_Prices: MatTableDataSource<any> | null;
  displayedPrices: string[] = ['Room', 'Definition', 'RoomType', 'Board',
    'Period', 'BuyingPrice', 'EXTBPrice', 'BoardPrice', 'PriceType'
  ];
  columnToDisplayP: string[] = this.displayedPrices.slice();
  //Room
  dataSource: MatTableDataSource<any> | null;
  dataSourceRooms: MatTableDataSource<any> | null;
  dataSourceRoomstype: MatTableDataSource<any> | null;
  dataSourcePricesRooms: MatTableDataSource<any> | null;
  filterBoardingforboarding$: Observable<any[]>;
  filterBoardingforboardingForCancellationCondirions$: Observable<any[]>;

  displayedColumns: string[] = [];
  columnToDisplayed: string[] = this.displayedColumns.slice();
  Rows: any[] = []
  RowsForAllotement: any[] = []
  ArrayRoomBoardForChild: any[] = []
  ArrayRoomNameForChild: any[] = []
  ArrayRoomTypeForChild: any[] = []
  periodeForChild: any[] = []
  nbchild: any[] = []
  Boardarray: any[]
  BoardConcell: any[] = []

  ageAdult: any[] = []
  ageToadult: any[] = []
  pricesAdult: any[] = []
  RowsRooms: any[] = []
  RowsBoard: any[] = []
  RowsRoomType: any[] = []
  RowsRoomNameP: any[] = []
  childprices: any[] = []
  Arraychild: any[] = [];
  columns: any[] = []; // Define your columns array
  NbAdult: number
  NbAdulttab: number[] = [];
  periode: any[] = []
  maxToDate: Date | null;
  mintoDate = new Date();
  mintoDatecontract = new Date();
  PeriodBoard: any[] = []
  displayedColPrice: string[] = ['child_number', 'age_from', 'age_to', 'price', 'operation'];


  minFromDate = new Date();
  maxToDateperiod: Date | null;
  maxFromDate: Date | null;

  BoardForBasePrice: any[] = []
  RoomForBasePrices: any[] = []
  TableauRoomPrices: any[] = []
  TableauRoomTypePrices: any[] = []
  TableauBoardPrices: any[] = []

  //Room price:room
  displayedC: string[] = ['room', 'Price', 'EXTBPrice', 'TypeCalculat','Cancellations'];
  columnToDisplay: string[] = this.displayedC.slice();
  columnsRoom = [
    { columnDef: 'room', header: 'Room' },
    { columnDef: 'Price', header: 'Price' },
    { columnDef: 'EXTBPrice', header: 'EXTBPrice' },
    { columnDef: 'TypeCalculat', header: 'TypeCalculat' },
    { columnDef: 'Cancellations', header: 'Cancellations' },
  ]

  //Room Type price:roomtype
  columnToDisplayType: string[] = ['RoomType', 'PriceRoomType', 'EXTBPriceType', 'TypeCalculatType','Cancellations'];
  columnToDisplaye: string[] = this.columnToDisplayType.slice();
  columnsRoomtype = [
    { columnDeftype: 'RoomType', header: 'RoomType' },
    { columnDeftype: 'PriceRoomType', header: 'Price' },
    { columnDeftype: 'EXTBPriceType', header: 'EXTBPrice' },
    { columnDeftype: 'TypeCalculatType', header: 'TypeCalculat' },
    { columnDeftype: 'Cancellations', header: 'Cancellations' },


  ]

  //BoardingPrices
  dataSourceBoard: MatTableDataSource<any> | null;
  columnToDisplayBoard: string[] = ['Board', 'PriceBoard', 'EXTBPriceBoard', 'TypeCalculatBoard','Cancellations'];
  columnToDisplayeBoard: string[] = this.columnToDisplayBoard.slice();
  columnsBoard = [
    { columnDefBoard: 'Board', header: 'Board' },
    { columnDefBoard: 'PriceBoard', header: 'Price' },
    { columnDefBoard: 'EXTBPriceBoard', header: 'EXTBPrice' },
    { columnDefBoard: 'TypeCalculatBoard', header: 'TypeCalculat' },
    { columnDefBoard: 'Cancellations', header: 'Cancellations' },


  ]
  // PricesTABLEAU
  dataSourcePrices: MatTableDataSource<any> | null;
  columnToDisplayPrices: string[] = ['code', 'name', 'start_at', 'end_at', 'room_name',
    'type_name', 'board_name', 'price', 'price_with_extb'];
  columnToDisplayePrices: string[] = this.columnToDisplayPrices.slice();
  columnsPrices = [
    { columnDefPrices: 'code', header: 'Code.' },
    { columnDefPrices: 'name', header: 'Name' },
    { columnDefPrices: 'start_at', header: 'StartAt' },
    { columnDefPrices: 'end_at', header: 'EndAt' },
    { columnDeffPrices: 'room_id', header: 'RoomID' },
    { columnDeffPrices: 'room_name', header: 'RoomName' },
    { columnDeffPrices: 'type_name', header: 'TypeName' },
    { columnDeffPrices: 'board_name', header: 'BoardName' },
    { columnDeffPrices: 'price', header: 'Price' },
    { columnDeffPrices: 'price_with_extb', header: 'EXTBPrice' },

  ]
  DeviseList: any[] = [
    { value: 'TND', viewValue: 'TND' },
    { value: 'EUR', viewValue: 'EUR' },
    { value: 'USD', viewValue: 'USD' },
    { value: 'DZD', viewValue: 'DZD' },
    { value: 'LYD', viewValue: 'LYD' },
    { value: 'EGP', viewValue: 'EGP' },
    { value: 'AED', viewValue: 'AED' },

  ]
  DeviseL: string
  @ViewChild('stepper') stepper;

  data: MatTableDataSource<any> | null;
  staticListCtrl: Array<UntypedFormControl> = [];
  staticListCtrl2: Array<UntypedFormControl> = [];

  Periodes: any[] = []
  Roomes: any[]
  Boardings: any[]
  Rooms: any[] = []
  tableauChild: any[] = []
  selecteP: any
  FilterTableBoardPrices: any[] = []
  FilterTableRoomTypePrices: any[] = []
  RoomTypeForBasePrices: any[] = []

  RoomsTypes: any[]
  Number: any[] = []
  displayedtype: string[] = ['Type', 'Price', 'EXTBPrice', 'TypeCalculat'];
  columnToDisplayT: string[] = this.displayedtype.slice();
  data_type: MatTableDataSource<any> | null;
  //Room price:Board
  displayedBoard: string[] = ['Board', 'Price', 'EXTBPrice', 'TypeCalculat'];
  columnToDisplayB: string[] = this.displayedBoard.slice();
  data_Board: MatTableDataSource<any> | null;
  selected = new FormControl(0);
  board_id: number
  marketCtrl: UntypedFormControl;
  selectedmarket
  filteredMarket$: Observable<any[]>;
  Market: any[]

  Accesslist: any[] = [
    { value: 'B2B', viewValue: 'B2B' },
    { value: 'B2C', viewValue: 'B2C' },
    { value: 'BOTH', viewValue: 'BOTH' },
  ]
  CalculList: any[] = [
    { value: true, viewValue: 'Per Pax' },
    { value: false, viewValue: 'Per Room' },
  ]
  RoomNameForPrice
  ContratRoomTypeForPrices
  ContratRoomTypeForChildPrices
  boardings: FormArray;
  rows: any[] = [];
  editRowId: any
  id: any
  definition = new UntypedFormControl();

  options = [
    { value: true, display: "%" },
    { value: false, display: "#" }
  ];
  board_idPrices: number

  childT: any[] = []
  PeriodeForChildPrice: any[] = []

  ngOnInit(): void {
    this.initFormContrat()
    this.initFormPeriods()
    this.initFormRoom()
    this.initFormBoard()
    this.initFormAllotement()
    this.getAllRooms();
    this.getAllRoomTypes();
    this.Alldatasource = new FormGroup({
      dataSourceRooms: this.fb.array([]),
      dataSourceRoomstype: this.fb.array([]),
      dataSourceBoard: this.fb.array([]),
      dataSourcePrices: this.fb.array([]),
      room_price: new FormControl(''),
      type: new FormControl(''),
      type_price: new FormControl(''),
      roomtype_type: new FormControl(''),
      board_price: new FormControl(''),
      type_board: new FormControl(''),

    })
    this.gethotels();
    this.getArrangementt();
    this.getContrats();
    this.GetMarket()
    this.initFormChildPrice()
  }
//***********************ALERTS SWEET *******************
showSuccessAlert(message): void {
  Swal.fire({
    title: 'Success',
    text: message,
    icon: 'success',
    confirmButtonText: 'Okay',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    allowOutsideClick: false
  }).then((result) => {
    if (result.isConfirmed) {
      this.dialogRef.close()
      console.log('Okay button pressed');
    } else if (result.isDismissed) {
      this.dialogRef.close()
      console.log('Dialog was dismissed');
    }
  });
 

}
showWarningAlert(message): void {
  const dialogswal=Swal.fire({
    title: 'Warning',
    text: message,
    icon: 'warning',
    confirmButtonText: 'Okay',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    allowOutsideClick: false
  })

 

}
showErrorAlert(): void {

  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Something went wrong!",
  });
 

}
//***********************END ALERT***********************

/***********************START CONTRAT INTERFACE***********************/
initFormContrat(){


  this.formContrat = new FormGroup({
    name: new FormControl('', Validators.required),
    hotel_id: new FormControl('', Validators.required),
    hotel_name:new FormControl('', Validators.required),

    start_at: new FormControl('', ),
    end_at: new FormControl('', ),
    version: new FormControl('', ),
    access: new FormControl('',),
    currency: new FormControl('',),
    market: new FormControl('', ),
    market_name: new FormControl('', ),



  });

}
gethotels() {
  this.ContratService.gethotel().subscribe(response => {
  
      this.Hotel = response

      this.filteredHotels$ = of([
        ...response
      ]);
     
    
  })
}
getContrats() {
  this.ContratService.getContratAutoComplete().subscribe(responsecontrat => {
      this.Contrat = responsecontrat
      this.filterContrat$ = of([
        ...responsecontrat
      ]);
  })
}
changeBeginCotrat(event) {

  const end_at = this.formContrat.get("end_at") 

  const BeginContrat = this.datepipe.transform(new Date(event.value), 'yyyy-MM-dd')
    console.log("changeBeginCotrat: ", BeginContrat+ '   ' + end_at.value)

  if(end_at.value && end_at.value<=BeginContrat){
    this.showWarningAlert("Dates must be consecutive")
    this.formContrat.get("start_at").reset()

  }
  else

  this.formContrat.controls['start_at'].setValue(BeginContrat)
  }
changeEndContrat(event) {
  const start_at = this.formContrat.get("start_at") 

  console.log("contrat",start_at)
  const EndContrat = this.datepipe.transform(new Date(event.value), 'yyyy-MM-dd')

  if(start_at.value>=EndContrat){
    this.showWarningAlert("The dates cant be the same")
    this.formContrat.get("end_at").reset()

  }
  else
  
  this.formContrat.controls['end_at'].setValue(EndContrat)

}
GetMarket() {
  this.ContratService.GetMarket().subscribe(response => {
    console.log("ResponseMArket: ", response)


    this.filteredMarket$ = of([
      ...response.items
    ]);
  
  })
}
onChangeDestinationmarket(selected) {

  var selectedmarket 
  this.filteredMarket$.pipe(
    map(arr => arr.find(e => e.name === selected))
  ).subscribe(selectedmarkets => {
    selectedmarket = selectedmarkets;
  }); 

  this.formContrat.controls['market'].setValue(selectedmarket.id)
  this.formContrat.controls['market_name'].setValue(selectedmarket.name)

  this.formContrat.updateValueAndValidity()

}
onChangeHotel(selected) {
  const selectedmap = this.Hotel.find(e => e.name === selected)

 console.log("selectedmap",selectedmap)

  this.formContrat.controls['hotel_id'].setValue(selectedmap.id)
  this.formContrat.controls['hotel_name'].setValue(selectedmap.name)

  this.formContrat.updateValueAndValidity()

}
createContrat() {

  console.log(this.formContrat.value)
  if(this.formContrat.status=='VALID'){

    this.ContratService.CreateContrat(this.formContrat.value).subscribe(respCreateContrat => {
      console.log("respCreateContrat: ", respCreateContrat)
      this.cd.detectChanges();


     
      this.minFromDate=this.formContrat.value.start_at
      this.mintoDate=this.formContrat.value.end_at

        this.IdContrat = respCreateContrat.contrat.id
        this.contratUpdatedSucess=false
        this.stepper.selectedIndex = 1;

      
    })
  }





}
/***********************END Contrat Interface***********************/


//*******************START PERIOD INTERFACE***********************
get PeriodeArray() {
  return this.formPeriode.get("PeriodeArray") as FormArray;
}
initFormPeriods(){

  this.formPeriode = this.fb.group({
    NBperiode: ['',],
  
    PeriodeArray: new FormArray([]),
  })
}
blurEvent(periode) {

  const nbPeriodes = parseInt(periode.target.value) 


  for (let i = 0; i < nbPeriodes; i++) {
    this.AddnewrowPeriode(i+1)
  }
  this.showNumP = false
  this.cd.detectChanges()

}
AddnewNullPeriod(i){
  const controls = this.formPeriode.get("PeriodeArray") as FormArray
  this.itemPeriode = this.formPeriode.get("PeriodeArray") as FormArray;

  if(i!=1){
    if(this.PeriodeArray.at(i-2).status=='VALID' ){
      this.itemPeriode.push(this.GenrowPeriode(i)) 



    }
    else{
      this.showWarningAlert("you need to complete the previous periods first")
    }
}}
changeCheckInn1(event,index) {
  var status=false
  const itemPeriode = this.formPeriode.get("PeriodeArray") as FormArray;
  const formGroupAtIndexminus1 = itemPeriode.at(index-1) as FormGroup;
  const formGroupAtIndex = itemPeriode.at(index) as FormGroup;

  if(index!=0 &&  this.PeriodeArray.at(index-1).status!=='VALID'){
    formGroupAtIndex.get('start_at').reset()

    this.showWarningAlert("you need to complete the previous periods first")

  
  
  }
  else {
  if(index!=0){

 

      if(!formGroupAtIndex.get('end_at').value){
        status=true


      }
       if( this.datepipe.transform(new Date(event.value), 'yyyy-MM-dd')<formGroupAtIndex.get('end_at').value && formGroupAtIndex.get('end_at').value){
        status=true
        
   
       }
       else         
       status=false

       if( this.datepipe.transform(new Date(event.value), 'yyyy-MM-dd')>formGroupAtIndexminus1.get('end_at').value){
        status=true}
        else status=false
  }
  else{
 if(!formGroupAtIndex.get('end_at').value){
  status=true


 }

   else if( this.datepipe.transform(new Date(event.value), 'yyyy-MM-dd')<formGroupAtIndex.get('end_at').value && formGroupAtIndex.get('end_at').value){
     status=true
     

    }
    else status=false




  }
  console.log(" status ", status)

  if(status){
      const start_at = formGroupAtIndex.get('start_at');
      start_at.setValue(this.datepipe.transform(new Date(event.value), 'yyyy-MM-dd'));
  }
  else{
    formGroupAtIndex.get('start_at').reset()
    this.showWarningAlert("the dates should be successive")
  }
 
}}
changeCheckInn2(event,index) {

  const itemPeriode = this.formPeriode.get("PeriodeArray") as FormArray;
    const formGroupAtIndex = itemPeriode.at(index) as FormGroup;


    if(index!=0 &&  this.PeriodeArray.at(index-1).status!=='VALID'){
      formGroupAtIndex.get('end_at').reset()
  
      this.showWarningAlert("you need to complete the previous periods first")
  
    
    
    }
    else{
    if( this.datepipe.transform(new Date(event.value), 'yyyy-MM-dd')>formGroupAtIndex.get('start_at').value){

      const end_at = formGroupAtIndex.get('end_at');
  end_at.setValue(this.datepipe.transform(new Date(event.value), 'yyyy-MM-dd'));
    }
else{
  formGroupAtIndex.get('end_at').reset()
  this.showWarningAlert("the dates should be successive")
}
}


}
GenrowPeriode(i): FormGroup {
  return this.formBuilder.group({
    name: new FormControl(''),
    code: new FormControl(i.toString()),
    start_at: new FormControl(''),
    end_at: new FormControl(''),
  });
}
AddnewrowPeriode(i) {
  //// console.log"<FormArray>this.formPeriode.controls: ", this.formPeriode.controls.length)
  const controls = this.formPeriode.get("PeriodeArray") as FormArray

  this.itemPeriode = this.formPeriode.get("PeriodeArray") as FormArray;
  this.itemPeriode.push(this.GenrowPeriode(i))
}
RemovePeriode(index: any) {
  this.itemPeriode = this.formPeriode.get("PeriodeArray") as FormArray;
  this.itemPeriode.removeAt(index)
}
savePeriode() {

  if(this.formPeriode.get("PeriodeArray").status==='INVALID'){

    

      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "All fields are required!",
      });
     
  
       }
       else {

  let formValue = this.formPeriode.get("PeriodeArray").value




  let objPeriode = Object.assign({ contract_id: this.IdContrat }, { periods: formValue },)
  console.log("objPeriode: ", objPeriode)

  this.ContratService.CreatePeriodeContrat(objPeriode).subscribe(respcreateperiodecontrat => {
    console.log("respcreateperiodecontrat: ", respcreateperiodecontrat)


    let obj
    
      this.Periodlist = respcreateperiodecontrat.period
      this.IdPeriod = respcreateperiodecontrat.period.map(h => h.id)

      this.displayedColumns.push("room")

      obj = Object.assign({ columnDef: "room" }, { header: "room" })
      this.columns.push(obj)

      this.Periodlist.forEach((element,index) => {
        this.displayedColumns.push(element.code + "." + element.name);
        let objColumn = Object.assign({ columnDef: element.code + "." + element.name },
           { header: element.code + "." + element.name }, { id: element.id })
           this.columns.push(objColumn)


      });
 

      this.Periodes = respcreateperiodecontrat.period.map((periode: any) => {
        return { ...periode, pressed: false };
      });  
    
      this.IdContratPeriod = this.Periodes.map(id => { id.contract_id })
      this.getArrangementtPeriod(this.IdContrat)
      this.stepper.selectedIndex=2
      this.initBasePriceForm();

    
    

  })
}
}
getArrangementtPeriod(IdContrat) {
  this.ContratService.getBoardPeriod(IdContrat).subscribe(response => {
    

        this.filterBoardingPeriod$ = of([
       
          ...response
        ]);


      
  
  }, error => {
    console.error("Error fetching board period:", error);
  });
}
//*******************END PERIOD INTERFACE**************************


//*******************START ROOM INTERFACE**************************
initFormRoom(){
  this.formRooms = new FormGroup({
    
    RoomArray: this.formBuilder.array([this.createItemRoom()]),
  });
}
get RoomArrayy() {
  return this.formRooms.get('RoomArray') as FormArray;
}
AddnewrowRoom() {
  this.item = this.formRooms.get("RoomArray") as FormArray;
  this.item.push(this.createItemRoom());
}
Removeitems(index: any) {
  this.item = this.formRooms.get("RoomArray") as FormArray;
  this.item.removeAt(index)
}
getAllRooms() {
  this.ContratService.getRoomName().subscribe(response => {
    console.log("response of room Names: ", response)
   
      
  
    this.fltDestinations$ = of(response.items );
    
    
  })
  


}
getAllRoomTypes() {
  this.ContratService.getRoomtype().subscribe(response => {
    console.log("responseRoomTYpe**: ", response)
      this.filterRoomTypeContrat$ = of(response.items );

  })
}
createItemRoom(): FormGroup {
  return this.formBuilder.group({
    code_room: '',
    room_id: '',
    room_name: '',
    room_type_id: '',
    room_type_name: '',
    code_type: '',
    min_pax: '',
    max_pax: '',
    min_adult: '',
    max_adult: '',
    child: '',
  });
}
onChangeRoomName(selected, index) {
 
  var selectedRoom 
  this.fltDestinations$.pipe(
    map(arr => arr.find(e => e.name === selected))
  ).subscribe(selectedRooms => {
    selectedRoom = selectedRooms;
  });  

    const roomArray = this.formRooms.get('RoomArray') as FormArray;
    const roomGroup = roomArray.at(index) as FormGroup;


    roomGroup.patchValue({
      min_adult: selectedRoom.min_adult,
      max_pax: selectedRoom.max_pax,
      min_pax: selectedRoom.min_pax,
      max_adult: selectedRoom.max_adult,
      child: selectedRoom.child,
      code_room: selectedRoom.name_code,
      room_name:selectedRoom.name,
      room_id:selectedRoom.id,



    });
  
  this.ArrayRoomName[index] = selectedRoom.id

}
onChangeContratRoomType(selected, index) {
  var selectedRoomType 
  this.filterRoomTypeContrat$.pipe(
    map(arr => arr.find(e => e.name === selected))
  ).subscribe(selectedRoomTypes => {
    selectedRoomType = selectedRoomTypes;
  });  

    const roomArray = this.formRooms.get('RoomArray') as FormArray;
    const roomGroup = roomArray.at(index) as FormGroup;
    roomGroup.patchValue({
      code_type: selectedRoomType.code,
      room_type_id:selectedRoomType.id,
      room_type_name:selectedRoomType.name,



    });

}
async saveRooms() {
  var rooms=this.formRooms.get("RoomArray").value
  console.log("Responseupdatedrooms:", rooms)

  if (this.formRooms.get("RoomArray").status==='VALID'){

    let objAddRoom = Object.assign({ contrat_id: this.IdContrat }, { rooms: rooms })

  this.ContratService.CreateRoom(objAddRoom).subscribe(async (Response: any) => {
    await this.setAllotementFormData()

 
    this.cd.detectChanges()
    this.stepper.selectedIndex=3


    console.log("Responseupdated:", Response)
  
  
    
  })
}
else{

  this.showWarningAlert("All fields are required")
}
}
getRoom_roomTypeByContrat(){
  this.ContratService.getRoomsOfContrat(this.IdContrat).subscribe(respRooms => {
    console.log("respRooms for contract.length: ", respRooms)    
    this.filterRoomChild$ = of([
      { room_id: 0, room_name: "All Rooms" },
      ...respRooms.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.room_id === item.room_id
        ))
      )
    ]);

    this.filterRoomTypeContratForChild$ = of([
      { room_type_id: 0, room_type_name: "All Room Types" },
      ...respRooms.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.room_type_id === item.room_type_id
        ))
      )
    ]);
  })
}
get RoomArray() {
  return this.formRooms.get("RoomArray") as FormArray;
}
//*******************END ROOM INTERFACE**************************


//*******************START BOARD INTERFACE***********************
initFormBoard(){
  this.formBoarding = new FormGroup({
    BoardArray: this.formBuilder.array([this.createItem()]),

  });

  }
Addnewrow() {
   
    this.BoardArray.push(this.createItem());
  }
createItem(): FormGroup {

    return this.formBuilder.group({
      id:new FormControl( null),
      board_id: new FormControl( ''),
      board_name: new FormControl( ''),

      definition: new FormControl( ''),
      start_at: new FormControl(''),
      end_at: new FormControl( ''),
      period_id: new FormControl( ''),
      period_name:new FormControl('')
    });
  } 
onChangeDestinationboardPeriod(selected, index) {
   var selectedboardPeriod 
   this.filterBoardingPeriod$.pipe(
     map(arr => arr.find(e => e.name === selected))
   ).subscribe(selectebd=> {
    selectedboardPeriod = selectebd;
   }); 
    const boardArray = this.formBoarding.get('BoardArray') as FormArray;
    if (index >= 0 && index < boardArray.length) {
      const boardGroup = boardArray.at(index) as FormGroup;
      boardGroup.patchValue({
        start_at: selectedboardPeriod.start_at,
        end_at: selectedboardPeriod.end_at,
        period_id:selectedboardPeriod.id,
        period_name:selectedboardPeriod.name
      })
    }
  this.formBoarding.updateValueAndValidity()
  }
onChangeDestinationboard(selected, index) {
    console.log("index in form array: ", index);

 
    const selectedBoard = this.Arrangement.find(e => e.name === selected);
    console.log("onChangeDestinationboardselectedBoard: ", selectedBoard);
  
   
  
      const boardArray = this.formBoarding.get('BoardArray') as FormArray;
      const boardGroup = boardArray.at(index) as FormGroup;
   
     
      boardGroup.patchValue({
        definition: selectedBoard.definition,
        board_id:selectedBoard.id,
        board_name:selectedBoard.name
  
  
  
      });
  
      this.formBoarding.updateValueAndValidity()
  }
saveBoarding() {
 
   if(this.formBoarding.get("BoardArray").status=='VALID'){
    let formValue = this.formBoarding.get("BoardArray").value

   

    let objBording = Object.assign({
      contrat_id: this.IdContrat
    },
      { boardings: formValue, })
    

    this.ContratService.CreateBoardingContrat(objBording).subscribe(respcreateboardingcontrat => {
      console.log("respcreateboardingcontrat: ", respcreateboardingcontrat)
  
      this.stepper.selectedIndex=4    
      this.cd.detectChanges()


      this.filterBoardingChild$ = of([
        { board_id: 0, board_name: "All Boards" },
        ...respcreateboardingcontrat.boardcontrat.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.board_id === item.board_id
        ))
      )
    ]);
    this.getRoom_roomTypeByContrat()

    })

   }
   else {

    this.showWarningAlert('All Fields are required')
   }
   

  }
getArrangementt() {
    this.ContratService.getArrangement().subscribe(response => {
        this.Arrangement = response.items

        this.filterBoarding$ = of([
          ...response.items
      ]);
     
      
    })
  }
Removeitem(index: any) {
    this.items = this.formBoarding.get("BoardArray") as FormArray;
    this.items.removeAt(index)
    this.ArrayBoarding.splice(index, -1);
  }
get BoardArray() {
    return this.formBoarding.get("BoardArray") as FormArray;
  }
//*******************END BOARD INTERFACE**************************

/*******************START Allotement INTERFACE******************/
initFormAllotement(){
  this.formAllotement = this.fb.group({
  
    allotArray: new FormArray([]),
 
  })


}
get allotArray() {
  return this.formAllotement.get("allotArray") as FormArray;
}
async setAllotementFormData(){
  this.ContratService.getAllotementOfContrat(this.IdContrat).subscribe(respAllotement => {
    console.log("respAllotement: ", respAllotement)
    const allotement = respAllotement

    this.cd.detectChanges()
    this.allotArray.clear();

    allotement.forEach(element => {
      this.allotArray.push(this.fb.group({
        id:[element.id],
          number:[element.number],
          room_id: [element.room_id],
          room_name:[element.room_name],
          period_name:[element.period_name],
          period_id:[element.period_id],
          bookedNumber:[element.number || 0],

          
      }));
  
      
    });

  })
}
saveAllotementNumber(event,k){


  if(!isNaN(parseInt(event.target.value)))
    
    {



console.log(parseInt(event.target.value)+'  k=  '+k)
const alltGroup = this.allotArray.at(k) as FormGroup;

alltGroup.patchValue({
  number: parseInt(event.target.value)
});
}
}
saveUpdateAllotement() {
if(this.allotArray.status=='VALID'){
  let request={
    "allotements":this.allotArray.value,
     "contrat_id":this.IdContrat
  }

  
  this.ContratService.CreateAllotement( request).subscribe(respUpdateContrat => {
    console.log("respUpdateContrat: ", respUpdateContrat)
    this.getBoardingPrices();
    this.getRoomPrices();
    this.getRoomTypePrices();
  this.stepper.selectedIndex=5
     })
}
else {

  this.showWarningAlert('You need to add allotement to all the rooms')
}


}
/*******************END Allotement INTERFACE***********************/

/*******************START CHILD PRICE INTERFACE**********************/
initFormChildPrice(){
 
  this.formChild = new FormGroup({
      child_prices: this.fb.array([this.createItemChild()]),
     });
  
 }
onChangeRoomTypeChildPrice(selected: string, index: number) {
  var selectedRoomTypeForChildPrices


  this.filterRoomTypeContratForChild$.pipe(
    map(boardArray => boardArray.find(e => e.room_type_name === selected))
  ).subscribe(selectedRoomTypeChild => {
    selectedRoomTypeForChildPrices = selectedRoomTypeChild;
  });  
  console.log("selectedRoomTypeForChildPrices: ", selectedRoomTypeForChildPrices)









    const childPricesArray = this.formChild.get('child_prices') as FormArray;
    console.log("childPricesArray before: ", childPricesArray)


      const roomControl = childPricesArray.at(index).get('type_id');
   
        roomControl.setValue(selectedRoomTypeForChildPrices.room_type_id);
        this.cd.detectChanges()
        console.log("childPricesArray after: ", childPricesArray)

  
}
onChangeRoomNameForChildPrices(selected: string, index: number) {


  var selectedroomForChildPrices
  this.filterRoomChild$.pipe(
    map(roomArray => roomArray.find(e => e.room_name === selected))
  ).subscribe(selectedRoomChild => {
    selectedroomForChildPrices = selectedRoomChild;
  });  
  console.log(" selectedroomForChildPrices ", selectedroomForChildPrices.room_id)


  const childPricesArray = this.formChild.get('child_prices') as FormArray;

  
  console.log("childPricesArray before: ", childPricesArray)


      const room_id = childPricesArray.at(index).get('room_id');
      const room_name = childPricesArray.at(index).get('room_name');

      room_id.setValue(selectedroomForChildPrices.room_id);
      room_name.setValue(selectedroomForChildPrices.room_name);

    this.cd.detectChanges()
    console.log("childPricesArray after: ", childPricesArray)

    
  
}
onChangeDestinationboardForChildPrices(selected:string, index:number) {

  console.log("selected:",selected)
  var selectedboardchild
  this.filterBoardingChild$.pipe(
    map(boardArray => boardArray.find(e => e.board_name === selected))
  ).subscribe(selectedBoardChild => {
    selectedboardchild = selectedBoardChild;
  });   
  console.log("selectedboardchild: ", selectedboardchild.board_id)



 
  const childPricesArray = this.formChild.get('child_prices') as FormArray;
  console.log("childPricesArray before: ", childPricesArray)


    const roomControl = childPricesArray.at(index).get('board_id');
      roomControl.setValue(selectedboardchild.board_id);
      this.cd.detectChanges();
    
  


      console.log("childPricesArray after: ", childPricesArray)




}
onChangePeriodChildPrice(selected,index) {
  console.log("showselected: ", selected)

 var selectedPeriodChildPrice
 selectedPeriodChildPrice = this.Periodlist.find(e => e.name === selected)
 console.log("showSelectPeriodChildPriceID: ", selectedPeriodChildPrice)
 const childPricesArray = this.formChild.get('child_prices') as FormArray;


 const roomControl = childPricesArray.at(index).get('period_id');
const idP=selectedPeriodChildPrice.id
console.log("idP: ", idP)

   roomControl.setValue(idP);
   this.cd.detectChanges();
}
RemoveitemsForChild(index: any) {

  this.items = this.formChild.get("child_prices") as FormArray;
  const formGroupAtIndex = this.items.at(index) as FormGroup;
  const idValue = formGroupAtIndex.get('id')?.value;
  this.items.removeAt(index)
  this.cd.detectChanges()



}
RemoveChilds(childGroup: AbstractControl,index: any) {
  const itemConcell = childGroup.get('childs') as FormArray;

  const item = itemConcell.at(index) as FormGroup;

 
  itemConcell.removeAt(index)
  
  this.cd.detectChanges()

    

  }
AddnewrowChildPrice(childGroup: AbstractControl) {
  this.itemConcell = childGroup.get('childs') as FormArray;
  this.itemConcell.push(  this.fb.group({
    child_number: new FormControl(''),
    age_from: new FormControl(''),
    age_to: new FormControl(''),
    price: new FormControl(''),
    operation: new FormControl(false),
  }));
  this.cd.markForCheck(); 

  this.cd.detectChanges()

}
getChildPriceControl(childGroup: AbstractControl): FormArray {

  return childGroup.get('childs') as FormArray;
}
createItemChild(): FormGroup {
    return this.fb.group({
      period_id: new FormControl(''),
      period_name: new FormControl(''),
      room_id: new FormControl(''),
      room_name: new FormControl(''),

      adults: new FormControl(''),
      type_id: new FormControl(''),
      board_id: new FormControl(0),
      board_name: new FormControl(''),
      type_name: new FormControl(''),
      childs: this.fb.array([
        this.fb.group({
          child_number: new FormControl(''),
          age_from: new FormControl(''),
          age_to: new FormControl(''),
          price: new FormControl(''),
          operation: new FormControl(false),
        })
      ])
    });
  }
get child_prices(): FormArray {
    return this.formChild.get('child_prices') as FormArray;
  }
AddnewrowChild() {
  this.items = this.formChild.get('child_prices') as FormArray;
  this.items.push(this.createItemChild());
  }
saveChild() {
    const formValue = this.child_prices.value;
    console.log("formValue:", formValue);

    if(this.child_prices.status=='VALID'){
 this.ContratService.CreateChildPrice({"child_prices":formValue,contrat_id:this.IdContrat}).subscribe(respCreateChildPrice => {
      this.stepper.selectedIndex = 6;
      this.cd.detectChanges()


    });

    }
    else {

      this.showWarningAlert('All fields are required')
    }


   
  }
/*******************END CHILD PRICE INTERFACE**********************/


/*******************START Cancellation methodes**********************/
openCancellationRoom(period: any,index,indexRoom,indexPeriod) {
  var dataType=this.FilterTableRoomPrices[index]

  dataType.price=parseFloat(this.tableauPrice[index])
 

  console.log("FilterTableRoomPrices",this.FilterTableRoomPrices)
  console.log("FilterTableRoomPrices index",index)

  this.dialog.open(CancellationComponent, {
    data: {data:dataType,type:"insert",category:"room",period:period},
    height: '60%',
    width: '60%'
  });
  this.DisabledRoomArray[indexPeriod][indexRoom] = true;

}
openCancellationRoomType(period: any,index,indexRoomType,indexPeriod) {
      var dataType=this.FilterTableRoomTypePrices[index]

     dataType.price=parseFloat(this.tableauPriceType[index])
    


  this.dialog.open(CancellationComponent, {
    data: {data:dataType,type:"insert",category:"roomType",period:period},
    height: '60%',
    width: '60%'
  });
  this.DisabledRoomTypeArray[indexPeriod][indexRoomType] = true;

}
openCancellationBoard(period: any,index,board_index,period_index) {

  var dataType=this.FilterTableBoardPrices[index]

  dataType.price=parseFloat(this.tableauPriceBoard[index])
  console.log("openCancellationBoard",this.FilterTableBoardPrices)
  console.log("openCancellationBoard index",index)
  this.dialog.open(CancellationComponent, {
    data: {data:this.FilterTableBoardPrices[index],type:"insert",category:"board",period:period},
    height: '60%',
    width: '60%'
  });
  this.DisabledBoardArray[period_index][index] = true;

}
/*******************END Cancellation methodes**********************/


/*******************START BASE Price INTERFACE**********************/
get basePricePerPeriod() {
  return <FormArray>this.formBaseRoom.get('basePricePerPeriod');
}
initBasePriceForm(){
  this.formBaseRoom = this.fb.group({
    basePricePerPeriod: this.fb.array([])})
    var base=this.formBaseRoom.get('basePricePerPeriod') as FormArray;
    console.log("this.Periodes1: ",this.Periodes)

    this.Periodes.forEach(() => {



      base.push(this.fb.group({
        id: [null],
        room_id: [''],
        room_name: [''],
    
        room_type_id: [''],
        room_type_name: [''],
    
        board_id: [''],
        board_name: [''],
        operation:[false],
        price: [''],
        extb_price: [''],
        par_pax: [''],
        par_pax_name: [''],

      }));
    });
  

 


  }
onChangeDestinationroomPrices(selected,index) {
    


    var selectedroomForBasePrices
    this.filterRoomChild$.pipe(
      map(roomArray => roomArray.find(e => e.room_name === selected))
    ).subscribe(selectedRoom => {
      selectedroomForBasePrices = selectedRoom;
    });  
    console.log(" selectedroomForChildPrices ", selectedroomForBasePrices)


    const base = this.formBaseRoom.get('basePricePerPeriod') as FormArray;


    const room_id = base.at(index).get('room_id');
    
      const room_name = base.at(index).get('room_name') ;

  

        room_id.setValue(selectedroomForBasePrices.room_id);
        room_name.setValue(selectedroomForBasePrices.room_name);

          this.cd.detectChanges();









  }
onChangeContratBoardForPrices(selected,index) {

var selectedroomForBasePrices
this.filterBoardingChild$.pipe(
  map(boardArray => boardArray.find(e => e.board_name === selected))
).subscribe(selectedBoard => {
  selectedroomForBasePrices = selectedBoard;
});   
const base = this.formBaseRoom.get('basePricePerPeriod') as FormArray;


const board_id = base.at(index).get('board_id');

  const board_name = base.at(index).get('board_name') ;




board_id.setValue(selectedroomForBasePrices.board_id);
board_name.setValue(selectedroomForBasePrices.board_name);

   
this.cd.detectChanges();



  



  }
onChangeContratRoomTypeForPrice(selected,index) {
    var selectedroomForBasePrices
  
  
  this.filterRoomTypeContratForChild$.pipe(
  map(boardArray => boardArray.find(e => e.room_type_name === selected))
  ).subscribe(selectedRoomType => {
  selectedroomForBasePrices = selectedRoomType;
  });  
  console.log("selectedRoomTypeForChildPrices: ", selectedroomForBasePrices)
  
  
  
  
  const base = this.formBaseRoom.get('basePricePerPeriod') as FormArray;
  
  
  const room_type_id = base.at(index).get('room_type_id');
  
  const room_type_name = base.at(index).get('room_type_name') ;
  
  
  
  
  
  
  room_type_id.setValue(selectedroomForBasePrices.room_type_id);
  room_type_name.setValue(selectedroomForBasePrices.room_type_name);
  
   
  this.cd.detectChanges();
  }
extb(extbPrice,index) {
  const base = this.formBaseRoom.get('basePricePerPeriod') as FormArray;


  const extb_price = base.at(index).get('extb_price');
extb_price.setValue(extbPrice.value);
 }
price(Price,index) {
  const base = this.formBaseRoom.get('basePricePerPeriod') as FormArray;


  const price = base.at(index).get('price');

price.setValue(Price.value);
 }
showSelect(selected,index) {
  console.log("showSelect Type",selected)
  var boolPar_pax="Per Pax"
  if(selected==false){
    boolPar_pax="Per Room"
  }
      const base = this.formBaseRoom.get('basePricePerPeriod') as FormArray;
  
      
      const par_pax_name = base.at(index).get('par_pax_name');

        const par_pax = base.at(index).get('par_pax');
     
        par_pax.setValue(selected);
        par_pax_name.setValue(boolPar_pax);

   
  
  
    }
saveBasePrice(idPeriode,index) {
  console.log("saveBasePrice", this.formBaseRoom.value)


  const base = this.formBaseRoom.get('basePricePerPeriod') as FormArray;
  const BasePrice = base.at(index).value;
  console.log(" base.at(index)",  base.at(index))

  console.log("BasePrice", BasePrice)

  if(base.at(index).status=='VALID'){
  console.log("this.formBaseRoom.value", {period_id:idPeriode,BasePrice:BasePrice})
  this.Periodes[index].pressedBasePrice = true;
  this.ContratService.postBasePrice({period_id:idPeriode,BasePrice:BasePrice}).subscribe(responseBaseRoomprice => {
 
    
 
  })
  

}
else {

  this.showWarningAlert('All fields are required')
}

}
/*******************END BASE Price INTERFACE**********************/


/*******************START ALL Price INTERFACE**********************/

goToNextTab(): void {
  this.selectedTabIndex++;

}
getBoardingPrices(){

  this.ContratService.getBoardPricesOfContrat(this.IdContrat).subscribe(respBoardPrices => {
    this.BoardForBasePrice = respBoardPrices

    this.FilterTableBoardPrices = respBoardPrices.filter(Board => Board.period_id == this.Periodes[0].id);
   
   
   
    for (let i = 0; i < this.FilterTableBoardPrices.length; i++) {
      let objBoardtype = Object.assign({ id: this.FilterTableBoardPrices[i].id }, { arrangement_id: this.FilterTableBoardPrices[i].arrangement_id },
        { Board: this.FilterTableBoardPrices[i].boarding_name }, { indexboard: i }, { Price: this.FilterTableBoardPrices[i].price, }, { EXTBPrice: this.FilterTableBoardPrices[i].extb_price }, { TypeCalculat: this.FilterTableBoardPrices[i].par_pax })
      this.RowsBoard.push(objBoardtype)
    }
    this.dataSourceBoard = new MatTableDataSource<any>(this.RowsBoard);
    this.Periodes.forEach((_,index)=>{
   
      this.DisabledBoardArray[index] = new Array(this.RowsBoard.length).fill(true);
        
         
    
        })
  })
  
  }
getRoomPrices(){
    this.ContratService.getRoomPricesOfContrat(this.IdContrat).subscribe(respRoomPrices => {
      this.RoomForBasePrices = respRoomPrices
      this.FilterTableRoomPrices = respRoomPrices.filter(Room => Room.period_id == this.Periodes[0].id);

      for (let i = 0; i < this.FilterTableRoomPrices.length; i++) {
        let objRooms = Object.assign({ id: this.FilterTableRoomPrices[i].id }, { room_id: this.FilterTableRoomPrices[i].room_id }, { room_name: this.FilterTableRoomPrices[i].room_name }, { indexroom: i }
          , { Price: this.FilterTableRoomPrices[i].price }, { EXTBPrice: this.FilterTableRoomPrices[i].extb_price }, { TypeCalculat: this.FilterTableRoomPrices[i].par_pax })
        this.RowsRoomNameP.push(objRooms)
      }
      this.dataSourceRooms = new MatTableDataSource<any>(this.RowsRoomNameP);
      this.Periodes.forEach((_,index)=>{
     
        
        this.DisabledRoomArray[index] = new Array(this.RowsRoomNameP.length).fill(true);
       
  
      })
      
      this.cd.detectChanges()
    })
  }
getRoomTypePrices(){
   

    this.ContratService.getRoomTypePricesOfContrat(this.IdContrat).subscribe(respRoomTypePrices => {
      this.RoomTypeForBasePrices = respRoomTypePrices
      this.FilterTableRoomTypePrices = respRoomTypePrices.filter(RoomType => RoomType.period_id == this.Periodes[0].id);
      for (let i = 0; i < this.FilterTableRoomTypePrices.length; i++) {
        let objroomtype = Object.assign({ id: this.FilterTableRoomTypePrices[i].id }, { room_type_id: this.FilterTableRoomTypePrices[i].type_id },
          { typeName: this.FilterTableRoomTypePrices[i].room_type }, { indexroomtype: i }, { Price: this.FilterTableRoomTypePrices[i].price, }, { EXTBPrice: this.FilterTableRoomTypePrices[i].extb_price }, { TypeCalculat: this.FilterTableRoomTypePrices[i].par_pax })
        this.RowsRoomType.push(objroomtype)
      }
      this.dataSourceRoomstype = new MatTableDataSource<any>(this.RowsRoomType);
      this.Periodes.forEach((_,index)=>{
     
        
            this.DisabledRoomTypeArray[index] = new Array(this.RowsRoomType.length).fill(true);
           
      
          })



    })
  }
onTabChange(event: any): void {
    const selectedPeriodId = this.Periodes[event].id;
    this.onPeriodChange(selectedPeriodId);
  }
onPeriodChange(selectedPeriodId: number): void {
    // Filter the data based on the selected period
    this.FilterTableBoardPrices = this.BoardForBasePrice.filter(Board => Board.period_id === selectedPeriodId);
    
    // Update RowsBoard
    this.RowsBoard = this.FilterTableBoardPrices.map((item, index) => ({
      id: item.id,
      arrangement_id: item.arrangement_id,
      Board: item.boarding_name,
      indexboard: index,
      Price: item.price,
      EXTBPrice: item.extb_price,
      TypeCalculat: item.par_pax
    }));
  
    // Update dataSourceBoard
    this.dataSourceBoard = new MatTableDataSource<any>(this.RowsBoard);
  }
savePrices(idPeriode,index) {



 

  

    let TableauRoomPrices = [];
    let TableauRoomTypePrices = [];
    let TableauBoardPrices = [];

    for (let i = 0; i < this.RowsRoomNameP.length; i++) {
      let roomprice = {
        "room_id": this.RowsRoomNameP[i].room_id,
        "period_id": idPeriode,
        "price": parseInt(this.tableauPrice[i]),
        "par_pax": this.tableauType[i],
        "extra_bed_price": parseInt(this.tableauEXTBPrice[i])
      };
      TableauRoomPrices.push(roomprice);
    }

    for (let i = 0; i < this.tabidtyperoomsForPrices.length; i++) {
      let BaseTypeRoom = {
        "type_id": this.tabidtyperoomsForPrices[i],
        "period_id": idPeriode,
        "price": parseFloat(this.tableauPriceType[i]),
        "par_pax": this.tableauTypeRoom[i],
        "extra_bed_price": parseFloat(this.tableauEXTBPriceType[i])
      };
      TableauRoomTypePrices.push(BaseTypeRoom);
    }

    for (let i = 0; i < this.RowsBoard.length; i++) {

      let PriceBoard = {
        "arrangement_id": parseInt(this.RowsBoard[i].arrangement_id),
        "period_id": idPeriode,
        "price": parseFloat(this.tableauPriceBoard[i]),
        "par_pax": this.tableauTypeBoard[i],
        "extra_bed_price": parseFloat(this.tableauEXTBPriceBoard[i])
      };
      TableauBoardPrices.push(PriceBoard);
    }

    this.request = {
      "room_price": TableauRoomPrices,
      "room_type_price": TableauRoomTypePrices,
      "board_price": TableauBoardPrices
    };

    console.log("requestRoomPricesAll", this.request)

    try{
      this.ContratService.PostRoomPricesAll(this.request).subscribe(responseRoomPricesAll => {
        this.Periodes[index].pressed = true;
        this.TableauRoomPrices = [];
        this.TableauRoomTypePrices = [];
        this.TableauBoardPrices = [];
        this.tableauEXTBPriceBoard= [];
        this.tableauTypeBoard= [];
        this.tableauPriceBoard= [];
        this.goToNextTab();

    this.cd.detectChanges();
        console.log("responseRoomPricesAll", responseRoomPricesAll)
      }
    ,
    error=>{

      this.showWarningAlert(error.error.message)

      this.Periodes[index].pressed = false;
    this.cd.detectChanges();    }
    )

    }
    catch(error){
      this.Periodes[index].pressed = false;
      this.cd.detectChanges();
    }
    

  }
savecalculate() {


      this.ContratService.getCalculatAll(this.IdContrat).subscribe(respAllCalcut => {

        console.log("respAllCalcut"+JSON.stringify(respAllCalcut))
        let obj
        for (let i = 0; i < respAllCalcut.periods.length; i++) {
          for (let j = 0; j < respAllCalcut.periods[i].rooms.length; j++) {
            obj = Object.assign({ code: respAllCalcut.periods[i].code, },
              { name: respAllCalcut.periods[i].name, },
              { start_at: respAllCalcut.periods[i].start_at },
              { end_at: respAllCalcut.periods[i].end_at }
              , { room_name: respAllCalcut.periods[i].rooms[j].room_name },
              { type_name: respAllCalcut.periods[i].rooms[j].type_name },
              { board_name: respAllCalcut.periods[i].rooms[j].board_name },
              { price: respAllCalcut.periods[i].rooms[j].price },
              { price_with_extb: respAllCalcut.periods[i].rooms[j].price_with_extb })

            this.periods.push(obj)
            console.log("respAllCalcut dataSourcePrices", JSON.stringify(this.periods) )
          }
        }

        this.dataSourcePrices = new MatTableDataSource<any>(this.periods)
      })
    
  }
showvalueinput(value, column, idroom, index,indexPeriod) {
   

    this.DisabledRoomArray[indexPeriod][index] = false;

    for (let j = 0; j < this.ArrayRoomNamePrices.length; j++) {
      this.Room = (value.target.value)
    }
    this.Price = parseInt(value.target.value)
    this.isDisabledMap[idroom] = true
    console.log("this.isDisabledMap"+this.isDisabledMap)

    this.EXTBPrice = parseInt(value.target.value)
    this.TypeCalculat = this.type.toLowerCase() == 'true';


    this.tabidroomsForPrices[index] = idroom
    if (column == "Price") {
      this.tableauPrice[index] = value.target.value
    }
    if (column == "EXTBPrice") {
      this.tableauEXTBPrice[index] = value.target.value
    }



  }
showvalueTypeRoom(value, colums, idroomtype, index,indexPeriod) {

    this.DisabledRoomTypeArray[indexPeriod][index] = false;
        console.log("this.DisabledRoomTypeArray"+ this.DisabledRoomTypeArray)
        console.log("this.indexPeriod"+ indexPeriod)
        console.log("this.index"+ index)


    this.Type = (value.target.value)
    this.PriceType = parseInt(value.target.value)
    this.EXTBPriceType = parseInt(value.target.value)
    this.TypeCalculatType = this.roomtype.toLowerCase() == 'true';

    this.tabidtyperoomsForPrices[index] = idroomtype

    if (colums == "Price") {
      this.tableauPriceType[index] = this.PriceType
    }
    if (colums == "EXTBPrice") {
      this.tableauEXTBPriceType[index] = this.EXTBPriceType
    }

   
  }
showvalueBoard(value, column, idboard, index,indexPeriod) {
    this.DisabledBoardArray[indexPeriod][index] = false;
    this.Boarding = (value.target.value)
    this.PriceBoard = parseInt(value.target.value)
    this.EXTBPriceBoard = parseInt(value.target.value)
    this.TypeCalculatBoard = this.boardtype.toLowerCase() == 'true';

    this.tabidboardsForPrices[index] = idboard

    if (column == "Price") {
      this.tableauPriceBoard[index] = this.PriceBoard
    }
    if (column == "EXTBPrice") {
      this.tableauEXTBPriceBoard[index] = this.EXTBPriceBoard
    }

 
  }
showSelectForRoom(index,value) {
    let val = value == "Per Pax"
    this.tableauType[index] = val

  }
showSelectForTypeRoom(index, value) {
    let val = value == "Per Pax"
    this.tableauTypeRoom[index] = val

  }
showSelectForBoard(index, value) {
let val = value == "Per Pax"
    this.tableauTypeBoard[index] = val

  }
showSelectacces() {
    this.selectvalueaceess = this.accessL
  }
/*******************END ALL Price INTERFACE**********************/
}