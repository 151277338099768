import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};
@Injectable({
  providedIn: 'root'
})
export class MiniErpService {
  urlReservations:string
  urlPostFacture:string
  urlPutFacture:string
  constructor(private http: HttpClient) {
    this.urlReservations=environment.urlReservations
    this.urlPostFacture=environment.urlPostFacture
    this.urlPutFacture=environment.urlPutFacture

   }
  postAllReserv(urlReservations):Observable<any>{
   
    return this.http.post<any>(this.urlReservations, urlReservations, httpOptions)  
   }
   
   PostFacture(facture){
    return this.http.post<any>(this.urlPostFacture, facture)  

   }
   updateFacture(facture:any,BookingId:any){
    console.log("this.urlPutFacture:",this.urlPutFacture+"/"+facture.BookingId,facture)
    return this.http.put<any>(this.urlPutFacture+"/"+facture.BookingId,facture);

  }
  postAllReservPagination(page:any,pageSize){
    console.log("urlReservations+ '?ItemsPerPage=&page='page: ",this.urlReservations+ '?ItemsPerPage=5&Page='+page +'&ItemsPerPage='+pageSize)

    return this.http.get<any>(this.urlReservations+ '?ItemsPerPage='+pageSize+'&Page='+page );
  }


}