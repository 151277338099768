import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { env } from 'process';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  urlGet_hotel: string;
  urlGet_Contart: string;
  urlGet_ContartAutoComplete: string;
  urlGet_RoomType: string;
  url_post_contrat: string
  urlGet_Period: string;
  urlGet_Arrangement: string;
  urlGet_post_contrat: string;
  urlGet_put_contrat: string;
  urlGet_delete_contrat: string;
  urlGet_delete_period:string;
  urlGet_delete_room:string;
  urlGet_delete_board:string;
  urlGet_delete_child:string;
  urlGet_delete_cancellation:string;

  urlGet_Room: string;   /*ines@dotcom.tn Room name  */
  url_post_room_contrat: string;/*ines@dotcom.tn api post   */
  url_post_board_contrat: string
  url_post_periode_contrat: string
  urlPostBaseRoomPrice: string
  url_Get_Periode_Of_Contrat: string
  url_Get_Boards_Of_Contrat: string
  url_Get_Room_Of_Contart: string
  url_Get_Allotement_Of_Contrat: string
  url_Get_BasePrices_Of_Contrat: string
  url_post_allotement_contrat: string;
  URLPost_RoomPrice: string;
  URLPut_RoomPrice: string;
  URLPost_TypePrice: string;
  URLPut_TypePrice: string;
  URLPost_Board: string;
  urlGet_Calculate_OfContrat: string
  urlGet_RoomPrice: string
  urlGet_RoomtypePrice: string
  urlGet_ArrangementPrice
  url_put_periode: string
  url_put_BasePrice: string
  url_post_BasePrice: string
  urlPostCancellationCondition: string
  urlPutCancellationCondition: string
  url_ActivateContract: string
  url_Get_Market

  url_Get_Cancellation_Conditions_Of_Contrat: string
  url_Get_Cancellations_Period_Type: string
  url_Get_Period: string;
  url_Get_Room: string;
  url_Get_RoomType: string;
  urlGet_board: string;
  url_Post_ChildPrice: string
  private id = new BehaviorSubject<any>({});
  currentData = this.id.asObservable();
  private period_id = new BehaviorSubject<any>({});
  currentDataperiod = this.period_id.asObservable();
  url_put_Room
  url_put_Board
  url_Get_Period_Contract: string
  url_put_periode_contrat
  url_put_room_contrat
  url_put_board_contrat
  url_put_allotement_contrat
  url_Get_ChildPrices_Of_Contrat
  url_Get_RoomPrices_Of_Contrat
  url_Get_RoomTypePrices_Of_Contrat
  url_Get_BoardPrices_Of_Contrat
  URLPut_Board
  url_Get_ChildPrice_Of_Contrat
  url_Put_ChildPrice
  url_put_periode_contrat_board
  url_Get_Boards_Of_Contrat_Extrat
  url_Put_BoardExtraOf_Contrat
  url_get_BoardExtratOfPeriod
  url_update_Prices_all

url_saveRoom_Prices_all
  constructor(private http: HttpClient) {
    this.url_Get_Market=environment.url_Get_Market
    this.url_ActivateContract = environment.url_ActivateContract
    this.url_Get_Room = environment.url_Get_Room
    this.url_Get_RoomType = environment.url_Get_RoomType
    this.urlGet_board = environment.urlGet_board
    this.urlGet_hotel = environment.urlGet_hotel
    this.urlGet_RoomType = environment.urlGet_RoomType
    this.urlGet_Contart = environment.urlGet_Contart
    this.urlGet_ContartAutoComplete = environment.urlGet_ContartAutoComplete
    this.url_post_contrat = environment.url_post_contrat
    this.urlGet_Room = environment.urlGet_Room
    this.url_post_room_contrat = environment.url_post_room_contrat
    this.urlGet_Arrangement = environment.url_get_arrangement
    this.url_post_board_contrat = environment.url_post_board_contrat
    this.url_post_periode_contrat = environment.url_post_periode_contrat
    this.urlPostBaseRoomPrice = environment.urlPostBaseRoomPrice
    this.url_Get_Periode_Of_Contrat = environment.url_Get_Periode_Of_Contrat
    this.url_Get_ChildPrice_Of_Contrat = environment.url_Get_ChildPrice_Of_Contrat
    this.url_Get_Boards_Of_Contrat = environment.url_Get_Boards_Of_Contrat
    this.url_Get_Room_Of_Contart = environment.url_Get_Room_Of_Contart
    this.url_Get_Allotement_Of_Contrat = environment.url_Get_Allotement_Of_Contrat
    this.url_Get_BasePrices_Of_Contrat = environment.url_Get_BasePrices_Of_Contrat
    this.urlGet_delete_contrat = environment.urlGet_delete_contrat
    this.urlGet_delete_period = environment.urlGet_delete_period
    this.urlGet_delete_room = environment.urlGet_delete_room
    this.urlGet_delete_board = environment.urlGet_delete_board
    this.urlGet_delete_child = environment.urlGet_delete_child
    this.urlGet_delete_cancellation= environment.urlGet_delete_cancellation
    
    
    
    this.url_post_allotement_contrat = environment.url_post_allotement_contrat
    this.URLPost_RoomPrice = environment.URLPost_RoomPrice
    this.URLPut_RoomPrice = environment.URLPut_RoomPrice
    this.URLPost_TypePrice = environment.URLPost_TypePrice
    this.URLPut_TypePrice = environment.URLPut_TypePrice
    this.URLPost_Board = environment.URLPost_Board
    this.urlGet_RoomPrice = environment.urlGet_RoomPrice
    this.urlGet_Calculate_OfContrat = environment.urlGet_Calculate_OfContrat
    this.urlGet_RoomtypePrice = environment.urlGet_RoomtypePrice
    this.urlGet_ArrangementPrice = environment.urlGet_ArrangementPrice
    this.urlGet_put_contrat = environment.urlGet_put_contrat
    this.url_put_periode = environment.url_put_periode
    this.url_put_Room = environment.url_put_Room
    this.url_put_Board = environment.url_put_Board
    this.url_put_BasePrice = environment.url_put_BasePrice
    this.url_post_BasePrice = environment.url_post_BasePrice
    
    this.urlPostCancellationCondition = environment.urlPostCancellationCondition
    this.url_Get_Cancellation_Conditions_Of_Contrat = environment.url_Get_Cancellation_Conditions_Of_Contrat
    this.url_Get_Cancellations_Period_Type = environment.url_Get_Cancellations_Period_Type

    
    this.url_Post_ChildPrice = environment.url_Post_ChildPrice
    this.url_Get_Period_Contract = environment.url_Get_Period_Contract
    this.url_put_periode_contrat = environment.url_put_periode_contrat
    this.url_put_room_contrat = environment.url_put_room_contrat
    this.url_put_board_contrat = environment.url_put_board_contrat
    this.url_put_allotement_contrat = environment.url_put_allotement_contrat
    this.urlPutCancellationCondition = environment.urlPutCancellationCondition
    this.url_Get_ChildPrices_Of_Contrat = environment.url_Get_ChildPrices_Of_Contrat
    this.url_Get_RoomPrices_Of_Contrat = environment.url_Get_RoomPrices_Of_Contrat
    this.url_Get_RoomTypePrices_Of_Contrat = environment.url_Get_RoomTypePrices_Of_Contrat
    this.url_Get_BoardPrices_Of_Contrat = environment.url_Get_BoardPrices_Of_Contrat
    this.URLPut_Board = environment.URLPut_Board
    this.url_Put_ChildPrice=environment.url_Put_ChildPrice
    this.url_put_periode_contrat_board=environment.url_put_periode_contrat_board
    this.url_Get_Boards_Of_Contrat_Extrat=environment.url_Get_Boards_Of_Contrat_Extrat
    this.url_Put_BoardExtraOf_Contrat=environment.url_Put_BoardExtraOf_Contrat
    this.url_get_BoardExtratOfPeriod=environment.url_get_BoardExtratOfPeriod
    this.url_saveRoom_Prices_all=environment.url_saveRoom_Prices_all
    this.url_update_Prices_all=environment.url_update_Prices_all

 
  }
  ActivateCONTRAT(contrat: any): Observable<any> {
    console.log("contrat///: ", contrat.id)
    console.log("this.url_ActivateContract", this.url_ActivateContract + "/" + contrat.id, contrat)
    return this.http.put<any>(this.url_ActivateContract + "/" + contrat.id, contrat);

  }


  PostRoomPricesAll(request: any): Observable<any> {
    console.log("this.url_saveRoom_Prices_all", this.url_saveRoom_Prices_all , request)
    return this.http.post<any>(this.url_saveRoom_Prices_all, request);
  }
  







  getPeriod(idcontrat) {
    console.log("this.url_Get_Period_Contract+idcontrat: ", this.url_Get_Period_Contract + idcontrat)
    return this.http.get<any>(this.url_Get_Period_Contract + idcontrat);
  }
  updatedata(id: any) {
    this.id.next(id)
  }
  updatedataperiod(period_id: any) {
    this.id.next(period_id)
  }
  CreateChildPrice(child) {
 
    console.log('this.url_Post_ChildPrice ', this.url_Post_ChildPrice, child)
    return this.http.post<any>(this.url_Post_ChildPrice, child);
  }
 
  //API Contrat
  getContrat() {
    return this.http.get<any>(this.urlGet_Contart, { params: { clientid: 1 } });
  }



  getContratPagination(page:any,pageSize:any){
    console.log("this.urlGet_Contart+ '?ItemsPerPage=&page='page: ",this.urlGet_Contart+ '?ItemsPerPage=5&Page='+page +'&ItemsPerPage='+pageSize)
    return this.http.get<any>(this.urlGet_Contart+ '?ItemsPerPage='+pageSize+'&Page='+page );

   }


  getContratAutoComplete() {
    return this.http.get<any>(this.urlGet_ContartAutoComplete);
  }

  getCancellationConditionOfContrat(idcontrat) {
    console.log("this.url_Get_Cancellation_Conditions_Of_Contrat + '/' + idcontrat: ", this.url_Get_Cancellation_Conditions_Of_Contrat + '/' + idcontrat)
    return this.http.get<any>(this.url_Get_Cancellation_Conditions_Of_Contrat + '/' + idcontrat);

  }
  getCancellationsByPeriodType(period_id,id_type,type) {
    console.log(this.url_Get_Cancellations_Period_Type+'?period_id='+period_id+'&id_type='+id_type+'&type='+type)

    return this.http.get<any>(this.url_Get_Cancellations_Period_Type+'?period_id='+period_id+'&id_type='+id_type+'&type='+type);

  }
  
  updatecontrat(contrat: any): Observable<any> {
    console.log("contrat///: ", contrat.id)

    console.log("this.urlGet_put_contrat", this.urlGet_put_contrat + "/" + contrat.id, contrat)
    return this.http.put<any>(this.urlGet_put_contrat + "/" + contrat.id, contrat);
  }


  getBoardPeriod(idcontrat){
    console.log("this.url_put_periode_contrat_board ",this.url_put_periode_contrat_board + '/' + idcontrat)
    return this.http.get<any>(this.url_put_periode_contrat_board + '/' + idcontrat);

  }

  updateperiode(periode: any): Observable<any> {
    console.log("periode///: ", periode.id)

    console.log("this.url_put_periode", this.url_put_periode + "/" + periode.id, periode)
    return this.http.put<any>(this.url_put_periode + "/" + periode.id, periode);
  }
  updateRoom(room: any): Observable<any> {
    console.log("room///: ", room.id)

    console.log("this.url_put_Room", this.url_put_Room + "/" + room.id, room)
    return this.http.put<any>(this.url_put_Room + "/" + room.id, room);
  }

  updateboard(board: any): Observable<any> {
    console.log("board///: ", board.id)

    console.log("this.url_put_Board", this.url_put_Board + "/" + board.id, board)
    return this.http.put<any>(this.url_put_Board + "/" + board.id, board);
  }


  updateBasePrice(basePrice: any): Observable<any> {

 
    return this.http.put<any>(this.url_put_BasePrice ,basePrice);
  }
  postBasePrice(basePrice: any): Observable<any> {

 
    return this.http.post<any>(this.url_post_BasePrice ,basePrice);
  }

  GetMarket(){
    console.log("this.url_Get_Market",this.url_Get_Market)
    return this.http.get<any>(this.url_Get_Market);

   }

  deletecontrat(id: any): Observable<any> {
    return this.http.delete<any>(this.urlGet_delete_contrat + "/" + id);
  }
  deletePeriod(id: any): Observable<any> {
    return this.http.delete<any>(this.urlGet_delete_period + "/" + id);
  }
  deleteRoom(id: any): Observable<any> {
    return this.http.delete<any>(this.urlGet_delete_room + "/" + id);
  }
  deleteBoard(id: any): Observable<any> {
    return this.http.delete<any>(this.urlGet_delete_board + "/" + id);
  }
  deleteChildPrice(id: any): Observable<any> {
    return this.http.delete<any>(this.urlGet_delete_child + "/" + id);
  }
  deleteCancellation(id: any): Observable<any> {
    return this.http.delete<any>(this.urlGet_delete_cancellation + "/" + id);
  }
  
  gethotel() {
    return this.http.get<any>(this.urlGet_hotel);
  }
  getRoomtype() {
    return this.http.get<any>(this.urlGet_RoomType);
  }
  getRoomName() {
    return this.http.get<any>(this.urlGet_Room);
  }
  getRoomNamePricce() {
    return this.http.get<any>(this.urlGet_Room);
  }
  getArrangement() {
    return this.http.get<any>(this.urlGet_Arrangement);
  }
  getArrangementPrice() {
    return this.http.get<any>(this.urlGet_Arrangement);
  }
  CreateContrat(contrat) {
    return this.http.post<any>(this.url_post_contrat, contrat);
  }
  getRoomtypePricce() {
    return this.http.get<any>(this.urlGet_RoomType);
  }
  CreateRoom(room) {
    return this.http.post<any>(this.url_post_room_contrat, room);
  }
  UpdateRoomContrat(room, idcontrat) {
    return this.http.put<any>(this.url_put_room_contrat + idcontrat, room);
  }
  CreateBoardingContrat(board) {
    return this.http.post<any>(this.url_post_board_contrat, board);

  }
  UpdateBoardingContrat(board, idContrat) {
    return this.http.put<any>(this.url_put_board_contrat + idContrat, board);

  }



  UpdateChildPrice(child,idContrat) {
    console.log('this.url_Put_ChildPrice, + idContrat, child', this.url_Put_ChildPrice + idContrat, child)
    return this.http.put<any>(this.url_Put_ChildPrice  + idContrat, child);
  }





  CreatePeriodeContrat(periode) {
    return this.http.post<any>(this.url_post_periode_contrat, periode);

  }
  UpdatePeriodeContrat(Tabperiode, idContrat) {
    return this.http.put<any>(this.url_put_periode_contrat + idContrat, Tabperiode);

  }

  


  CreateAllotement(allotements) {
    return this.http.post<any>(this.url_post_allotement_contrat, allotements);

  }

  UpdateAllotement(allotements, idContrat) {
    return this.http.put<any>(this.url_put_allotement_contrat + idContrat, allotements);

  }

  PostBaseRoomPrice(BaseRoomPrices) {
    return this.http.post<any>(this.urlPostBaseRoomPrice, BaseRoomPrices);
  }

  PostTypePrice(BaseTypeRoom) {
    return this.http.post<any>(this.URLPost_TypePrice, BaseTypeRoom);

  }
  PostBoardPrice(BoardPrice) {
    return this.http.post<any>(this.URLPost_Board, BoardPrice);
  }
  PostRoomPice(RoomPrice) {
    return this.http.post<any>(this.URLPost_RoomPrice, RoomPrice);

  }
  PutAllPrices(request){
    return this.http.put<any>(this.url_update_Prices_all , request);

  }
  /*ines@dotcom.tn Room name  */
  getPeriodesOfContrat(idcontrat) {
    console.log("this.url_Get_Periode_Of_Contrat + '/' + idcontrat: ", this.url_Get_Periode_Of_Contrat + '/' + idcontrat)
    return this.http.get<any>(this.url_Get_Periode_Of_Contrat + '/' + idcontrat);

  }
  geChildPriceOfContrat(idcontrat) {
    console.log("this.url_Get_ChildPrice_Of_Contrat + '/' + idcontrat: ", this.url_Get_ChildPrice_Of_Contrat + '/' + idcontrat)
    return this.http.get<any>(this.url_Get_ChildPrice_Of_Contrat + '/' + idcontrat);

  }
  getBoardsOfContrat(idcontrat) {
    console.log("this.url_Get_Boards_Of_Contrat + '/' + idcontrat: ", this.url_Get_Boards_Of_Contrat + '/' + idcontrat)
    return this.http.get<any>(this.url_Get_Boards_Of_Contrat + '/' + idcontrat);
  }




  getBoardsOfContratExtrat(idcontrat) {
    console.log("this.url_Get_Boards_Of_Contrat_Extrat + '/' + idcontrat: ", this.url_Get_Boards_Of_Contrat_Extrat + '/' + idcontrat)
    return this.http.get<any>(this.url_Get_Boards_Of_Contrat_Extrat + '/' + idcontrat);
  }







  getRoomsOfContrat(idcontrat) {
    console.log("this.url_Get_Room_Of_Contart + '/' + idcontrat: ", this.url_Get_Room_Of_Contart + '/' + idcontrat)
    return this.http.get<any>(this.url_Get_Room_Of_Contart + '/' + idcontrat);
  }
  getAllotementOfContrat(idcontrat) {
    console.log("this.url_Get_Allotement_Of_Contrat + '/' + idcontrat: ", this.url_Get_Allotement_Of_Contrat + '/' + idcontrat)
    return this.http.get<any>(this.url_Get_Allotement_Of_Contrat + '/' + idcontrat);
  }
  getRoomPricesOfContrat(idcontrat) {
    console.log("this.url_Get_RoomPrices_Of_Contrat + '/' + idcontrat: ", this.url_Get_RoomPrices_Of_Contrat + '/' + idcontrat)
    return this.http.get<any>(this.url_Get_RoomPrices_Of_Contrat + '/' + idcontrat);
  }

  getBoardPricesOfContrat(idcontrat) {
    console.log("this.url_Get_BoardPrices_Of_Contrat + '/' + idcontrat: ", this.url_Get_BoardPrices_Of_Contrat + '/' + idcontrat)
    return this.http.get<any>(this.url_Get_BoardPrices_Of_Contrat + '/' + idcontrat);
  }
  getRoomTypePricesOfContrat(idcontrat) {
    console.log("this.url_Get_RoomTypePrices_Of_Contrat + '/' + idcontrat: ", this.url_Get_RoomTypePrices_Of_Contrat + '/' + idcontrat)
    return this.http.get<any>(this.url_Get_RoomTypePrices_Of_Contrat + '/' + idcontrat);
  }
  getBasePricesOfContrat(idcontrat) {
    console.log("this.url_Get_BasePrices_Of_Contrat + '/' + idcontrat: ", this.url_Get_BasePrices_Of_Contrat + '/' + idcontrat)
    return this.http.get<any>(this.url_Get_BasePrices_Of_Contrat + '/' + idcontrat);
  }
  getChildPricesOfContrat(idcontrat) {
    console.log("this.url_Get_ChildPrices_Of_Contrat + '/' + idcontrat: ", this.url_Get_BasePrices_Of_Contrat + '/' + idcontrat)
    return this.http.get<any>(this.url_Get_ChildPrices_Of_Contrat + idcontrat);
  }
  getCalculatAll(idcontrat) {
    console.log("this.urlGet_Calculate_OfContrat + '/' + idcontrat: ", this.urlGet_Calculate_OfContrat + '/' + idcontrat)
    return this.http.get<any>(this.urlGet_Calculate_OfContrat + '/' + idcontrat);
  }
  PostcancellationCondition(cancellation) {
    return this.http.post<any>(this.urlPostCancellationCondition, cancellation);

  }
  PutcancellationCondition(cancellation) {
    console.log("this.urlPutCancellationCondition ", this.urlPutCancellationCondition )
    return this.http.put<any>(this.urlPutCancellationCondition , cancellation);

  }
  getRoomNameForChild() {
    return this.http.get<any>(this.url_Get_Room);
  }
  getRoomTypeForChild() {
    return this.http.get<any>(this.url_Get_RoomType);

  }
  getArrangementForChild() {
    return this.http.get<any>(this.urlGet_board);
  }



  updateBoardExtraofcontrat(boardextra: any): Observable<any> {
    console.log("room///: ", boardextra)

    console.log("this.url_Put_BoardExtraOf_Contrat", this.url_Put_BoardExtraOf_Contrat , boardextra)
    return this.http.put<any>(this.url_Put_BoardExtraOf_Contrat , boardextra);
  }


  getboardofcontratPeriod(idcontrat,Code){
    console.log("this.url_get_BoardExtratOfPeriod", this.url_get_BoardExtratOfPeriod  + '/' + idcontrat+'?code='+Code)
    return this.http.get<any>(this.url_get_BoardExtratOfPeriod  + '/' + idcontrat+'?code='+Code);

    
  }
}

