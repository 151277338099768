<vex-page-layout *ngIf="list==true">
  <vex-secondary-toolbar current="Billing">
    <vex-breadcrumbs style="height: var(--toolbar-width);" [crumbs]="['Invoice Edition']" style="margin-top: 0px;"
      class="flex-auto"></vex-breadcrumbs>

  </vex-secondary-toolbar>
  <vex-page-layout-header class="pb-8 flex flex-col items-start justify-center padding"
    style="background-color:whitesmoke;">
    <div [class.container]="layoutCtrl.value === 'fullwidth'" [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
      class="w-full flex flex-col sm:flex-row justify-between">
      <div>
      </div>
    </div>
  </vex-page-layout-header>
  <vex-page-layout-content [class.container]="layoutCtrl.value === 'fullwidth'"
    [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6">
    <div class="card overflow-auto -mt-16">
      <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
        <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
          <span *ngIf="selection.isEmpty()"></span>
          <span *ngIf="selection.hasValue()">{{ selection.selected.length }}
            <span *ngIf="selection.selected.length > 1">s</span> selected</span>
        </h2>
        <div *ngIf="selection.hasValue()" class="mr-4 pr-4 border-r flex-none">
          <button (click)="deleteReservation(selection.selected)" color="primary" mat-icon-button matTooltip="Confirmer"
            type="button">
            <mat-icon svgIcon="mat:done"></mat-icon>
          </button>

          <button color="primary" mat-icon-button matTooltip="Annuler" type="button">
            <mat-icon svgIcon="mat:clear"></mat-icon>
          </button>
        </div>
        <div
          class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
          <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
          <input [formControl]="searchCtrl" class="px-4 py-2 border-0 outline-none w-full bg-transparent"
            placeholder="Search..." type="search">
        </div>
        <span class="flex-1"></span>
        <button color="primary" [matMenuTriggerFor]="columnFilterMenu" class="ml-4 flex-none" mat-icon-button
          matTooltip="Filter Columns" type="button">
          <mat-icon svgIcon="mat:filter_list"></mat-icon>
        </button>
      </div>
      <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Checkbox Column -->
        <ng-container matColumnDef="checkbox">
          <th *matHeaderCellDef mat-header-cell>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" color="primary">
            </mat-checkbox>
          </th>
          <td *matCellDef="let row" class="w-4" mat-cell>
            <mat-checkbox (change)="$event ? selection.toggle(row) : null" (click)="$event.stopPropagation()"
              [checked]="selection.isSelected(row)" color="primary">
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- Text Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
          </ng-container>
          <ng-container *ngIf="column.type === 'button'" [matColumnDef]="column.property">
            <ng-container *ngIf="column.property === 'confirm'">
              <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row" [ngClass]="column.cssClasses" class="w-10" mat-cell>
                <div class="flex items-center gap-1" (click)="$event.stopPropagation(); ConfirmReservation(row)"
                  (change)="$event ? ConfirmReservation(row) : null">
                  <button matTooltip="Facturer" color="primary" mat-icon-button type="button" (click)="facturer(row)">
                    <mat-icon style="color:darkblue ; " svgIcon="mat:note_add"></mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>
            <ng-container *ngIf="column.property === 'actions'">
              <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
              <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
                <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ customer: row }"
                  [matMenuTriggerFor]="actionsMenu" mat-icon-button type="button">
                  <mat-icon svgIcon="mat:more_horiz"></mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container *ngIf="column.property === 'BookingStatus'">
              <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row" mat-cell>
                <div (click)="$event.stopPropagation()" class="flex items-center gap-1">
                  <div *ngIf="row.BookingStatus == 'en attente'" [ngClass]="[labels[2].textClass, labels[2].bgClass]"
                    class="rounded px-2 py-1 font-medium text-xs flex-none">
                    {{ labels[2].text }}
                  </div>
                  <div *ngIf="row.BookingStatus == 'canceled'" [ngClass]="[labels[1].textClass, labels[1].bgClass]"
                    class="rounded px-2 py-1 font-medium text-xs flex-none">
                    {{ labels[1].text }}
                  </div>
                  <div *ngIf="row.BookingStatus == 'confirmed'" [ngClass]="[labels[0].textClass, labels[0].bgClass]"
                    class="rounded px-2 py-1 font-medium text-xs flex-none">
                    {{ labels[0].text }}
                  </div>
                </div>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>
        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: visibleColumns;" @fadeInUp
          class="hover:bg-hover trans-ease-out cursor-pointer" mat-row></tr>
      </table>
      <div *ngIf="dataSource.filteredData.length === 0" @scaleFadeIn
        class="flex-auto flex flex-col items-center justify-center">
        <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
        <h2 class="headline m-0 text-center">No billing matching your filters</h2>
      </div>
      <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" [length]="length" showFirstLastButtons
        class="sticky left-0"></mat-paginator>
    </div>
    <mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
      <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of columns"
        class="checkbox-item mat-menu-item">
        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">
          {{ column.label }}
        </mat-checkbox>
      </button>
    </mat-menu>
  </vex-page-layout-content>
</vex-page-layout>
<vex-page-layout *ngIf="facture==true">
  <vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
    <div [class.container]="layoutCtrl.value === 'boxed'" [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
      class="w-full flex flex-col sm:flex-row justify-between">
      <div>
        <h1 class="title mt-0 mb-1">Invoice Edition</h1>
        <vex-breadcrumbs [crumbs]="['Apps', 'Invoice Edition']"></vex-breadcrumbs>
      </div>
    </div>
  </vex-page-layout-header>
  <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
    [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6">
    <mat-horizontal-stepper #stepper="matHorizontalStepper" [linear]="true">
      <ng-template matStepperIcon="edit">
        <mat-icon svgIcon="mat:done_all"></mat-icon>
      </ng-template>
      <ng-template matStepperIcon="done">
        <mat-icon svgIcon="mat:done_all"></mat-icon>
      </ng-template>
      <mat-step>
        <ng-template matStepLabel>Create Invoice</ng-template>
        <div class="flexHead">
          <div>
            <p class="contactM">{{address}}</p>
            <!--  <p class="contactM">Av. des Martyrs Imm. Ribat Madina</p>
          <p class="contactM">Bloc A. 2 éme etage App: 207</p>
          <p class="contactM">3000 Sfax-Tunisie</p>
          <p class="contactM">(216) 98 777 431</p> -->
          </div>
          <!-- <img src="./../../../../assets/img/demo/logo90.png" style="width: 134px; height: 40px; margin-right: 10px" /> -->
          <h2 style="padding-left: 0px;color: #6366F1;font-size: 31px;">DOTCOM</h2>
        </div>
        <form #formAdd="ngForm" (ngSubmit)="onSubmitAdd(formAdd.value)" #ref>
          <article>
            <h1>Recipient</h1>
            <table class="meta"
              style="border-collapse: separate; border-spacing: 2px;background: #EEE; border-color: #BBB;">
              <tr
                style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                <th
                  style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                  <span contenteditable>Invoice # </span>
                </th>
                <td
                  style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                </td>
                <!-- <td>654654654</td> -->
              </tr>
              <tr
                style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                <th
                  style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                  <span contenteditable>Date</span>
                </th>
                <td
                  style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                  {{jstoday}}
                </td>
              </tr>
              <tr
                style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                <th
                  style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                  <span contenteditable> Price Reservation</span>
                </th>
                <td
                  style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                  {{totalPrice}}
                </td>
              </tr>
            </table>
            <table class="inventory"
              style="border-collapse: separate; border-spacing: 2px;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
              <thead>
                <tr
                  style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                  <th
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <span contenteditable>Désignation</span>
                  </th>
                  <th
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <span contenteditable> Check-In</span>
                  </th>
                  <th
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <span contenteditable>Check-Out</span>
                  </th>
                  <th
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <span contenteditable>Number Of Nights</span>
                  </th>
                  <th
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <span contenteditable>Number Of Rooms</span>
                  </th>
                  <th
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <span contenteditable>Number Of Adults</span>
                  </th>
                  <th
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <span contenteditable>Number Of Children</span>
                  </th>
                  <th
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <span contenteditable>Price</span>
                  </th>
                  <th
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <span contenteditable> TVA</span>
                  </th>
                  <th
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <span contenteditable>Price HT</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let booking of Bookings ; let i=index"
                  style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                  <td
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <a class="cut">-</a>
                    <span contenteditable>{{ booking.Hotel }}</span>
                  </td>
                  <td
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <span contenteditable>{{ booking.CheckInDate }}</span>
                  </td>
                  <td
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <span contenteditable>{{ booking.CheckOutDate }}</span>
                  </td>
                  <td
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    {{NumberOfNights}}
                  </td>
                  <td
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    {{NumberOfRooms}}
                  </td>
                  <td
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    {{NBchild}}
                  </td>
                  <td
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    {{NumberOfChildren}}
                  </td>

                  <td
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <span factureData-prefix> </span>
                    {{totalPrice}}
                  </td>

                  <td
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <mat-select name="taux_tva" [(ngModel)]="Percentagee" (ngModelChange)="showoperationSelected()">

                      <mat-option *ngFor="let Percent of PercentageValues" [value]="Percent.value"
                        (click)="createReservation(Row)">
                        {{Percent.viewValue}}</mat-option>
                    </mat-select>
                  </td>
                  <td (blur)="showValueTTC1($event)"
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    {{resultMHT}}
                  </td>
                </tr>
              </tbody>
            </table>
            <p style="
          clip: rect(0 0 0 0);
          border: none;
          margin: 0 0 1em;
          font: bold 100% sans-serif;
          letter-spacing: 0.5em;
          text-align: center;
          text-transform: uppercase;
        ">
              Suppléments additionnels
            </p>
            <table class="inventory"
              style="border-collapse: separate; border-spacing: 2px;background: #EEE; border-color: #BBB;">
              <thead>
                <tr
                  style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                  <th
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <span contenteditable>Désignation</span>
                  </th>

                  <th
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <span contenteditable>Price </span>
                  </th>
                  <th
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <span contenteditable> TVA</span>
                  </th>
                  <th
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <span contenteditable>Price HT</span>
                  </th>
                </tr>
              </thead>

              <tbody *ngFor="let supp of supps ; let i=index">

                <tr *ngFor="let booking of Bookings ; let i=index"
                  style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                  <td
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <a class="cut">-</a>
                    <input (blur)="showValueDesignation($event)" class="nom" spellcheck="false" type="text"
                      id={{nomsupp}} ngModel name={{nomsupp}} nbInput fullWidth #nom1="ngModel"
                      placeholder="Désignation" autocomplete="none" />
                  </td>

                  <td
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <input class="inp" (blur)="showValue($event)" spellcheck="false" type="text" id={{mt_ht_supp}}
                      name={{mt_ht_supp}} ngModel nbInput fullWidth placeholder="Montant HT" #mt_ht_supp1="ngModel"
                      autocomplete="none" />
                  </td>
                  <td
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    <mat-select name="taux_tva_supp1" [(ngModel)]="Percentage"
                      (ngModelChange)="showoperationSelectedd()" fullWidth size="small">

                      <mat-option *ngFor="let Percent of PercentageValue" [value]="Percent.value"
                        (click)="createReservation(Row)">
                        {{Percent.viewValue}}</mat-option>
                    </mat-select>
                  </td>
                  <td (blur)="showValueTTCMT($event)"
                    style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                    {{result}}
                  </td>
                </tr>
              </tbody>
            </table>
            <table style="border-collapse: separate; border-spacing: 2px;background: #EEE; border-color: #BBB;"
              class="balance">
              <tr
                style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                <th
                  style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                  <span contenteditable>Total HT</span>
                </th>
                <td
                  style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                  <span factureData-prefix> </span>
                  {{result}}
                </td>
              </tr>
              <tr
                style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                <th
                  style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                  <span contenteditable> TVA</span>
                </th>
                <td
                  style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                  {{percent}}
                </td>
              </tr>
              <tr
                style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                <th
                  style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                  <span contenteditable>Stamp Duty</span>
                </th>
                <td
                  style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                  0.600</td>
              </tr>
              <tr
                style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                <th
                  style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                  <span contenteditable>Price TTC</span>
                </th>
                <td (blur)="showValueTTCMT($event)"
                  style="border-width: 1px; padding: 0.5em; position: relative; text-align: left;border-radius: 0.25em; border-style: solid;background: #EEE; border-color: #BBB;">
                  {{mtttc}}
                </td>
              </tr>



            </table>


          </article>
          <br><br>
          <button color="primary" mat-flat-button type="submit" style="margin-left: 90%;"
            (click)="PostFacture()">Confirmation</button>

        </form>
      </mat-step>
    </mat-horizontal-stepper>

  </vex-page-layout-content>
</vex-page-layout>
<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-customer="customer" matMenuContent>


  </ng-template>
</mat-menu>