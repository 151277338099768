import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy,ChangeDetectorRef, Component, Inject, InjectFlags, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, FormArray, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, ReplaySubject, startWith, map, of } from 'rxjs';
import { fadeInUp400ms } from '../../../../../../src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from '../../../../../../src/@vex/animations/stagger.animation';
import { TableColumn } from '../../../../../../src/@vex/interfaces/table-column.interface';
import { aioTableLabels } from '../../../../../../src/static-data/aio-table-data';
import { ApiService } from '../api.service';
import { CreateContratComponent } from '../create-contrat/create-contrat.component';
import { Contrat } from '../interfaces/contrat.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatTabGroup } from '@angular/material/tabs';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router'; // Assurez-vous d'importer Router
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { CancellationComponent } from '../cancellation/cancellation.component';
import { error } from 'console';
@UntilDestroy()

@Component({
  selector: 'vex-affichage-contrat',
  templateUrl: './affichage-contrat.component.html',
  styleUrls: ['./affichage-contrat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]

})

export class AffichageContratComponent implements OnInit, OnDestroy {

   RoomTypeConcel: any[];

 inputReadonly = true;
 RoomTypechild:any[]=[]
  allotement: any[] = []
  AllotementToUpdate: any[] = []
  tableauRoom: any[] = []
  marketCtrl: UntypedFormControl;
  destinationChildBoard: UntypedFormControl;
  roomChildchild: UntypedFormControl;
  ContratRoomchild
  selectedmarket
  idmarket: any
  selectedTabIndex = 0;



  marketname: any
  filteredMarket$: Observable<any[]>;
  Market: any[]
  customers: any[];
  static id: 100;
  nbPeriodes: number = 0;
  CodePeriod: string;
  event: string;
  list_room: [];
  public formContrat: UntypedFormGroup;
  public formPeriode: UntypedFormGroup;
  public formRooms: UntypedFormGroup;
  public formBaseRoom: UntypedFormGroup;
  public formBoarding: UntypedFormGroup
  public formallotement: UntypedFormGroup
  public formExtraBoarding: FormGroup;
  formCancellation = this.fb.group({
    PeriodGroupes: this.fb.array([])  // FormArray for PeriodGroupes
  });

  showNumP = true

  TypeCalculat: boolean


  mode: 'create' | 'update' = 'create';
  ROOM: string;
  HotelName: any;
  nameboard: any;
  Contracthotelname: any;
  hotelname: any;
  selectvalueaceess: any;
  version: number;
  nameP: number;
  CheckIn: string;
  CheckInn: string;
  contrat_id: any;
  paxRoom: number;
  MaxPax: number;
  Minadult: number;
  Maxadult: number;
  child: number;
  type = "true"
  codeRoom: number;
  roomname: any;
  roomtype: any;
  period_id: any;
  Contractname: any;
  roomid: any;
  boardId: any;
  board_id: any;
  selectedRoomPricesname: any;
  roomPrices: any;
  selectedRoomTypePrices: any;
  type_id: any;
  room_id: any;
  selectedRoomPrices: any;
  TabUpdateChildPrices: any[] = []
  @ViewChild('stepper') stepper: MatStepper;
  board_idPrices: any;
  selectedBoardPrices: any;
  roomName: any;
  pricevalue: number;
  Periods:any[]
  extbprice: number;
  basepriceid: any;
  def: any;
  codep: any;
  selectvalueDevise: string;
  market: any;
  Roomsarray: any;
  board_name: any;
  contrat: any;
  IdRooms: any[] = [];
  minFromDate ;
  IdChuild: any;
  IdChild: any;
  selectedboardPeriod: any;
  IdContratPeriod: void[];
  namePeriode: any;
  listboardperiod: any;
  Boardarrayy: any;
  Code: number;
  boardnameextra: any;
  selectedboardextra: any;
  boardextraId: any;
  tableauBoardExtrat: any[] = []
  IdChildofcontrat: any;
  IdchildContrat: number = 0;
  selectedboardchild: any;
  BoardidChild: any[];
  roomchild: any;
  typechild: any;
  ageadultchild: any;
  ageto: any;
  nbadult: number;
  pricechild: any;
  nbchildd: any;
  BoardidChildd: any;
  selectedRoomTypechild: any;
  itemConcell!: FormArray;
  BoardConcellation:any[]=[]
  BoardArrayOfContrat: any;
  destinationroomtypeForCancellationConditions: UntypedFormControl;
  selectedRoomTypeConcellation: any;
  TypeConcellation_name: any;
  TypeConcellation_id: any;
  selectedConcel: any;
  BoardConcellName: any;
  BoardConcellid: any;
  IdRoome: any;
  idcontrat: number;
  selectedPeriodId: any;
  idCancell: any;
  loading = false;
  toAllotement: boolean=false;


  filterBoardingforboardingextrat$: Observable<any[]>;
  destinationBoardExtra: UntypedFormControl;
  BoardExtra: any[] = []
  EXTBPrice: number
  filterBoardingforboarding$: Observable<any[]>[] = [];
  public filterRoomTypeContrat$: Observable<any[]>;
  fltDestinationsForContrat$: Observable<any[]>;
  filterRoomTypeContratForPrices$: Observable<any[]>
  filterBoardingContrat$: Observable<any[]>
  ContratRoom: UntypedFormControl;
  selectedRoomType
  DisabledBoardArray: boolean[][] = [[true],[true],[true],[true],[true],[true],[true],[true]];
  DisabledRoomArray: boolean[][] = [[true],[true],[true],[true],[true],[true],[true],[true]];

  DisabledRoomTypeArray: boolean[][] = [[true],[true],[true],[true],[true],[true],[true],[true]];
  ContratRoomType: UntypedFormControl;
  item!: FormArray;
  itemPeriode!: FormArray;
  items!: FormArray;
  destinationBoard: UntypedFormControl;
  destinationBoardForCancellation: UntypedFormControl;
  filterBoardingCancellation$: Observable<any[]>
  beginP: Date;
  Price: number
  PriceType: number
  EXTBPriceType: number
  dataSourceRoomstype: MatTableDataSource<any> | null;
  rooms: string = '';

  /***********BoardPRICES************** */
  Boarding: any
  PriceBoard: number
  EXTBPriceBoard: number
  tableauBoard: any[] = []
  tableauPriceBoard: any[] = []
  tableauEXTBPriceBoard: any[] = []
  tableauTypeBoard: any[] = []
  
  TypeCalculatBoard: boolean
  selecteP: any
  boardtype = "true"
  destinationBoardPeriod: UntypedFormControl;

  /***************RoomTypePrices************* */
  Type: any
  TypeCalculatType: boolean

  tabidroomsForPrices: any[] = []
  tabiPeriodesForPrices: any[] = []

  tabidtyperoomsForPrices: any[] = []
  tabidboardsForPrices: any[] = []
  tabidChangedRoomsForPrices: any[] = []
  tabidChangedTypeRoomForPrices: any[] = []
  tabidChangedboardsForPrices: any[] = []
  tabidChangedRoomForPrices: any[] = []
  tableauBoradCancell: any[] = []
  filterBoardingforboardingg$: Observable<any[]>
  tableauRoomType: any[] = []
  tableauPriceType: any[] = []
  tableauEXTBPriceType: any[] = []
  tableauTypeRoom: any[] = []

  tableauEXTBPrice: any[] = []
  tableauPrice: any[] = []
  tableauType: any[] = []
  tableauBase: any[] = []
  IdRoom: any;
  RoomsForAllotement: any[] = []
  columnsRoom = [
    { columnDef: 'room', header: 'Room' },
    { columnDef: 'Price', header: 'Price' },
    { columnDef: 'EXTBPrice', header: 'EXTBPrice' },
    { columnDef: 'TypeCalculat', header: 'TypeCalculat' },
    { columnDef: 'Cancellations', header: 'Cancellations' },

  ]
  options = [
    { value: true, display: "%" },
    { value: false, display: "#" }
  ];

  //Room Type price:roomtype
  columnToDisplayType: string[] = ['RoomType', 'PriceRoomType', 'EXTBPriceType', 'TypeCalculatType','Cancellations'];
  columnToDisplaye: string[] = this.columnToDisplayType.slice();
  columnsRoomtype = [
    { columnDeftype: 'RoomType', header: 'RoomType' },
    { columnDeftype: 'PriceRoomType', header: 'Price' },
    { columnDeftype: 'EXTBPriceType', header: 'EXTBPrice' },
    { columnDeftype: 'TypeCalculatType', header: 'TypeCalculat' },
    { columnDeftype: 'Cancellations', header: 'Cancellations' },


  ]
  NbAdulttab: number[] = [];

  //BoardingPrices
  dataSourceBoard: MatTableDataSource<any> | null;
  columnToDisplayBoard: string[] = ['Board', 'PriceBoard', 'EXTBPriceBoard', 'TypeCalculatBoard','Cancellations'];
  columnToDisplayeBoard: string[] = this.columnToDisplayBoard.slice();
  columnsBoard = [
    { columnDefBoard: 'Board', header: 'Board' },
    { columnDefBoard: 'PriceBoard', header: 'Price' },
    { columnDefBoard: 'EXTBPriceBoard', header: 'EXTBPrice' },
    { columnDefBoard: 'TypeCalculatBoard', header: 'TypeCalculat' },
    { columnDefBoard: 'Cancellations', header: 'Cancellations' },



  ]
  BoardT: any[] = []
  // PricesTABLEAU
  dataSourcePrices: MatTableDataSource<any> | null;
  columnToDisplayPrices: string[] = ['code', 'name', 'start_at', 'end_at', 'room_name',
    'type_name', 'board_name', 'price', 'price_with_extb'];
  columnToDisplayePrices: string[] = this.columnToDisplayPrices.slice();
  columnsPrices = [
    { columnDefPrices: 'code', header: 'Code.' },
    { columnDefPrices: 'name', header: 'Name' },
    { columnDefPrices: 'start_at', header: 'StartAt' },
    { columnDefPrices: 'end_at', header: 'EndAt' },
    { columnDeffPrices: 'room_id', header: 'RoomID' },
    { columnDeffPrices: 'room_name', header: 'RoomName' },
    { columnDeffPrices: 'type_name', header: 'TypeName' },
    { columnDeffPrices: 'board_name', header: 'BoardName' },
    { columnDeffPrices: 'price', header: 'Price' },
    { columnDeffPrices: 'price_with_extb', header: 'EXTBPrice' },

  ]
  periods: any[] = [];
  Arraydef: any[] = []
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  /***********BoardPRICES************** */
  ROOM0: string;
  CalculL: string;
  periodboard: any[] = []
  Calcul: string;
  tablo: string[] = []
  tableselect: string[] = []
  BeginContrat: any;
  EndContrat: any
  CheckInPeriod: any
  changeCheckInPeriod: any;
  changeCheckInn_period: any;
  changeCheckIn_Board: any;
  ListCancellationConditionsofContract: any
  ActivateNowShow: boolean = false
  ActivateBillPerStay: boolean = false
  ActivateFreeCancellation: boolean = false
  changeCheckInEndBoard: any;
  destination: UntypedFormControl;
  roomChild: UntypedFormControl
  destinationIndex: UntypedFormControl;
  fltDestinations$: Observable<any[]>;
  DestinationCtrl: UntypedFormControl;
  filterContrat$: Observable<any[]>;
  destinationCtrl: UntypedFormControl;
  RoomTypeForPrices: UntypedFormControl;
  BoardingForPrices: UntypedFormControl;
  filteredHotels$: Observable<any[]>;
  filterBoardingChild$: Observable<any[]>;
  fltDestinationsChild$: Observable<any[]>;
  destinationRoomPrices: UntypedFormControl;
  BoardPrice: any[]
  selectedmap
  accessL: string
  IdPeriod: any[] = []
  definition = new UntypedFormControl();
  name = new UntypedFormControl();
  IdContrat: number
  ContractName: string
  ContractHotelid: number
  selectedcontrat
  selectvalue: string
  Filters
  selectedroom
  RoomNameChild:any[]=[]
  TabPeriodes: any[]
  Boardarray: any[]
  disabled: boolean = false
  PeriodBoard: any[]
  ArrayRoomTypeCancellation:any[]=[]
  tableauRoomTypecancell:any[]=[]
  ArrayBoardingCancellation:any[] = []

  TabCanellationCondtions: any[] = []
  Arraynameboard: any[] = []
  RoomName: any[];
  RoomType: any[];
  Contrat: any[];
  Hotel: any[];
  ArrayTypeRoom: any[] = [];
  ArrayRoomName: any[] = [];
  IdTypeRoom: any[] = [];
  RowsRoomType: any[] = []
  RowsRoom: any[] = []
  RowsBoard: any[] = []
  RowsRoomNameP: any[] = []

  tableColumns: TableColumn<any>[] = [
    {
      label: 'Rooms',
      property: 'room',
      type: 'text',
      cssClasses: ['font-medium']
    },
  ]
  Room: any;

  labels = aioTableLabels;
  ArrayIdBoardExtra: any

  layoutCtrl = new UntypedFormControl('boxed');
  subject$: ReplaySubject<Contrat[]> = new ReplaySubject<any[]>(1);
  data$: Observable<any[]> = this.subject$.asObservable();
  selectedboard
  childofcontrat:any
  Arrangement: any[];
  ArrangementChild: any[];

  filterBoarding$: Observable<any[]>;
  filterBoardingContratForPrices$: Observable<any[]>
  destinationBoardPrices: UntypedFormControl;
  maxToDate: Date | null;
  maxFromDate: Date | null;
  mintoDate = new Date();
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new UntypedFormControl();
  data_Prices: MatTableDataSource<any> | null;
  displayedPrices: string[] = ['Room', 'Definition', 'RoomType', 'Board',
    'Period', 'BuyingPrice', 'EXTBPrice', 'BoardPrice', 'PriceType'
  ];
  //Room
  dataSource: MatTableDataSource<any> | null;
  dataSourceRooms: MatTableDataSource<any> | null;

  displayedColumns: string[] = ['period_id', 'period_name', 'room_id', 'number'];
  columnToDisplayed: string[] = this.displayedColumns.slice();
  Rows: any[] = []
  RowsRooms: any[] = []

  columns = []
  childP:any[]=[]
  //Room price:room /*********************************/ */
  displayedC: string[] = ['room', 'Price', 'EXTBPrice', 'TypeCalculat','Cancellations'];
  columnToDisplay: string[] = this.displayedC.slice();
  columnToDisplayT: string[] = ['type_id', 'room_type']
  columnToDisplayB: string[] = ['board_id', 'board_name']
  columnToDisplayP: string[] = ['period_name', 'period_id', 'price', 'extb_price', 'par_pax']
  ArrayBoarding: any[] = [];
  ArrayRoomNamePrices: any[] = [];
  ArrayRoomTypePrices: any[] = [];
  ArrayBoardingPrices: any[] = [];

  extbPrice: any;


  data: MatTableDataSource<any> | null;
  staticListCtrl: Array<UntypedFormControl> = [];

  Periodes: any[]
  Boardings: any[]
  Rooms: any[]
  RoomsTypes: any[]
  RoomNameP: any[]
  childpricestab: any[]
  updatedChildPrices:any[]=[]
  displayedtype: string[] = ['Type', 'Price', 'EXTBPrice', 'TypeCalculat'];
  data_type: MatTableDataSource<any> | null;

  displayedBoard: string[] = ['Board', 'Price', 'EXTBPrice', 'TypeCalculat'];
  data_Board: MatTableDataSource<any> | null;
  selected = new FormControl(0);
  status: any[] = [
    { value: 'SARL', viewValue: 'SARL' },
    { value: 'SA', viewValue: 'SA' },
    { value: 'SAS', viewValue: 'SAS' },
    { value: 'SASU', viewValue: 'SASU' },
  ];
  Accesslist: any[] = [
    { value: 'B2B', viewValue: 'B2B' },
    { value: 'B2C', viewValue: 'B2C' },
    { value: 'BOTH', viewValue: 'BOTH' },
  ]
  CalculList: any[] = [
    { value: 'true', viewValue: 'Per Pax' },
    { value: 'false', viewValue: 'Per Room' },
  ]
  perpax = 'Per Pax'
  perroom = 'Per Room'
  DeviseList: any[] = [
    { value: 'TND', viewValue: 'TND' },
    { value: 'EUR', viewValue: 'EUR' },
    { value: 'USD', viewValue: 'USD' },
    { value: 'DZD', viewValue: 'DZD' },
    { value: 'LYD', viewValue: 'LYD' },
    { value: 'EGP', viewValue: 'EGP' },
    { value: 'AED', viewValue: 'AED' },
  ]
  DeviseL: string
  RoomNamePrices: any[]
  RoomNameForPrice
  ContratRoomTypeForPrices
  RoomType2: any[] = []
  /*BasePrices*/
  BoardForBasePrice: any[] = []
  RoomForBasePrices: any[] = []
  FilterTableBoardPrices: any[] = []
  FilterTableRoomPrices: any[] = []
  FilterTableRoomTypePrices: any[] = []

  RoomTypeForBasePrices: any[] = []
  /*BasePrices*/
  /**child prices */
  formchild: any;
  public formChild: UntypedFormGroup;
  ArrayRoomBoardForChild: any[] = []
  ArrayRoomNameForChild: any[] = []
  ArrayRoomTypeForChild: any[] = []
  periodeForChild: any[] = []
  nbchild: any[] = []
  ageAdult: any[] = []
  ageToadult: any[] = []
  pricesAdult: any[] = []
  activate: any[] = []
  Childs: [] = []
  childs: any;
  tableauChild: any[] = []
  NbAdult: any
  childprices: any[] = []
  Arraychild: any[] = [];
  Board: any[];
  NB: any
  filterRoomTypeContratForChild$: Observable<any[]>;
  filterBoardingPeriod$: Observable<any[]>
  RoomType1: any[] = []
  PeriodL: any
  periode: any[] = []
  index: number = 1
  SelectedValue: any
  Periodlist: any
  AgeTo: any
  Age: any
  prices: any
  selectedboardForChildPrices:any
  Pricesboard: number
  selectedroomForChildPrices
  Pricesroomname: number
  PricesRoomtype: number
  public useDefaultoperation = false;
  togglevalue: any
  public useDefault = false;
  childT: any[] = []
  PeriodeForChildPrice: any[] = []
  displayedColPrice: string[] = ['child_number', 'age_from', 'age_to', 'price', 'operation'];

  constructor(@Inject(MAT_DIALOG_DATA)  public defaults: any, private dialog:MatDialog,private spinner: NgxSpinnerService,
    private dialogRef: MatDialogRef<CreateContratComponent>,private router: Router,
    private fb: UntypedFormBuilder, private ContratService: ApiService, 
    public datepipe: DatePipe,
     private cd: ChangeDetectorRef, private formBuilder: FormBuilder, private cdr: ChangeDetectorRef) {
    this.destinationBoardPrices = this.fb.control('');
    console.log('ID Contraty:', this.defaults.id);
  }
  ngOnInit(): void {
    console.log("this.defaults: ", this.defaults)
    this.mode = 'update';
    this.initFormChildPrice()
    this.initFormContrat()
    this.getContrats();
    this.GetMarket()
    this.initFormPeriods()
    this.initFormRoom()  
    this.initBasePriceForm()
    this.setFormPeriodsData()
    this.getAllRooms()
    this.getAllRoomTypes()
    this.getAllArrangement()
    this.getArrangementByPeriod(this.defaults.id)
    this.setDatesRanges()
    this.initFormBoard()
    this.setFormBoardData(this.defaults.id)
    this.initFormAllotement()
    this.setAllotementFormData()
    this.setFormChildPriceData()
    this.getBoardingPrices();
    this.getRoomPrices();
    this.getRoomTypePrices();

  }
/*******************START ALERTS ********************************/

  showSuccessAlert(message): void {
    Swal.fire({
      title: 'Success',
      text: message,
      icon: 'success',
      confirmButtonText: 'Okay',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.dialogRef.close()
        console.log('Okay button pressed');
      } else if (result.isDismissed) {
        this.dialogRef.close()
        console.log('Dialog was dismissed');
      }
    });
   

  }

  showWarningAlert(message): void {
    const dialogswal=Swal.fire({
      title: 'Warning',
      text: message,
      icon: 'warning',
      confirmButtonText: 'Okay',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      allowOutsideClick: false
    })
  
   

  }
  showErrorAlert(): void {
  
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    });
   

  }
/*******************END ALERTS ***********************************/

/*******************START CONTRAT INTERFACE***********************/
getContrats() {
  this.ContratService.getContratAutoComplete().subscribe(responsecontrat => {
    //console.log("responsecontrat: ", responsecontrat
    if (!responsecontrat.error) {
      this.Contrat = responsecontrat

      this.filterContrat$ = of([
        ...this.Contrat
      ]);
   
    }
  })
}

setDatesRanges(){
  this.defaults
  this.minFromDate=this.defaults.start_at
  this.mintoDate=this.defaults.end_at
  console.log("PDATE minFromDate: ", this.minFromDate)
  console.log(" PDATE mintoDate: ", this.mintoDate)

}
    initFormContrat(){

      this.formContrat = this.fb.group({
       id:[this.defaults.id],
       name: [this.defaults.name],
       hotel_id: [this.defaults.hotel_id ],
       hotel_name:[this.defaults.hotel_name],
       start_at: [this.defaults.start_at],
       end_at: [this.defaults.end_at],
       version: [this.defaults.version ],
       access: [this.defaults.access ],
       currency: [this.defaults.currency ],
       market: [this.defaults.market ],
       market_name: [this.defaults.market_name ],
 
     });
   }
   filteredMarket(name) {
     return this.Market.filter(destination3 =>
       destination3.name.toLowerCase().indexOf(name.toLowerCase()) >= 0);
   }
   onChangeDestinationmarket(selected) {
     const selectedmarket = this.Market.find(e => e.name === selected)
 
     this.formContrat.controls['market'].setValue(selectedmarket.id)
     this.formContrat.controls['market_name'].setValue(selectedmarket.name)
 
     this.formContrat.updateValueAndValidity()
 
   }
   GetMarket() {
     this.ContratService.GetMarket().subscribe(response => {
       console.log("ResponseMArket: ", response)
       if (!response.error) {
         this.Market = response.items
         this.filteredMarket$ = this.marketCtrl.valueChanges.pipe(
           startWith(''),
           map(destination3 => destination3 ? this.filteredMarket(destination3) : this.Market.slice())
         );
       }
       console.log("filteredMarket", this.filteredMarket$)
     })
   }
 
   changeBeginCotrat(event) {
 
     const end_at = this.formContrat.get("end_at") 
   
     const BeginContrat = this.datepipe.transform(new Date(event.value), 'yyyy-MM-dd')
   
     if(end_at.value && end_at.value<=BeginContrat){
       this.showWarningAlert("Dates must be consecutive")
       this.formContrat.get("start_at").reset()
   
     }
     else
   
     this.formContrat.controls['start_at'].setValue(BeginContrat)
     }
   changeEndContrat(event) {
     const start_at = this.formContrat.get("start_at") 
   
     console.log("contrat",start_at)
     const EndContrat = this.datepipe.transform(new Date(event.value), 'yyyy-MM-dd')
   
     if(start_at.value==EndContrat){
       this.showWarningAlert("The dates cant be the same")
       this.formContrat.get("end_at").reset()
   
     }
     else
     
     this.formContrat.controls['end_at'].setValue(EndContrat)
   
   }
   updateContrat() {
 
     if(this.formContrat.status=='VALID'){
       const contrat = this.formContrat.value;
       console.log("formContrat:",contrat)
         this.ContratService.updatecontrat(contrat).subscribe((Response: any) => {
             console.log("Responseupdated--------:", Response)
             this.showSuccessAlert(Response.message)
           }),
           (error)=>{
             this.showErrorAlert()
 
           }
     }
          
     
         
         
       }
/*******************END CONTRAT INTERFACE**************************/

/*******************START PERIOD INTERFACE*************************/
  get PeriodeArray() {
    return this.formPeriode.get("PeriodeArray") as FormArray;
  }
  setFormPeriodsData(){
    this.ContratService.getPeriodesOfContrat(this.defaults.id).subscribe(respPriodes => {
      // console.log("respPriodes: ", respPriodes," respPriodes.length: ", respPriodes.length)
      this.formPeriode.get("NBperiode").setValue(respPriodes.length);
      this.Periodes = respPriodes.map((periode: any) => {
        return { ...periode, pressed: false,pressedBasePrice: false };
      });      console.log("this.Periodes: ")
      this.Periodlist = respPriodes
      console.log("this.Periodes1: ", this.Periodes)
      for (let i = 0; i < respPriodes.length; i++) {
        //console.log("i: ",i)
        this.AddnewrowPeriode(respPriodes[i],i)
      }
        
      this.setFormRoomsData()  

      this.setFormBasePriceData()

      this.cd.detectChanges()
    })
  }
  initFormPeriods(){

    this.formPeriode = this.fb.group({
      NBperiode: ['',],
    
      PeriodeArray: new FormArray([]),
    })
  }
  blurEvent(periode) {
    this.nbPeriodes = parseInt(periode.target.value)
    for (let i = 0; i < this.nbPeriodes; i++) {
      //console.log("oniit", i)
      this.AddnewrowPeriode(null,i+1)
    }
    
    this.cd.detectChanges()
  }
  GenrowPeriode(periode,i): FormGroup {

    if (periode != null)
      return new FormGroup({
        id: new FormControl(periode.id),
        name: new FormControl(periode.name),
        code: new FormControl(periode.code),
        start_at: new FormControl(periode.start_at),
        end_at: new FormControl(periode.end_at),
      });
    else{
      this.toAllotement=true

      return new FormGroup({
        id: new FormControl(0,),
        name: new FormControl('' ),
        code: new FormControl(i),
        start_at: new FormControl(''),
        end_at: new FormControl(''),
      });
    
    }
  }

  AddnewNullPeriod(period,i){
    const controls = this.formPeriode.get("PeriodeArray") as FormArray
    this.itemPeriode = this.formPeriode.get("PeriodeArray") as FormArray;

    if(i!=1){
      if(this.PeriodeArray.at(i-2).status=='VALID' ){
        this.itemPeriode.push(this.GenrowPeriode(null,i)) 



      }
      else{
        this.showWarningAlert("you need to complete the previous periods first")
      }
  }}
  AddnewrowPeriode(priode,i) {
     
    const controls = this.formPeriode.get("PeriodeArray") as FormArray
    this.itemPeriode = this.formPeriode.get("PeriodeArray") as FormArray;
    if (priode != null) { this.itemPeriode.push(this.GenrowPeriode(priode,i)) 

      this.showNumP = false
    }
    else { this.itemPeriode.push(this.GenrowPeriode(null,i)) 

      this.showNumP = false
    }
    
  }
  async RemovePeriode(index: any) {
    this.itemPeriode = this.formPeriode.get("PeriodeArray") as FormArray;
    const formGroupAtIndex = this.itemPeriode.at(index) as FormGroup;
    const idValue = formGroupAtIndex.get('id')?.value;
    if(idValue!=0){
       await this.ContratService.deletePeriod(idValue).subscribe(response => {
       console.log("deletePeriod"+response)
       this.itemPeriode.removeAt(index)
       this.cd.detectChanges()

    })
  }
  else{
    this.itemPeriode.removeAt(index)
    this.cd.detectChanges()

  }
  

}
savePeriode() {
    
  let formValue = this.formPeriode.get("PeriodeArray")
  console.log("savePeriode formPeriode. ", formValue)
if(formValue.status=='VALID'){
  let objPeriode = Object.assign({ periods: formValue })
  console.log("objPeriode: ", objPeriode)
  console.log("this.mode to update periode", this.mode)
  this.updatePeriode(formValue.value, this.defaults.id)

}


 
}
updatePeriode(objPeriode, idContrat) {
  console.log("obj Priode to update: ", objPeriode, "id contart to update: ", idContrat)
  this.ContratService.UpdatePeriodeContrat(objPeriode, idContrat).subscribe(respcreateperiodecontrat => {
    console.log("respupdateperiodecontrat: ", respcreateperiodecontrat)
    this.ContratService.getPeriod(idContrat).subscribe(async(data: any) => {
      this.Periodlist = data;
      console.log("data of Period:", data)
      await this.setAllotementFormData()
      if(this.toAllotement){
        this.showWarningAlert("You need to add allotement for the rooms in the new period")
        this.stepper.selectedIndex = 4;  
      }
      else{

        this.dialogRef.close()
      }

 
    })
  })
}
changeCheckInn1(event,index) {
  var status=false
  const itemPeriode = this.formPeriode.get("PeriodeArray") as FormArray;
  const formGroupAtIndexminus1 = itemPeriode.at(index-1) as FormGroup;
  const formGroupAtIndex = itemPeriode.at(index) as FormGroup;

  if(index!=0 &&  this.PeriodeArray.at(index-1).status!=='VALID'){
    formGroupAtIndex.get('start_at').reset()

    this.showWarningAlert("you need to complete the previous periods first")

  
  
  }
  else {
  if(index!=0){

 

      if(!formGroupAtIndex.get('end_at').value){
        status=true


      }
       if( this.datepipe.transform(new Date(event.value), 'yyyy-MM-dd')<formGroupAtIndex.get('end_at').value && formGroupAtIndex.get('end_at').value){
        status=true
        
   
       }
       else         
       status=false

       if( this.datepipe.transform(new Date(event.value), 'yyyy-MM-dd')>formGroupAtIndexminus1.get('end_at').value){
        status=true}
        else status=false
  }
  else{
 if(!formGroupAtIndex.get('end_at').value){
  status=true


 }

   else if( this.datepipe.transform(new Date(event.value), 'yyyy-MM-dd')<formGroupAtIndex.get('end_at').value && formGroupAtIndex.get('end_at').value){
     status=true
     

    }
    else status=false




  }
  console.log(" status ", status)

  if(status){
      const start_at = formGroupAtIndex.get('start_at');
      start_at.setValue(this.datepipe.transform(new Date(event.value), 'yyyy-MM-dd'));
  }
  else{
    formGroupAtIndex.get('start_at').reset()
    this.showWarningAlert("the dates should be successive")
  }
 
}}
changeCheckInn2(event,index) {

  const itemPeriode = this.formPeriode.get("PeriodeArray") as FormArray;
    const formGroupAtIndex = itemPeriode.at(index) as FormGroup;


    if(index!=0 &&  this.PeriodeArray.at(index-1).status!=='VALID'){
      formGroupAtIndex.get('end_at').reset()
  
      this.showWarningAlert("you need to complete the previous periods first")
  
    
    
    }
    else{
    if( this.datepipe.transform(new Date(event.value), 'yyyy-MM-dd')>formGroupAtIndex.get('start_at').value){

      const end_at = formGroupAtIndex.get('end_at');
  end_at.setValue(this.datepipe.transform(new Date(event.value), 'yyyy-MM-dd'));
    }
else{
  formGroupAtIndex.get('end_at').reset()
  this.showWarningAlert("the dates should be successive")
}
}


}
/*******************END PERIOD INTERFACE*************************/


/*******************START ROOM INTERFACE*************************/
get RoomArray() {
  return this.formRooms.get("RoomArray") as FormArray;
}
setFormRoomsData(){
  this.ContratService.getRoomsOfContrat(this.defaults.id).subscribe(respRooms => {
    console.log("respRooms for contract.length: ", respRooms)

    for (let i = 0; i < respRooms.length; i++) {
      console.log("respRooms[i]: ", respRooms[i])
      this.AddnewrowRoom(respRooms[i])
    }
    
    this.fltDestinationsChild$ = of([
      { room_id: 0, room_name: "All Rooms" },
      ...respRooms.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.room_id === item.room_id
        ))
      )
    ]);

    this.filterRoomTypeContratForChild$ = of([
      { room_type_id: 0, room_type_name: "All Room Types" },
      ...respRooms.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.room_type_id === item.room_type_id
        ))
      )
    ]);


 
   



  })
}
initFormRoom(){
  this.formRooms = this.fb.group({
    RoomArray: new FormArray([]),
  });
 
}

getAllRoomTypes() {
  this.ContratService.getRoomtype().subscribe(response => {
    console.log("responseRoomTYpe**: ", response)
      this.filterRoomTypeContrat$ = of(response.items );


  })
}

getAllRooms() {
  this.ContratService.getRoomName().subscribe(response => {
    console.log("response of room Names: ", response)
   
      
  
    this.fltDestinationsForContrat$ = of(response.items );
    
    
  })
  


}
AddnewrowRoom(room) {
  if (room != null) {

    this.roomid = room.id
    console.log("<FormArray>this.formRooms.controls: ", this.formRooms.controls.length)
    const controls = this.formRooms.get("RoomArray") as FormArray
    this.item = this.formRooms.get("RoomArray") as FormArray;
    this.item.push(this.GenrowRoom(room))
    this.ArrayTypeRoom.push("")
    this.ArrayRoomName.push("")
    console.log("controls.length - 1: ", controls.length - 1)
   
  }

  else {
    const controls = this.formRooms.get("RoomArray") as FormArray
    this.item = this.formRooms.get("RoomArray") as FormArray;
    this.item.push(this.GenrowRoom(null))
    this.ArrayTypeRoom.push("")
    this.ArrayRoomName.push("")
    console.log("controls.length: ", controls.length)


  }

}
GenrowRoom(room): FormGroup {
  console.log(" GenrowRoom rooms : ", room)
  if (room == null) {
    return new FormGroup({
      code_room: new FormControl(''),
      code_type: new FormControl(''),
      room_id: new FormControl(''),
      room_name: new FormControl(''),
      room_type_name: new FormControl(''),
      room_type_id: new FormControl(''),
      max_pax: new FormControl(''),
      min_pax: new FormControl(''),
      max_adult: new FormControl(''),
      min_adult: new FormControl(''),
      child: new FormControl(''),
    });
  }
  else {
    return new FormGroup({
      code_room: new FormControl(room.code_room),
      code_type: new FormControl(room.code_type),
      room_id: new FormControl(room.room_id),
      room_type_id: new FormControl(room.room_type_id),
      room_name: new FormControl(room.room_name),
      room_type_name: new FormControl(room.room_type_name),
      max_pax: new FormControl(room.max_pax ),
      min_pax: new FormControl(room.min_pax ),
      max_adult: new FormControl(room.max_adult ),
      min_adult: new FormControl(room.min_adult ),
      child: new FormControl(room.child ),
      id: new FormControl(room.id),

    });
  }
}

RemoveRoom(index: any) {
    


  this.item = this.formRooms.get("RoomArray") as FormArray;
  const formGroupAtIndex = this.item.at(index) as FormGroup;
  const idValue = formGroupAtIndex.get('id')?.value;
  console.log("idValue"+idValue)
  if(idValue!=undefined){
    this.ContratService.deleteRoom(idValue).subscribe(response => {
      console.log("deleteRoom"+JSON.stringify(response))
      this.item.removeAt(index)
      this.cd.detectChanges()
    
      })
  }
  else{
    this.item.removeAt(index)
    this.cd.detectChanges()
  }





  this.ArrayTypeRoom.splice(index, 1);
  this.ArrayRoomName.splice(index, 1);
}

onChangeRoomName(selected, index) {
 
  var selectedRoom 
  this.fltDestinationsForContrat$.pipe(
    map(arr => arr.find(e => e.name === selected))
  ).subscribe(selectedRooms => {
    selectedRoom = selectedRooms;
  });  

    const roomArray = this.formRooms.get('RoomArray') as FormArray;
    const roomGroup = roomArray.at(index) as FormGroup;


    roomGroup.patchValue({
      min_adult: selectedRoom.min_adult,
      max_pax: selectedRoom.max_pax,
      min_pax: selectedRoom.min_pax,
      max_adult: selectedRoom.max_adult,
      child: selectedRoom.child,
      code_room: selectedRoom.name_code,
      room_name:selectedRoom.name,
      room_id:selectedRoom.id,



    });
  
  this.ArrayRoomName[index] = selectedRoom.id

}
onChangeContratRoomType(selected, index) {
  var selectedRoomType 
  this.filterRoomTypeContrat$.pipe(
    map(arr => arr.find(e => e.name === selected))
  ).subscribe(selectedRoomTypes => {
    selectedRoomType = selectedRoomTypes;
  });  

    const roomArray = this.formRooms.get('RoomArray') as FormArray;
    const roomGroup = roomArray.at(index) as FormGroup;
    roomGroup.patchValue({
      code_type: selectedRoomType.code,
      room_type_id:selectedRoomType.id,
      room_type_name:selectedRoomType.name,



    });

}


async updateRoom() {
  var rooms=this.formRooms.get("RoomArray").value

  if (this.formRooms.get("RoomArray").status==='VALID'){
  this.ContratService.UpdateRoomContrat(rooms, this.defaults.id).subscribe(async (Response: any) => {
    await this.setAllotementFormData()
    this.cd.detectChanges()
  

    console.log("Responseupdated:", Response)
  
    this.showWarningAlert("You need to add allotement for the new rooms")
      this.stepper.selectedIndex = 4; 
    
  })
}
else{

  this.showWarningAlert("All fields are required")
}
}

/*******************END ROOM INTERFACE*************************/



/*******************START BOARD INTERFACE**********************/

initFormBoard(){
this.formBoarding = this.fb.group({

  BoardArray: new FormArray([]),


});
}

getAllArrangement() {
  this.ContratService.getArrangement().subscribe(response => {
    console.log("response for board: ", response)
      this.Arrangement = response.items
  })
}
RemoveBoard(index: any) {

  this.items = this.formBoarding.get("BoardArray") as FormArray;
  const formGroupAtIndex = this.items.at(index) as FormGroup;
  const idValue = formGroupAtIndex.get('id')?.value;
  this.ContratService.deleteBoard(idValue).subscribe(response => {
  console.log("deleteBoard"+JSON.stringify(response))
  this.items.removeAt(index)
  this.cd.detectChanges()

  })
  this.Arraynameboard.splice(index, 1)



}
getArrangementByPeriod(contrat_id) {
  this.ContratService.getBoardPeriod(contrat_id).subscribe(response => {
    if (!response.error) {
      if (Array.isArray(response)) {
        this.PeriodBoard = response;
    
      this.filterBoardingforboarding$[0] = of([
        
        ...this.Arrangement
      ]);

      this.filterBoardingPeriod$ = of([
        
        ...this.PeriodBoard
      ]);
  
      
      } else {
        console.error("response is not an array:", response);
        // Handle the case where response is not an array
        this.PeriodBoard = [];
      }
    }
  }, error => {
    console.error("Error fetching board period:", error);
    // Handle the error case here
  });
}
onChangeDestinationboard(selected, index) {
  console.log("index in form array: ", index);

 
  const selectedBoard = this.Arrangement.find(e => e.name === selected);
  console.log("onChangeDestinationboardselectedBoard: ", selectedBoard);

  if (selectedBoard) {

    const boardArray = this.formBoarding.get('BoardArray') as FormArray;
    const boardGroup = boardArray.at(index) as FormGroup;
      const inputBoardData=boardArray.at(index).value
    console.log("boardArray at onChangeDestinationboard"+ JSON.stringify(inputBoardData) )
    // this.filterBoardingforboarding$[(parseInt(inputBoardData.period_name, 10)-1)] = this.destinationBoard.valueChanges.pipe(
    //   startWith(''),
    //   map(destinationb => {
    //     const filteredArray = destinationb ? this.filterBoarding(destinationb) : this.Arrangement.slice();
    //     return filteredArray.filter(item => item.name !== inputBoardData.board_name);
    //   })
    // );
   
    boardGroup.patchValue({
      definition: selectedBoard.definition,
      board_id:selectedBoard.id,
      board_name:selectedBoard.name



    });

    this.formBoarding.updateValueAndValidity()
  }
}
AddBoardNewRow(): FormGroup {

    
  return new FormGroup({
    board_id: new FormControl('', Validators.required),
    board_name: new FormControl('', Validators.required),

    definition: new FormControl('', Validators.required),
    start_at: new FormControl(),
    end_at: new FormControl(),
    id: new FormControl(),
    period_id: new FormControl(),
    period_name: new FormControl()

  });

}
Addnewrow() {

  
    const controls = this.formBoarding.get("BoardArray") as FormArray;
    this.items = this.formBoarding.get("BoardArray") as FormArray;
    this.items.push(this.AddBoardNewRow());
    
  
}
get BoardArray() {
  return this.formBoarding.get("BoardArray") as FormArray;
}
setFormBoardData(id){

  this.ContratService.getBoardsOfContrat(id).subscribe(respBoards => {
    console.log("respBoards.length: ", respBoards,respBoards.length);
    console.log("respBoards: ", respBoards,respBoards);
    // this.setBoardArrayExtra(respBoards);

    respBoards.forEach(board => {
      this.BoardArray.push(this.fb.group({
        period_name: [board.name],
        period_id: [board.period_id],
        id: [board.id],
       board_id:[board.board_id],
       board_name:[board.board_name],

        definition: [board.definition],
        start_at: [board.start_at],
        end_at: [board.end_at]
      }));
    });
  
    this.ArrangementChild = respBoards
    this.filterBoardingChild$ = of([
      { board_id: 0, board_name: "All Boards" },
      ...this.ArrangementChild.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.board_id === item.board_id
        ))
      )
    ]);
 
  })
}
saveBoarding() {

  if(this.formBoarding.get("BoardArray").status=='VALID'){
    let formValue = this.formBoarding.get("BoardArray").value
 
    let objBording = {boards:formValue}
    console.log("objBording: ", objBording)
    this.ContratService.UpdateBoardingContrat(objBording, this.defaults.id).subscribe(respcreateboardingcontrat => {
      console.log("respcreateboardingcontrat: ", respcreateboardingcontrat)
      if (respcreateboardingcontrat.error == false)
        this.Boardings = objBording.boards
      // this.filterBoardingContrat$ = this.BoardingForPrices.valueChanges.pipe(
      //   startWith(''),
      //   map(destination0 => destination0 ? this.filterContrat(destination0) : this.Boardings.slice())
      // )
      this.dialogRef.close()
      this.cd.detectChanges()
    })
  }
  else {
    this.showWarningAlert("All fields are required")

  }
 
}

getSelectedPeriodIndex(index){
  const boardArray = this.formBoarding.get('BoardArray') as FormArray;
      return (parseInt(boardArray.at(index).value.period_name, 10)-1);
}
onChangeDestinationboardPeriod(selected, index) {


  const selectedboardPeriod = this.PeriodBoard.find(e => e.name === selected);
  console.log("selectedboardPeriod: ", selectedboardPeriod)

  if (selectedboardPeriod) {
    const boardArray = this.formBoarding.get('BoardArray') as FormArray;

    if (index >= 0 && index < boardArray.length) {
      const boardGroup = boardArray.at(index) as FormGroup;
      boardGroup.patchValue({
        start_at: selectedboardPeriod.start_at,
        end_at: selectedboardPeriod.end_at,
        period_id:selectedboardPeriod.id,
        period_name:selectedboardPeriod.name


      })
    }

 
  }
  this.formBoarding.updateValueAndValidity()



}
/*******************END BOARD INTERFACE*************************/


/*******************START Allotement INTERFACE******************/
initFormAllotement(){
  this.formallotement = this.fb.group({
  
    allotArray: new FormArray([]),
 
  })


}
get allotArray() {
  return this.formallotement.get("allotArray") as FormArray;
}
async setAllotementFormData(){
  this.ContratService.getAllotementOfContrat(this.defaults.id).subscribe(respAllotement => {
    console.log("respAllotement: ", respAllotement)
    const allotement = respAllotement

    this.cd.detectChanges()
    this.allotArray.clear();

    allotement.forEach(element => {
      this.allotArray.push(this.fb.group({
        id:[element.id],
          number:[element.number],
          room_id: [element.room_id],
          room_name:[element.room_name],
          period_name:[element.period_name],
          period_id:[element.period_id],
          bookedNumber:[element.number || 0],

          
      }));
  
      
    });

  })
}
saveAllotementNumber(event,k){


  if(!isNaN(parseInt(event.target.value)))
    
    {



console.log(parseInt(event.target.value)+'  k=  '+k)
const alltGroup = this.allotArray.at(k) as FormGroup;

alltGroup.patchValue({
  number: parseInt(event.target.value)
});
}
}
saveUpdateAllotement() {
if(this.allotArray.status=='VALID'){
  let request={
    "allotementU":this.allotArray.value

  }
  console.log("*****requestAllotement",request)
  this.ContratService.UpdateAllotement( request,this.defaults.id).subscribe(respUpdateContrat => {
    console.log("respUpdateContrat: ", respUpdateContrat)
    if(this.toAllotement){
      this.showWarningAlert("You need to update the prices for the new entries")
      this.stepper.selectedIndex = 6; 
    }
    else{

      this.dialogRef.close()
    }
     })
}
else {

  this.showWarningAlert('You need to add allotement to all the rooms')
}


}
/*******************END Allotement INTERFACE***********************/



/*******************START Child Price INTERFACE********************/

initFormChildPrice(){
  this.formChild = this.fb.group({
    child_prices: this.fb.array([]),
   });

}

  onChangePeriodChildPrice(selected,index) {
    console.log("showselected: ", selected)

 var selectedPeriodChildPrice
 selectedPeriodChildPrice = this.Periodlist.find(e => e.name === selected)
 console.log("showSelectPeriodChildPriceID: ", selectedPeriodChildPrice.id)
 const childPricesArray = this.formChild.get('child_prices') as FormArray;
 console.log("childPricesArray before: ", childPricesArray)

 console.log("childPricesArraypER: ", childPricesArray)

 const roomControl = childPricesArray.at(index).get('period_id');
const idP=selectedPeriodChildPrice.id
console.log("idP: ", idP)

   roomControl.setValue(idP);
   this.cd.detectChanges();
   console.log("childPricesArray after: ", childPricesArray)

  }
  onChangeRoomTypeChildPrice(selected: string, index: number) {
    var selectedRoomTypeForChildPrices


    this.filterRoomTypeContratForChild$.pipe(
      map(boardArray => boardArray.find(e => e.room_type_name === selected))
    ).subscribe(selectedRoomTypeChild => {
      selectedRoomTypeForChildPrices = selectedRoomTypeChild;
    });  
    console.log("selectedRoomTypeForChildPrices: ", selectedRoomTypeForChildPrices)



  


  


      const childPricesArray = this.formChild.get('child_prices') as FormArray;


        const roomControl = childPricesArray.at(index).get('type_id');
     
          roomControl.setValue(selectedRoomTypeForChildPrices.room_type_id);
          this.formChild.updateValueAndValidity();
        
      
    
  }
  onChangeRoomNameForChildPrices(selected: string, index: number) {
    var selectedroomForChildPrices
    this.fltDestinationsChild$.pipe(
      map(roomArray => roomArray.find(e => e.room_name === selected))
    ).subscribe(selectedRoomChild => {
      selectedroomForChildPrices = selectedRoomChild;
    });  
    console.log(" selectedroomForChildPrices ", selectedroomForChildPrices)



    
      const childPricesArray = this.formChild.get('child_prices') as FormArray;

  
        const room_id = childPricesArray.at(index).get('room_id');
        const room_name = childPricesArray.at(index).get('room_name');

        room_id.setValue(selectedroomForChildPrices.room_id);
        room_name.setValue(selectedroomForChildPrices.room_name);

          this.formChild.updateValueAndValidity();
        
      
    
  }
  onChangeDestinationboardForChildPrices(selected:string, index:number) {

    // console.log("selected:",selected)

    this.filterBoardingChild$.pipe(
      map(boardArray => boardArray.find(e => e.board_name === selected))
    ).subscribe(selectedBoardChild => {
      this.selectedboardchild = selectedBoardChild;
    });   
    // console.log("selectedboardchild: ", this.selectedboardchild.board_id)

  

   
    const childPricesArray = this.formChild.get('child_prices') as FormArray;

      const roomControl = childPricesArray.at(index).get('board_id');
        roomControl.setValue(this.selectedboardchild.board_id);
        this.formChild.updateValueAndValidity();
      
    




  

  }  
  setFormChildPriceData(){

  this.ContratService.geChildPriceOfContrat(this.defaults.id).subscribe(respChildPrices => {
    console.log("respChildPrices: ", respChildPrices, respChildPrices.length)
    var childsArray 
    var newChildGroup
    if (respChildPrices.length != 0) {
      for (let i = 0; i < respChildPrices.length; i++) {
        console.log("respChildPrices[i].id", respChildPrices[i].id)
        this.IdChuild = respChildPrices[i].id
        console.log("this.IdChuild****", this.IdChuild)
        this.AddnewrowChild(respChildPrices[i])
         childsArray = (this.formChild.get('child_prices') as FormArray).at(i).get('childs') as FormArray;
         console.log("childs.length", respChildPrices[i].childs.length)

         
        for (let j = 0; j < respChildPrices[i].childs.childs.length; j++) {
          newChildGroup =      this.fb.group({
            child_number: new FormControl(respChildPrices[i].childs.childs[j].child_number),
            age_from: new FormControl(respChildPrices[i].childs.childs[j].age_from),
            age_to: new FormControl(respChildPrices[i].childs.childs[j].age_to),
            price: new FormControl(respChildPrices[i].childs.childs[j].price),
            operation: new FormControl(respChildPrices[i].childs.childs[j].operation),
          })
          childsArray.push(newChildGroup);


      }
    

    }

    }
    else {
      this.AddnewrowChild(null)
    }
  })
  }
RemoveChilds(periodGroup: AbstractControl,index: any) {
  const itemConcell = periodGroup.get('childs') as FormArray;

  const item = itemConcell.at(index) as FormGroup;

 
  itemConcell.removeAt(index)
  
  this.cd.detectChanges()

    

  }
  RemoveitemsForChild(index: any) {

    this.items = this.formChild.get("child_prices") as FormArray;
    const formGroupAtIndex = this.items.at(index) as FormGroup;
    const idValue = formGroupAtIndex.get('id')?.value;
    this.ContratService.deleteChildPrice(idValue).subscribe(response => {
    console.log("deleteChildPrice"+JSON.stringify(response))
    this.items.removeAt(index)
    this.cd.detectChanges()

    })


    this.ArrayRoomBoardForChild.splice(index, -1);
    this.ArrayRoomNameForChild.splice(index, 1);
    this.ArrayRoomTypeForChild.splice(index, 1);
    this.periodeForChild.splice(index, 1);
    this.childs.splice(index, 1);


  }
  saveChild() {
    // const currentFormValue = this.formChild.getRawValue();
    // console.log("Current form value:", currentFormValue);

   if(this.formChild.status=='VALID'){
 const currentFormValue = this.formChild.getRawValue();
    console.log("Current form value:", currentFormValue);
    console.log("REQ CHILD PRICE:", this.formChild);
        this.ContratService.UpdateChildPrice
    ({child_prices:currentFormValue.child_prices,contrat_id:this.defaults.id   },this.defaults.id)
    .subscribe(respCreateChildPrice => {
      console.log("Response from update:", respCreateChildPrice);
      this.showSuccessAlert('Child Price updated succesfully')
     
    });

   }
   else {

    this.showWarningAlert('All fields are required')
   }

 

    


  }
  createItemChild(ChildPrice): FormGroup {
    console.log("ChildPrice in createItemChild : ", ChildPrice)
    if (ChildPrice == null) {
      return this.fb.group({
        id:new FormControl(null),
        room_id: new FormControl(0),
        period_id: new FormControl(0),
        period_name: new FormControl(''),

        adults: new FormControl(''),
        type_id: new FormControl(0),
        board_id: new FormControl(0),
        board_name: new FormControl(''),
        room_name: new FormControl(''),
        type_name: new FormControl(''),
        childs: this.fb.array([
          this.fb.group({
            child_number: new FormControl(''),
            age_from: new FormControl(''),
            age_to: new FormControl(''),
            price: new FormControl(''),
            operation: new FormControl(false),
          })
        ])
      });
    }
    else {
      return this.fb.group({
        id: new FormControl(ChildPrice.id),


        room_id: new FormControl(ChildPrice.room_id),
        period_id: new FormControl(ChildPrice.period_id),
        period_name: new FormControl(ChildPrice.period_name),

        adults: new FormControl(ChildPrice.adults),
        type_id: new FormControl(ChildPrice.type_id),
        board_id: new FormControl(ChildPrice.board_id),
        board_name: new FormControl(ChildPrice.board_name),
        room_name: new FormControl(ChildPrice.room_name),
        type_name: new FormControl(ChildPrice.type_name),



        childs: this.fb.array([
     
        ])
      });
    }
  }
  AddnewrowChildPrice(childGroup: AbstractControl) {
    this.itemConcell = childGroup.get('childs') as FormArray;
    this.itemConcell.push(  this.fb.group({
      child_number: new FormControl(''),
      age_from: new FormControl(''),
      age_to: new FormControl(''),
      price: new FormControl(''),
      operation: new FormControl(false),
    }));
    this.cd.markForCheck(); 

    this.cd.detectChanges()

  }
  getChildPriceControl(periodGroup: AbstractControl): FormArray {

    return periodGroup.get('childs') as FormArray;
  }
  AddnewrowChild(ChildPrice) {
    this.items = this.formChild.get('child_prices') as FormArray;

    if (ChildPrice != null) {
     

      this.items.push(this.createItemChild(ChildPrice));

    }
    else {
      

      this.items.push(this.createItemChild(null));

   
    }



  }

  get child_prices() {
    return <FormArray>this.formChild.get('child_prices');
  }
/*******************END Child Price INTERFACE************************/


/*******************START Cancellation methodes**********************/

openCancellationRoom(period: any,index) {
  console.log("FilterTableRoomPrices",this.FilterTableRoomPrices)
  console.log("FilterTableRoomPrices index",index)

  this.dialog.open(CancellationComponent, {
    data: {data:this.FilterTableRoomPrices[index],type:"update",category:"room",period:period},
    height: '60%',
    width: '60%'
  });
}
openCancellationRoomType(period: any,index) {
 

  this.dialog.open(CancellationComponent, {
    data: {data:this.FilterTableRoomTypePrices[index],type:"update",category:"roomType",period:period},
    height: '60%',
    width: '60%'
  });
}
openCancellationBoard(period: any,index) {

  console.log("openCancellationBoard",this.FilterTableBoardPrices)
  console.log("openCancellationBoard index",index)
  this.dialog.open(CancellationComponent, {
    data: {data:this.FilterTableBoardPrices[index],type:"update",category:"board",period:period},
    height: '60%',
    width: '60%'
  });
}
/*******************END Cancellation methodes**********************/


/*******************START BASE Price INTERFACE**********************/



get basePricePerPeriod() {
  return <FormArray>this.formBaseRoom.get('basePricePerPeriod');
}
initBasePriceForm(){
  this.formBaseRoom = this.fb.group({
    basePricePerPeriod: this.fb.array([])})
}

setFormBasePriceData(){

    var base=this.formBaseRoom.get('basePricePerPeriod') as FormArray;

    console.log("this.Periodes1: ",this.Periodes)
    this.Periodes.forEach(() => {



      base.push(this.fb.group({
        id: [null],
        room_id: [''],
        room_name: [''],
    
        room_type_id: [''],
        room_type_name: [''],
    
        board_id: [''],
        board_name: [''],
    
        price: [''],
        operation: [false],

        extb_price: [''],

        par_pax: [''],
        par_pax_name: [''],

      }));
    });


  this.ContratService.getBasePricesOfContrat(this.defaults.id).subscribe(respBasePrices => {


    respBasePrices.forEach((item,index) => {


        console.log("respBasePricesitem",item)
        base.at(index).patchValue({
          id: item.id,
          room_id: item.room_id,
          room_name: item.room_name,
      
          room_type_id: item.type_id,
          room_type_name: item.room_type,
      
          board_id: item.board_id,
          board_name: item.board_name,
          operation:item.operation,
          price: item.price,
          extb_price: item.extb_price,
          par_pax: item.par_pax,
          par_pax_name: item.par_pax_name
        });
            
             

      
      });

    
 console.log("respBasePrices: ", respBasePrices)
 this.cd.detectChanges()
   })
  }


  onChangeDestinationroomPrices(selected,index) {
    


    var selectedroomForBasePrices
    this.fltDestinationsChild$.pipe(
      map(roomArray => roomArray.find(e => e.room_name === selected))
    ).subscribe(selectedRoom => {
      selectedroomForBasePrices = selectedRoom;
    });  
    console.log(" selectedroomForChildPrices ", selectedroomForBasePrices)


    const base = this.formBaseRoom.get('basePricePerPeriod') as FormArray;


    const room_id = base.at(index).get('room_id');
    
      const room_name = base.at(index).get('room_name') ;

  

        room_id.setValue(selectedroomForBasePrices.room_id);
        room_name.setValue(selectedroomForBasePrices.room_name);

          this.cd.detectChanges();









  }
  onChangeContratRoomTypeForPrice(selected,index) {
    var selectedroomForBasePrices


this.filterRoomTypeContratForChild$.pipe(
  map(boardArray => boardArray.find(e => e.room_type_name === selected))
).subscribe(selectedRoomType => {
  selectedroomForBasePrices = selectedRoomType;
});  
console.log("selectedRoomTypeForChildPrices: ", selectedroomForBasePrices)




const base = this.formBaseRoom.get('basePricePerPeriod') as FormArray;


const room_type_id = base.at(index).get('room_type_id');

  const room_type_name = base.at(index).get('room_type_name') ;






room_type_id.setValue(selectedroomForBasePrices.room_type_id);
room_type_name.setValue(selectedroomForBasePrices.room_type_name);

   
this.cd.detectChanges();
  }
  onChangeContratBoardForPrices(selected,index) {

var selectedroomForBasePrices
this.filterBoardingChild$.pipe(
  map(boardArray => boardArray.find(e => e.board_name === selected))
).subscribe(selectedBoard => {
  selectedroomForBasePrices = selectedBoard;
});   
const base = this.formBaseRoom.get('basePricePerPeriod') as FormArray;


const board_id = base.at(index).get('board_id');

  const board_name = base.at(index).get('board_name') ;




board_id.setValue(selectedroomForBasePrices.board_id);
board_name.setValue(selectedroomForBasePrices.board_name);

   
this.cd.detectChanges();



  



  }

 extb(extbPrice,index) {
  const base = this.formBaseRoom.get('basePricePerPeriod') as FormArray;


  const extb_price = base.at(index).get('extb_price');
extb_price.setValue(extbPrice.value);
 }
 price(Price,index) {
  const base = this.formBaseRoom.get('basePricePerPeriod') as FormArray;


  const price = base.at(index).get('price');

price.setValue(Price.value);
 }
 showSelect(selected,index) {
  console.log("showSelect Type",selected)
  var boolPar_pax="Per Pax"
  if(selected==false){
    boolPar_pax="Per Room"
  }
      const base = this.formBaseRoom.get('basePricePerPeriod') as FormArray;
  
      
      const par_pax_name = base.at(index).get('par_pax_name');

        const par_pax = base.at(index).get('par_pax');
     
        par_pax.setValue(selected);
        par_pax_name.setValue(boolPar_pax);

   
  
  
    }
 saveBasePrice(idPeriode,index) {


  const base = this.formBaseRoom.get('basePricePerPeriod') as FormArray;

 if(base.at(index).status==='VALID'){


    const BasePrice = base.at(index).value;


    console.log("base.at(index)", base.at(index))

  console.log("this.formBaseRoom.value", {period_id:idPeriode,BasePrice:BasePrice})
  this.Periodes[index].pressedBasePrice = true;
  this.ContratService.updateBasePrice({period_id:idPeriode,BasePrice:BasePrice}).subscribe(responseBaseRoomprice =>
     {
      console.log("responseBaseRoomprice", responseBaseRoomprice)
       this.cd.detectChanges()
    
 
  })
}
}

/*******************END BASE Price INTERFACE**********************/


/*******************START All Prices INTERFACE**********************/



getBoardingPrices(){

this.ContratService.getBoardPricesOfContrat(this.defaults.id).subscribe(respBoardPrices => {
  console.log("respBoardPrices: ", respBoardPrices)
  this.BoardForBasePrice = respBoardPrices

  this.FilterTableBoardPrices = respBoardPrices.filter(Board => Board.period_id == this.Periodes[0].id);
  console.log("FilterTable for board: ", this.FilterTableBoardPrices, this.FilterTableBoardPrices.length, respBoardPrices.length)
  for (let i = 0; i < this.FilterTableBoardPrices.length; i++) {
    let objBoardtype = Object.assign({ id: this.FilterTableBoardPrices[i].id }, { arrangement_id: this.FilterTableBoardPrices[i].arrangement_id },
      { Board: this.FilterTableBoardPrices[i].boarding_name }, { indexboard: i }, { Price: this.FilterTableBoardPrices[i].price, }, { EXTBPrice: this.FilterTableBoardPrices[i].extb_price }, { TypeCalculat: this.FilterTableBoardPrices[i].par_pax })
    console.log("objBoardtype", objBoardtype)
    this.RowsBoard.push(objBoardtype)
  }
  console.log("RowsBoard: ", respBoardPrices)

  console.log("this.RowsBoard: ", this.RowsBoard)
  
 

  this.dataSourceBoard = new MatTableDataSource<any>(this.RowsBoard);

})

}
getRoomPrices(){
  this.ContratService.getRoomTypePricesOfContrat(this.defaults.id).subscribe(respRoomTypePrices => {
    console.log("respRoomTypePrices: ", respRoomTypePrices)
    this.RoomTypeForBasePrices = respRoomTypePrices
    this.FilterTableRoomTypePrices = respRoomTypePrices.filter(RoomType => RoomType.period_id == this.Periodes[0].id);
    for (let i = 0; i < this.FilterTableRoomTypePrices.length; i++) {
  
      let objroomtype = Object.assign({ id: this.FilterTableRoomTypePrices[i].id }, { room_type_id: this.FilterTableRoomTypePrices[i].type_id },
        { typeName: this.FilterTableRoomTypePrices[i].room_type }, { indexroomtype: i }, { Price: this.FilterTableRoomTypePrices[i].price, }, { EXTBPrice: this.FilterTableRoomTypePrices[i].extb_price }, { TypeCalculat: this.FilterTableRoomTypePrices[i].par_pax })
      console.log("objroomtype", objroomtype)
      this.RowsRoomType.push(objroomtype)
    }
    
    this.dataSourceRoomstype = new MatTableDataSource<any>(this.RowsRoomType);
  })
}
getRoomTypePrices(){
  this.ContratService.getRoomPricesOfContrat(this.defaults.id).subscribe(respRoomPrices => {
    console.log("respRoomPrices: ", respRoomPrices)
    for (let i = 0; i < respRoomPrices.length; i++) {
  
    this.Periods=respRoomPrices[i].period_id
    console.log("this.Periods",this.Periods)
  
  
    }
  
  
    this.RoomForBasePrices = respRoomPrices
    this.FilterTableRoomPrices = respRoomPrices.filter(Room => Room.period_id == this.Periodes[0].id);
  
    console.log("FilterTable in : ", this.FilterTableRoomPrices)
    for (let i = 0; i < this.FilterTableRoomPrices.length; i++) {
      let objRooms = Object.assign({ id: this.FilterTableRoomPrices[i].id },
        { room_id: this.FilterTableRoomPrices[i].room_id },
        { room_name: this.FilterTableRoomPrices[i].room_name }, { indexroom: i }
        , { Price: this.FilterTableRoomPrices[i].price },
        { EXTBPrice: this.FilterTableRoomPrices[i].extb_price },
        { TypeCalculat: this.FilterTableRoomPrices[i].par_pax })
      console.log("objRooms----", objRooms)
      this.RowsRoomNameP.push(objRooms)
    }
    console.log("this.RowsRoomNameP : ", this.RowsRoomNameP)
    this.dataSourceRooms = new MatTableDataSource<any>(this.RowsRoomNameP);
    console.log("dataSourceRooms11111111111", this.dataSourceRooms)
    this.destinationBoardPrices.setValue(respRoomPrices[0].board_name)
    this.destinationRoomPrices.setValue(respRoomPrices[0].room_name)
    this.cd.detectChanges()
    this.Periodes.forEach((_,index)=>{
      this.DisabledBoardArray[index] = new Array(this.RowsBoard.length).fill(true);
      this.DisabledRoomArray[index] = new Array(this.RowsRoomNameP.length).fill(true);
      this.DisabledRoomTypeArray[index] = new Array(this.RowsRoomType.length).fill(true);
     
  
    })
    console.log("this.DisabledBoardArray",this.DisabledBoardArray)
    console.log("this.DisabledRoomArray",this.DisabledRoomArray)
    console.log("this.DisabledRoomTypeArray",this.DisabledRoomTypeArray)
  })
}

patchValuesForBasePrices(idPeriode) {
  this.RowsBoard = []
  this.RowsRoomNameP = []
  this.RowsRoomType = []

  console.log("idPeriode for base prices: ", idPeriode)
  this.FilterTableRoomPrices = this.RoomForBasePrices.filter(Room => Room.period_id == this.Periodes[idPeriode].id);
  console.log("FilterTable for Room: ", this.FilterTableRoomPrices, this.FilterTableRoomPrices.length, this.RoomForBasePrices.length)

  for (let i = 0; i < this.FilterTableRoomPrices.length; i++) {
    let objRooms = Object.assign({ room_id: this.FilterTableRoomPrices[i].room_id }, { room_name: this.FilterTableRoomPrices[i].room_name }, { indexroom: i }
      , { Price: this.FilterTableRoomPrices[i].price }, { EXTBPrice: this.FilterTableRoomPrices[i].extb_price }, { TypeCalculat: this.FilterTableRoomPrices[i].par_pax })
    console.log("objRooms-", objRooms)
    this.RowsRoomNameP.push(objRooms)
  }
  console.log("this.RowsRoomNameP rihem: ", this.RowsRoomNameP)
  this.dataSourceRooms = new MatTableDataSource<any>(this.RowsRoomNameP);

  this.FilterTableRoomTypePrices = this.RoomTypeForBasePrices.filter(RoomType => RoomType.period_id == this.Periodes[idPeriode].id);
  console.log("FilterTable for Room Type: ", this.FilterTableRoomTypePrices, this.FilterTableRoomTypePrices.length, this.RoomTypeForBasePrices.length)
  for (let i = 0; i < this.FilterTableRoomTypePrices.length; i++) {
    let objroomtype = Object.assign({ room_type_id: this.FilterTableRoomTypePrices[i].type_id },
      { typeName: this.FilterTableRoomTypePrices[i].room_type }, { indexroomtype: i }, { Price: this.FilterTableRoomTypePrices[i].price, }, { EXTBPrice: this.FilterTableRoomTypePrices[i].extb_price }, { TypeCalculat: this.FilterTableRoomTypePrices[i].par_pax })
    console.log("objroomtype", objroomtype)
    this.RowsRoomType.push(objroomtype)
  }

  this.dataSourceRoomstype = new MatTableDataSource<any>(this.RowsRoomType);

  this.FilterTableBoardPrices = this.BoardForBasePrice.filter(Board => Board.period_id == this.Periodes[idPeriode].id);
  console.log("FilterTable for board: ", this.FilterTableBoardPrices, this.FilterTableBoardPrices.length, this.BoardForBasePrice.length)
  for (let i = 0; i < this.FilterTableBoardPrices.length; i++) {
    let objBoardtype = Object.assign({ id: this.FilterTableBoardPrices[i].id }, { arrangement_id: this.FilterTableBoardPrices[i].arrangement_id },
      { Board: this.FilterTableBoardPrices[i].boarding_name }, { indexboard: i }, { Price: this.FilterTableBoardPrices[i].price, }, { EXTBPrice: this.FilterTableBoardPrices[i].extb_price }, { TypeCalculat: this.FilterTableBoardPrices[i].par_pax })
    console.log("objBoardtype", objBoardtype)
    this.RowsBoard.push(objBoardtype)
  }
  console.log("this.RowsBoard: ", this.RowsBoard)
  //  this.dataSource = new MatTableDataSource<any>(this.Rooms);
  //this.dataSourceRooms = new MatTableDataSource<any>(this.RowsRooms)
  this.dataSourceBoard = new MatTableDataSource<any>(this.RowsBoard);
  this.cd.detectChanges()
}
goToNextTab(): void {
  this.selectedTabIndex++;

}
  filterBoardingContratForPrices(name) {
    console.log("name filterBoardingContratForPrices: ", name)
    return this.Boardarray.filter(destinationBoardPrices =>
      destinationBoardPrices.name.toLowerCase().indexOf(name.toLowerCase()) >= 0);
  }


  savecalculate() {

      this.ContratService.getCalculatAll(this.defaults.id).subscribe(respAllCalcut => {
        console.log("**************respAll*****: ", respAllCalcut)
        let obj
        this.periods = []
        this.dataSourcePrices = new MatTableDataSource<any>(this.periods)
        this.cd.detectChanges()
        for (let i = 0; i < respAllCalcut.periods.length; i++) {
          for (let j = 0; j < respAllCalcut.periods[i].rooms.length; j++) {
            obj = Object.assign({ code: respAllCalcut.periods[i].code, },
              { name: respAllCalcut.periods[i].name, },
              { start_at: respAllCalcut.periods[i].start_at },
              { end_at: respAllCalcut.periods[i].end_at }
              , { room_name: respAllCalcut.periods[i].rooms[j].room_name },
              { type_name: respAllCalcut.periods[i].rooms[j].type_name },
              { board_name: respAllCalcut.periods[i].rooms[j].board_name },
              { price: respAllCalcut.periods[i].rooms[j].price },
              { price_with_extb: respAllCalcut.periods[i].rooms[j].price_with_extb })
  
            this.periods.push(obj)
            console.log("periods", this.periods)
          }
        }
  
        this.dataSourcePrices = new MatTableDataSource<any>(this.periods)
        this.cd.detectChanges()
  
      })
    }
  async savePrices(idPeriode,index) {
      console.log("this.tableauRoom.length in save baseprices: ", this.tableauRoom.length)
    
      console.log("oformValuebj for room prices***************", this.FilterTableRoomPrices)
      for (let i = 0; i < this.FilterTableRoomPrices.length; i++) {
        if (this.FilterTableRoomPrices[i].par_pax == null || this.FilterTableRoomPrices[i].price == null || this.FilterTableRoomPrices[i].extb_price == null) {
          
          this.showWarningAlert('All fields are required')
          return 0
        }
      }
      console.log("PriceBoard***************", this.FilterTableBoardPrices)
      for (let i = 0; i < this.FilterTableBoardPrices.length; i++) {
        if (this.FilterTableBoardPrices[i].par_pax == null || this.FilterTableBoardPrices[i].price == null || this.FilterTableBoardPrices[i].extb_price == null) {
          this.showWarningAlert('All fields are required')
          return 0
        }
      }
  
      console.log("this.tabidtyperoomsForPrices.length: ", this.tabidtyperoomsForPrices.length)
    
      console.log("BaseTypeRoom***************", this.FilterTableRoomTypePrices)
      for (let i = 0; i < this.FilterTableRoomTypePrices.length; i++) {
        if (this.FilterTableRoomTypePrices[i].par_pax == null || this.FilterTableRoomTypePrices[i].price == null || this.FilterTableRoomTypePrices[i].extb_price == null) {
          this.showWarningAlert('All fields are required')
          return 0
        }
      }
  
      this.Periodes[index].pressed = true;
      this.cd.detectChanges();
      let request = {
        "room_price": this.FilterTableRoomPrices,
        "room_type_price": this.FilterTableRoomTypePrices,
        "board_price": this.FilterTableBoardPrices
      };
  
      console.log("request savePr"+JSON.stringify(request))
      try{
  
        await this.ContratService.PutAllPrices(request).subscribe(async response => {
          console.log("ResponsePriceOfRoom: ", response)
          
          this.goToNextTab();
          this.cd.detectChanges();
  
        },error=>{
          console.log("errorRoom: ", error)
          this.showWarningAlert(error.error.message)

          this.Periodes[index].pressed = false;
        this.cd.detectChanges();
        }
      )
      }
      catch(error){
        this.Periodes[index].pressed = false;
        this.cd.detectChanges();
      }
    
  
   
   
    
  
  
  
  
    }

  showSelectForRoom(value, column, index, id, type) {
    console.log("this.CalculForRoom: ", "value: ", type, "index: ", index, "id: ", id, "column: ", column, "calculatetype: ", type)
    this.tabidChangedRoomForPrices[index] = id
    this.tableauType[index] = type
    console.log("this.FilterTableRoomPrices[index].par_pax: ", this.FilterTableRoomPrices[index].par_pax, value == "Per Pax")
    let val = type == "Per Pax"
    this.FilterTableRoomPrices[index].par_pax = val
    console.log("this.FilterTableRoomPrices[index].par_pax after: ", this.FilterTableRoomPrices[index].par_pax, value == "Per Pax", this.FilterTableRoomPrices)
  }
  showSelectForTypeRoom(value, column, index, id, type) {

    console.log("showSelectForTypeRoom: ")
    this.cd.detectChanges
    this.tabidChangedTypeRoomForPrices[index] = id
    this.tableauTypeRoom[index] = type
    console.log("this.FilterTableRoomTypePrices.par_pax: ", this.FilterTableRoomTypePrices[index].par_pax, value == "Per Pax")
    let val = type == "Per Pax"
    this.FilterTableRoomTypePrices[index].par_pax = val
    console.log("this.FilterTableRoomTypePrices.par_pax after: ", this.FilterTableRoomTypePrices[index].par_pax, value == "Per Pax")
  }
  showSelectForBoard(value, column, index, id, type) {
    console.log("showSelectForBoard: ")

    this.tabidChangedboardsForPrices[index] = id
    this.tableauTypeBoard[index] = type
    console.log("this.FilterTableBoardPrices[index].par_pax: ", this.FilterTableBoardPrices[index].par_pax, value == "Per Pax")
    let val = type == "Per Pax"
    this.FilterTableBoardPrices[index].par_pax = val
    console.log("this.FilterTableBoardPrices[index].par_pax after: ", this.FilterTableBoardPrices[index].par_pax, value == "Per Pax")

  }

  showvalueinput(value, column, idroom, index, id,PeriodIndex) {
  

    console.log("idroom in base room: ", idroom, "index in showvalueinput: ", index, id)
    console.log("this.ArrayRoomNamePrices.length", this.ArrayRoomNamePrices.length)
    for (let j = 0; j < this.ArrayRoomNamePrices.length; j++) {
      console.log("ArrayRoomNamePrices", this.ArrayRoomNamePrices[j])
      this.Room = (value.target.value)
      console.log(index, "this.Room: ", this.Room)
    }
    this.Price = parseInt(value.target.value)
    console.log(index, "this.Price: ", this.Price)
    this.EXTBPrice = parseInt(value.target.value)
    console.log(index, "this.EXTBPrice: ", this.EXTBPrice)
    this.TypeCalculat = this.type.toLowerCase() == 'true';
    console.log(index, "this.TypeCalculat: ", this.TypeCalculat)
    /*         this.tableauRoom[index][column]=this.Room
     */

    this.tabidChangedRoomForPrices[index] = id
    this.tabidroomsForPrices[index] = idroom
    console.log(" this.tabidroomsForPrices[index]: ", this.tabidroomsForPrices[index])
    if (column == "Price") {
      this.tableauPrice[index] = value.target.value
      this.RoomForBasePrices[index].price = value.target.value
      this.FilterTableRoomPrices[index].price = this.Price
      this.DisabledRoomArray[PeriodIndex][index] = false;

    }
    if (column == "EXTBPrice") {
      this.tableauEXTBPrice[index] = value.target.value
      this.RoomForBasePrices[index].extb_price = value.target.value
      this.FilterTableRoomPrices[index].extb_price = this.EXTBPrice
    }
    console.log("this.tabidroomsForPrices", this.tabidroomsForPrices)
    console.log("this.tableauPrice", this.tableauPrice)
    console.log("this.tableauEXTBPrice", this.tableauEXTBPrice)
    
  }
  showvalueTypeRoom(value, colums, idroomtype, index, id,indexPeriod) {


    console.log("index in select roomtype: ", index, id)
    this.Type = (value.target.value)
    console.log(index, "this.Type: ", this.Type)
    this.PriceType = parseInt(value.target.value)
    console.log(index, "this.PriceType: ", this.PriceType)
    this.EXTBPriceType = parseInt(value.target.value)
    console.log(index, "this.EXTBPriceType: ", this.EXTBPriceType)
    //this.TypeCalculatType = this.roomtype.toLowerCase() == 'true';
    //console.log(index, "this.TypeCalculatType: ", this.TypeCalculatType)
    /*         this.tableauRoom[index][column]=this.Room
     */
    this.tabidtyperoomsForPrices[index] = id
    this.tabidtyperoomsForPrices[index] = idroomtype
    if (colums == "Price") {
      this.tableauPriceType[index] = this.PriceType
      this.FilterTableRoomTypePrices[index].price = this.PriceType
      this.DisabledRoomTypeArray[indexPeriod][index] = false;

    }
    if (colums == "EXTBPrice") {
      this.tableauEXTBPriceType[index] = this.EXTBPriceType
      this.FilterTableRoomTypePrices[index].extb_price = this.EXTBPriceType
    }
    console.log("this.tabidtyperoomsForPrices", this.tabidtyperoomsForPrices)
    console.log("this.tableauPriceType", this.tableauPriceType)
    console.log("this.tableauEXTBPriceType", this.tableauEXTBPriceType)
    //console.log("this.TypeCalculatType", this.TypeCalculatType)
  }
  showvalueBoard(value, column, idboard, index, id,indexPeriod) {

    console.log("index in select board: ", index, id)
    this.Boarding = (value.target.value)
    console.log(index, "this.Boarding: ", this.Boarding)
    this.PriceBoard = parseInt(value.target.value)
    console.log(index, "this.PriceBoard: ", this.PriceBoard)
    this.EXTBPriceBoard = parseInt(value.target.value)
    console.log(index, "this.EXTBPriceBoard: ", this.EXTBPriceBoard)
    this.TypeCalculatBoard = this.boardtype.toLowerCase() == 'true';
    console.log(index, "this.TypeCalculatBoard: ", this.TypeCalculatBoard)
    this.tabidChangedboardsForPrices[index] = id
    this.tabidboardsForPrices[index] = idboard

    if (column == "Price") {
      this.tableauPriceBoard[index] = this.PriceBoard
      this.FilterTableBoardPrices[index].price = this.PriceBoard
      this.DisabledBoardArray[indexPeriod][index] = false;

    }
    if (column == "EXTBPrice") {
      this.tableauEXTBPriceBoard[index] = this.EXTBPriceBoard
      this.FilterTableBoardPrices[index].extb_price = this.EXTBPriceBoard
    }
    console.log("this.tableauBoard", this.tableauBoard)
    console.log("this.tableauPriceBoard", this.tableauPriceBoard)
    console.log("this.tableauEXTBPriceBoard", this.tableauEXTBPriceBoard)
    console.log("this.tableauTypeBoard", this.tableauTypeBoard)
  }
/*******************END All Prices INTERFACE**********************/
  ngOnDestroy() {
  }
  close() {
    this.dialogRef.close()
  }
}